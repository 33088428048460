<div mat-dialog-content>
    <button mat-icon-button [mat-dialog-close] class="closeModal">
        <mat-icon>close</mat-icon>
    </button>
    <h2 style="margin-left: 3px;font-weight: 100"><mat-icon style="vertical-align: text-bottom;">notifications_active</mat-icon>Notifications</h2>
    <br>
    <br>
    <div mat-dialog-content cdkFocusInitial style="max-height: 20%">
      <p *ngIf="(data$ | async)?.length === 0">{{'no notifs' | translate}}</p>
      <div *ngFor="let message of data$ | async" style="margin-bottom: 1%">
        <mat-card id="messages" (click)="linkToTodo(message?.todoId)">
          <button mat-icon-button style="float: right;" (click)=" delNotif(message.id)">
            <mat-icon>delete_forever</mat-icon>
        </button>
          <mat-card-header>
            <div mat-card-avatar>
              <img [src]="message?.img" height="32px" alt="photo" *ngIf='message?.img'>
            </div>
            <mat-card-subtitle>{{message?.author}} | {{message?.sentAt?.toDate() | date:'dd-MM-yyyy HH:mm:ss'}} |
              ({{message?.type}})
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <h4>{{message?.todoTitle}}</h4>
            <p style="overflow-wrap:break-word" [innerHTML]="message?.content |  linkify">
            </p>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
</div>



