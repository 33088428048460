import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable({
    providedIn: 'root',
})
export class SidenavService {
    private sidenav: MatSidenav;

    public setSidenav(sidenav: MatSidenav) {
        console.log(sidenav);
        this.sidenav = sidenav;
    }

    public open() {
        if (this.sidenav != null) {
            return this.sidenav.open();
        }
    }
}
