import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { TeammemberGuard } from './guards/teammember.guard';
import { HomeDashboardComponent } from './home-dashboard/home-dashboard.component';
import { LoginComponent } from './login/login.component';
import { FormTeamComponent } from './team/form-team/form-team.component';
import { TeamContainerComponent } from './team/team-container/team-container.component';
import { TeamDetailsComponent } from './team/team-container/team-details/team-details.component';
import { TeamComponent } from './team/team.component';
import { FormTodoComponent } from './todo/form/form.component';
import { TodoComponent } from './todo/todo.component';

const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'home', component: HomeDashboardComponent, canActivate: [AuthGuard] },
    { path: 'mytodos', component: TodoComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
    { path: 'todo/:key', component: TodoComponent, canActivate: [TeammemberGuard] },
    { path: 'todo/single/:id', component: TodoComponent, canActivate: [AuthGuard]},
    { path: 'myteams', component: TeamComponent, canActivate: [AuthGuard] },
    {
        path: 'myteamsList',
        component: TeamContainerComponent,
        canActivate: [AuthGuard],
        children: [{ path: ':key', component: TeamDetailsComponent }],
    },
    { path: 'add', component: FormTodoComponent, canActivate: [AuthGuard] },
    { path: 'edit/:key', component: FormTodoComponent, canActivate: [AuthGuard] },
    { path: 'edit-team/:key', component: FormTeamComponent, canActivate: [AuthGuard] },
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: '**', redirectTo: 'home' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: false, onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
