import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
    selector: 'app-widget-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.css'],
})
export class CardComponent implements OnInit {
    @Input() label: string;
    @Input() total: string;
    @Input() percentage: string;
    @Input() data = [];

    Highcharts = Highcharts;
    chartOptions = {};

    constructor() {}

    ngOnInit() {
        this.chartOptions = {
            chart: {
                type: 'area',
                backgroundColor: null,
                borderWidth: 0,
                margin: [2, 2, 2, 2],
                height: 60,
            },
            title: {
                text: null,
            },
            subtitle: {
                text: null,
            },
            tooltip: {
                split: true,
                outside: true,
            },
            legend: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: false,
            },
            xAxis: {
                labels: {
                    enabled: false,
                },
                title: {
                    text: null,
                },
                startOnTick: false,
                endOnTick: false,
                tickOptions: [],
            },
            yAxis: {
                labels: {
                    enabled: false,
                },
                title: {
                    text: null,
                },
                startOnTick: false,
                endOnTick: false,
                tickOptions: [],
            },
            series: [
                {
                    data: this.data,
                },
            ],
        };

        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 300);
    }
}
