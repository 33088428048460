// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    firebaseConfig: {
        apiKey: 'AIzaSyDg74s7w6203L3oJURmAXTITHAupu2uJRY',
        authDomain: 'todo-app-dev-da10a.firebaseapp.com',
        databaseURL: 'https://todo-app-dev-da10a.firebaseio.com',
        projectId: 'todo-app-dev-da10a',
        storageBucket: 'todo-app-dev-da10a.appspot.com',
        messagingSenderId: '785764634921',
        appId: "1:785764634921:web:5471cefa163b1ef0cf2738",
    },
    siteUrl: 'https://wedo-d.soitec.net/',
    clientId: '785764634921-0mjm6jc19e8f0pden18ij5jnkf2qnrq8.apps.googleusercontent.com',
    stage: 'dev',
    version: 'v2.8.1',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
