import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of as observableOf, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { TodoService, User } from '../../services/todo.service';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
})
export class FormTodoComponent implements OnInit, OnDestroy {
    @ViewChild('title', { static: true }) nameField: ElementRef;
    myForm: FormGroup;
    teamID;
    todoID;
    teamNAME;
    teamNameSub: Subscription;
    myCurrentEmailSub: Subscription;
    mytagsSub: Subscription;
    todoCategoriesSub: Subscription;
    todoTypeSub: Subscription;
    teamsForList: any; // Array contient la liste des projets (uniquement ceux du User connecté)
    startDate = new Date();
    state: string;
    options: User[] = [];
    filteredOptions: Observable<User[]>;
    TodoupdatedAt;
    currentEmail;
    selectedOption;
    autocompleteItems = ['P1'];
    campaignsqvt = ['Septembre 2021 (Soitec France)', 'September 2021 (Soitec worldwide)'];
    type;
    logoqvt: string;
    categories: any;
    logo8d: string;

    constructor(
        public dialogRef: MatDialogRef<string>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private auth: AuthService,
        private todoservice: TodoService,
        private fb: FormBuilder,
    ) {
        this.teamID = data.teamid;
        console.log(data);
        this.todoID = data.todoid;
        this.teamNAME = data.teamname;
        this.logoqvt = './assets/images/logoqvt.png';
        this.logo8d = './assets/images/logo8d.png';
    }

    ngOnInit() {
        this.nameField.nativeElement.focus();
        this.options = this.todoservice.getUsersSoitec();
        // on récupère les projects de l'app, pour les afficher dans les choix (liste déroulante) : Array projectsForList
        this.teamsForList = this.todoservice.getUserTeams();
        // FORM
        this.myForm = this.fb.group({
            title: ['', Validators.required],
            description: [''],
            teamID: [this.teamID],
            teamName: [this.teamNAME],
            teamsShared: [],
            // owner: [this.auth.emailUserConnected],
            owner: [this.auth.currentEmail, Validators.required],
            transverse: [''],
            dateTarget: [''],
            isDone: [false],
            creator: [this.auth.currentEmail, Validators.required],
            category: [''],
            campaignQVT: [''],
            orgniv1: [''],
            orgniv2: [''],
            orgniv3: [''],
            orgniv4: [''],
            orgniv5: [''],
            tags: [''],
            type: [''],
        });
        this.myForm.controls['teamsShared'].patchValue([this.teamID]);
        this.filteredOptions = this.myForm.controls.owner.valueChanges.pipe(
            startWith<string | User>(''),
            map(value => (typeof value === 'string' ? value : value.name)),
            map(name => (name ? this._filter(name) : this.options.slice())),
        );
        // Subscription : mytags
        this.mytagsSub = this.todoservice.getUserTags().subscribe(
            mytags => {
                this.autocompleteItems = mytags;
                // console.log(mytags);
            },
            error => console.log('Observer a reçu une erreur : ' + error),
        );

        // Subscription : categories
        if (this.teamID) {
            this.todoTypeSub = this.todoservice.getTodoTypeFromTeam(this.teamID).subscribe(
                type => {
                    this.type = type;
                    if (type === 'QVT') {
                        this.myForm.controls['category'].setValidators([Validators.required]);
                        this.myForm.controls['category'].updateValueAndValidity();
                        this.myForm.controls['campaignQVT'].setValidators([Validators.required]);
                        this.myForm.controls['campaignQVT'].updateValueAndValidity();
                        this.myForm.controls['dateTarget'].setValidators([Validators.required]);
                        this.myForm.controls['dateTarget'].updateValueAndValidity();
                        this.myForm.controls['type'].patchValue(type);
                        this.myForm.controls['transverse'].patchValue(false);
                        // on set la valeur orgniv récupérés du Team
                        this.todoservice.getTodoTeamorgnivFromTeam(this.teamID).subscribe(orgniv => {
                            if (orgniv) {
                                console.log(orgniv);
                                this.myForm.controls['orgniv1'].patchValue(orgniv['orgniv1']);
                                this.myForm.controls['orgniv2'].patchValue(orgniv['orgniv2']);
                                this.myForm.controls['orgniv3'].patchValue(orgniv['orgniv3']);
                                this.myForm.controls['orgniv4'].patchValue(orgniv['orgniv4']);
                                this.myForm.controls['orgniv5'].patchValue(orgniv['orgniv5']);
                            }
                        });
                        this.categories = this.todoservice.categoriesQVT;
                    } else if (type === '8D') {
                        this.myForm.controls['dateTarget'].setValidators([Validators.required]);
                        this.myForm.controls['dateTarget'].updateValueAndValidity();
                        this.myForm.controls['type'].patchValue(type);
                        this.myForm.controls['category'].setValidators([Validators.required]);
                        this.myForm.controls['category'].updateValueAndValidity();
                        this.categories = this.todoservice.categories8D;
                    }
                },
                error => console.log('Observer a reçu une erreur : ' + error),
            );
        }
    }

    get dateTarget() {
        return this.myForm.get('dateTarget');
    }
    get isDone() {
        return this.myForm.get('isDone');
    }

    ngOnDestroy(): void {
        if (this.teamNameSub) {
            this.teamNameSub.unsubscribe();
        }
        if (this.mytagsSub) {
            this.mytagsSub.unsubscribe();
        }
        if (this.todoCategoriesSub) {
            this.todoCategoriesSub.unsubscribe();
        }
        if (this.todoTypeSub) {
            this.todoTypeSub.unsubscribe();
        }
    }

    private _filter(name: string): User[] {
        const filterValue = name.toLowerCase();
        return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    // TAGS add
    public transform(value): Observable<object> {
        console.log(value);
        if (value.value) {
            const item = { display: `#${value.value}`, value: `#${value.value}` };
            return observableOf(item);
        } else if (value) {
            const item = { display: `#${value}`, value: `#${value}` };
            return observableOf(item);
        } else {
            return;
        }
    }

    // récupère le nom de la Team, et set le todo (teamName) sur changement de team
    teamNameSet($event: { value: any }) {
        this.todoTypeSub = this.todoservice.getTodoTypeFromTeam($event.value).subscribe(
            type => {
                this.type = type;
                if (type === 'QVT') {
                    console.log('type==QVT');
                    this.myForm.controls['category'].setValidators([Validators.required]);
                    this.myForm.controls['category'].updateValueAndValidity();
                    this.myForm.controls['campaignQVT'].setValidators([Validators.required]);
                    this.myForm.controls['campaignQVT'].updateValueAndValidity();
                    this.myForm.controls['dateTarget'].setValidators([Validators.required]);
                    this.myForm.controls['dateTarget'].updateValueAndValidity();
                    this.myForm.controls['type'].patchValue(type);
                    this.myForm.controls['transverse'].patchValue(false);
                    // on set la valeur orgniv récupérés du Team
                    this.todoservice.getTodoTeamorgnivFromTeam($event.value).subscribe(orgniv => {
                        if (orgniv) {
                            this.myForm.controls['orgniv1'].patchValue(orgniv['orgniv1']);
                            this.myForm.controls['orgniv2'].patchValue(orgniv['orgniv2']);
                            this.myForm.controls['orgniv3'].patchValue(orgniv['orgniv3']);
                            this.myForm.controls['orgniv4'].patchValue(orgniv['orgniv4']);
                            this.myForm.controls['orgniv5'].patchValue(orgniv['orgniv5']);
                        }
                    });
                    this.categories = this.todoservice.categoriesQVT;
                }
            },
            error => console.log('Observer a reçu une erreur : ' + error),
        );
        this.clearSharedTeam();
        this.myForm.patchValue({ teamsShared: [$event.value] });
        this.teamNameSub = this.todoservice.getATeamName($event.value).subscribe(x => {
            // console.log(x);
            this.myForm.patchValue({ teamName: x });
        });
    }

    closeTodo() {
        this.myForm.patchValue({ isDone: true });
    }

    openTodo() {
        this.myForm.patchValue({ isDone: false });
    }

    clearTeam() {
        if (this.teamNameSub) {
            this.teamNameSub.unsubscribe();
        }
        this.myForm.patchValue({ teamID: '' });
        this.myForm.patchValue({ teamName: '' });
        this.clearSharedTeam();
    }

    clearSharedTeam() {
        this.myForm.patchValue({ teamsShared: [] });
    }

    clearTargetDate() {
        this.myForm.patchValue({ dateTarget: '' });
    }

    changeHandler(e) {
        this.state = e;
        // console.log('changeHandler', e);
    }

    changeMyControl(): void {
        if (this.selectedOption === undefined) {
            // also check selected item and entered text are not same
            this.myForm.get('owner').setErrors({ incorrect: true });
        }
    }

    onSelectedOption(isSelected: boolean, name: string): void {
        if (isSelected) {
            setTimeout(() => {
                const option = this.options.filter(bt => bt.name === name);
                if (option.length > 0) {
                    this.selectedOption = option[0];
                    // patch formcontrol value here
                }
            }, 200);
        }
    }

    authorizeTeamChange(type) {
        if (type === '8D') {
            return false;
        }
        if (type === 'QVT') {
            return false;
        } else {
            return true;
        }
    }

    transverse(e: any) {
        this.myForm.get('transverse').patchValue(e.checked);
    }
}
