import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'email',
})
export class EmailPipe implements PipeTransform {
    public transform(input: string): string {
        if (!input) {
            return '';
        } else {
            const name = input.replace(/@.*/, '');
            const name2 = name.replace(/-ext/g, ' (ext)');
            return name2.replace(/\./g, ' ');
        }
    }
}
