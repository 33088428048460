import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'SearchTeamPipe', pure: false })

@Injectable()
export class SearchTeamPipe implements PipeTransform {
    transform(array: any[], query: string): any {
        if (query) {
            query = query.toLowerCase();
            return array.filter((value: any, index: number, arr: any) => {
                // tslint:disable-next-line:max-line-length
                if (query.startsWith('#')) {
                    // console.log(query);
                    if (value.tags) {
                        for (let i = 0; i < value.tags.length; i++) {
                            if (value.tags[i].value.toLowerCase().indexOf(query) > -1) {
                                return (value.tags[i].value.toLowerCase().indexOf(query) > -1);
                            }
                        }
                    }
                } else {
                        return (value.title.toLowerCase().indexOf(query) > -1)
                         || (value.creator.toLowerCase().indexOf(query) > -1)
                         || (value.note.toLowerCase().indexOf(query) > -1);
                }
            });
        }
        return array;
    }
}
