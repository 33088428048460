import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { debounceTime, take, tap } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { FirestoreService } from '../../services/firestore.service';

@Directive({
    selector: '[appFireFormSettings]',
})
export class FireFormSettingsDirective implements OnInit, OnDestroy {
    @Input() path: string;
    @Input() formGroup: FormGroup;
    @Input() dialogRef: MatDialogRef<string>;

    private _state: 'loading' | 'synced' | 'modified' | 'error';

    @Output() stateChange = new EventEmitter<string>();
    @Output() formError = new EventEmitter<string>();

    // Firestore Document
    private docRef: AngularFirestoreDocument;

    // Subscriptions
    formSub: Subscription;

    constructor(private afs: AngularFirestore, private fs: FirestoreService, private auth: AuthService) {}

    ngOnInit() {
        this.preloadData();
        this.autoSave();
    }

    // Loads initial form data from Firestore
    preloadData() {
        // console.log(this.path);
        this.state = 'loading';
        this.docRef = this.getDocRef(this.path);
        this.docRef
            .valueChanges()
            .pipe(
                tap(doc => {
                    // console.log(doc);
                    this.formGroup.patchValue(doc);
                    this.formGroup.markAsPristine();
                    this.state = 'synced';
                }),
                take(1),
            )
            .subscribe();
    }

    // Autosaves form changes
    autoSave() {
        this.formSub = this.formGroup.valueChanges
            .pipe(
                tap(change => {
                    this.state = 'modified';
                }),
                debounceTime(3000),
                tap(change => {
                    if (this.formGroup.valid && this._state === 'modified') {
                        this.setDoc();
                    }
                }),
            )
            .subscribe();
    }

    @HostListener('ngSubmit', ['$event'])
    onSubmit(e) {
        // console.log(e);
        this.setDoc();
        this.dialogRef.close();
    }

    // Determines if path is a collection or document
    getDocRef(path: string): any {
        // console.log(path);
        // console.log(path.split('/'));
        // console.log(path.split('/')[1]);
        if (path.split('/')[1]) {
            // console.log('doc');
            return this.afs.doc(path);
        } else {
            // console.log('collection');
            return this.afs.doc(`${path.split('/')[0]}/${this.afs.createId()}`);
        }
    }

    // Writes changes to Firestore
    // on teste si la date vient de firestore (timeStamp)
    // Ou si la date vient du composant DatePicker (JS Date)
    async setDoc() {
        try {
            await this.fs.upsert(this.docRef, this.formGroup.value, this.auth.currentEmail);
            this.state = 'synced';
        } catch (err) {
            console.log(err);
            this.formError.emit(err.message);
            this.state = 'error';
        }
    }
    // Setter for state changes
    set state(val) {
        this._state = val;
        this.stateChange.emit(val);
    }

    ngOnDestroy() {
        if (this.formSub) {
            this.formSub.unsubscribe();
        }
    }
}
