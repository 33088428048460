import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TeamService } from 'src/app/services/team.service';
import { TodoService } from 'src/app/services/todo.service';
import { LoadingService } from '../services/loading.service';

@Component({
    selector: 'app-home-dashboard',
    templateUrl: './home-dashboard.component.html',
    styleUrls: ['./home-dashboard.component.css'],
})
export class HomeDashboardComponent implements OnInit, OnDestroy {
    countOpenTodosAllSub: Subscription;
    countOpenWithoutTargetTodosSub: Subscription;
    countOpenWithTargetOKTodosSub: Subscription;
    countOpenLateTodosSub: Subscription;
    countOpenWithoutTeamTodosSub: Subscription;
    countClosedTodosSub: Subscription;
    countTeamsAllSub: Subscription;
    countTeamsAdminSub: Subscription;
    countTeams8DSub: Subscription;
    countTeamsQVTSub: Subscription;
    countOpenTodosAll: number;
    countOpenWithoutTargetTodos: number;
    countOpenWithTargetOKTodos: number;
    countOpenLateTodos: number;
    countOpenWithoutTeamTodos: number;
    countClosedTodos: number;
    countTeamsAll: number;
    countTeamsAdmin: number;
    countTeams8D: number;
    countTeamsQVT: number;
    todosdata;
    cards = [];
    todosSub: Subscription;
    logoqvt: string;
    logo8d: string;

    constructor(private todoservice: TodoService, private teamservice: TeamService, public loading: LoadingService) {
        this.logoqvt = './assets/images/logoqvt.png';
        this.logo8d = './assets/images/logo8d.png';
    }

    ngOnInit() {
        this.loading.isLoading$.next(true);
        this.cards = this.todoservice.cards();
        this.todosSub = this.todoservice.getUserTodosKpi().subscribe(kpi => {
            this.todosdata = [
                ['open (with target ok)', kpi['open'], true],
                ['open (without target)', kpi['openwithouttarget'], true],
                ['closed', kpi['isDone'], true],
                ['open (late)', kpi['late'], true],
            ];
            this.loading.isLoading$.next(false);
        });
        // Je récupère le count des todo ALl
        this.countOpenTodosAllSub = this.todoservice.getUserOpenTodosAll().subscribe(
            x => {
                this.countOpenTodosAll = x;
            },
            error => console.log('Observer a reçu une erreur : ' + error),
        );
        // Je récupère le count des todo sans data target
        this.countOpenWithoutTargetTodosSub = this.todoservice.getUserOpenWithoutTargetTodos().subscribe(
            x => {
                this.countOpenWithoutTargetTodos = x;
            },
            error => console.log('Observer a reçu une erreur : ' + error),
        );
        // Je récupère le count des todo ouvertes avec dates target OK
        this.countOpenWithTargetOKTodosSub = this.todoservice.getUserOpenTodosWithTargetOK().subscribe(
            x => {
                this.countOpenWithTargetOKTodos = x;
            },
            error => console.log('Observer a reçu une erreur : ' + error),
        );
        // Je récupère le count des todo ouvertes
        this.countOpenLateTodosSub = this.todoservice.getUserLateTodos().subscribe(
            x => {
                this.countOpenLateTodos = x;
            },
            error => console.log('Observer a reçu une erreur : ' + error),
        );
        // Je récupère le count des todo fermés
        this.countClosedTodosSub = this.todoservice.getUserClosedTodos().subscribe(
            x => {
                this.countClosedTodos = x;
            },
            error => console.log('Observer a reçu une erreur : ' + error),
        );
        // Je récupère le count des todo fermés
        this.countTeamsAllSub = this.teamservice.getUserTeams().subscribe(
            x => {
                this.countTeamsAll = x.length;
            },
            error => console.log('Observer a reçu une erreur : ' + error),
        );
        // Je récupère le count des todo fermés
        this.countTeamsAdminSub = this.teamservice.getUserTeamsAdmin().subscribe(
            x => {
                this.countTeamsAdmin = x.length;
            },
            error => console.log('Observer a reçu une erreur : ' + error),
        );
        // Je récupère le count des todo fermés
        this.countTeams8DSub = this.teamservice.getUserTeams8D().subscribe(
            x => {
                this.countTeams8D = x.length;
            },
            error => console.log('Observer a reçu une erreur : ' + error),
        );
        // Je récupère le count des todo fermés
        this.countTeamsQVTSub = this.teamservice.getUserTeamsQVT().subscribe(
            x => {
                this.countTeamsQVT = x.length;
            },
            error => console.log('Observer a reçu une erreur : ' + error),
        ); // Je récupère le count des todo fermés
        this.countOpenWithoutTeamTodosSub = this.todoservice.getUserMyOpenTodosWithoutTeam().subscribe(
            x => {
                this.countOpenWithoutTeamTodos = x;
            },
            error => console.log('Observer a reçu une erreur : ' + error),
        );
    }

    ngOnDestroy(): void {
        if (this.countClosedTodosSub) {
            this.countClosedTodosSub.unsubscribe();
        }
        if (this.countOpenTodosAllSub) {
            this.countOpenTodosAllSub.unsubscribe();
        }
        if (this.countOpenLateTodosSub) {
            this.countOpenLateTodosSub.unsubscribe();
        }
        if (this.countOpenWithoutTargetTodosSub) {
            this.countOpenWithoutTargetTodosSub.unsubscribe();
        }
        if (this.countTeamsAllSub) {
            this.countTeamsAllSub.unsubscribe();
        }
        if (this.countTeamsAdminSub) {
            this.countTeamsAdminSub.unsubscribe();
        }
        if (this.countTeams8D) {
            this.countTeams8DSub.unsubscribe();
        }
        if (this.countTeamsQVTSub) {
            this.countTeamsQVTSub.unsubscribe();
        }
        if (this.todosSub) {
            this.todosSub.unsubscribe();
        }
    }
}
