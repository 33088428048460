<div>
    <div style="margin-left: 1%">
        <img [src]="logoqvt" height="25px" *ngIf="team?.type === 'QVT'" style="float:left" />
        <img [src]="logo8d" height="25px" *ngIf="team?.type === '8D'" style="float:left" />
        <div class="card-title">{{ team?.title }}</div>
    </div>

    <mat-tab-group>
        <mat-tab label="Description">
            <div style="margin: 10px">
                <p *ngIf="team?.note">Note : {{ team?.note }}</p>
                <p *ngIf="team?.creator">{{ 'Create by' | translate }} : {{ team?.creator | email | titlecase }}</p>
                <p *ngIf="team?.createdAt">
                    {{ 'Create at' | translate }} : {{ team?.createdAt.toDate() | date: 'dd/MM/yyyy' }}
                </p>
                <div>
                    <tag-input
                        [ngModel]="team?.tags"
                        [editable]="true"
                        [addOnBlur]="true"
                        [theme]="'dark'"
                        [clearOnBlur]="true"
                        [onAdding]="transform"
                        (onRemove)="removeTag($event, key)"
                        (onAdd)="addTag($event, key)"
                        (onSelect)="filterTag($event)"
                    >
                        <tag-input-dropdown [focusFirstElement]="true" [autocompleteItems]="autocompleteItems">
                        </tag-input-dropdown>
                    </tag-input>
                </div>
                <mat-chip-list>
                    <mat-chip color="primary" selected *ngIf="team?.type">Type : {{ team?.type }}</mat-chip>
                    <mat-chip *ngIf="team?.teamQVT">Team QVT : {{ team?.teamQVT }}</mat-chip>
                </mat-chip-list>
                <br />
                <button
                    mat-stroked-button
                    style="margin-right: 10px;"
                    matTooltip="{{ 'Update' | translate }}"
                    (click)="openForm(key, team?.type)"
                >
                    <mat-icon>edit</mat-icon>
                </button>
                <button
                    mat-stroked-button
                    matTooltip="{{ 'Delete' | translate }}"
                    [disabled]="!isOwnerUserConnected(team?.creator) || team?.todosCount > 0"
                    (click)="DeleteModal(key)"
                >
                    <mat-icon>delete_forever</mat-icon>
                </button>
                <br />
                <div fxLayout="row" *ngIf="!(kpi?.isDone == 0 && kpi?.open == 0 && kpi?.late == 0 && kpi?.open2 == 0)">
                    <mat-card
                        fxFlex
                        style="background-color: transparent;
                    box-shadow: none;"
                    >
                        <highcharts-chart
                            [Highcharts]="Highcharts"
                            [constructorType]="chartConstructor"
                            [options]="chartOptions"
                            [(update)]="updateFlag"
                            [oneToOne]="oneToOneFlag"
                        >
                        </highcharts-chart>
                    </mat-card>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="{{ 'Open Todo' | translate }} ({{ openTodosLength }})">
            <div style="margin: 10px">
                <div>
                    <mat-form-field>
                        <input matInput (keyup)="applyFilter($any($event.target).value)" [(ngModel)]="search" placeholder="{{'search' | translate}}" />
                        <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="resetSearchFilter()">
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                </div>
                <button
                mat-mini-fab
                color="warn"
                    style="margin-right: 10px;"
                    matTooltip="{{ 'Add Todo' | translate }}"
                    (click)="addForm(key, team?.title)"
                >
                    <mat-icon>add</mat-icon>
                </button>

                <mat-table
                    #table
                    #sort="matSort"
                    [dataSource]="dataSource"
                    [trackBy]="trackByUid"
                    matSort
                    class="animate"
                    style="height: 100%"
                >
                    <ng-container matColumnDef="title">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Title' | translate }} </mat-header-cell>
                        <mat-cell *matCellDef="let todo"> {{ todo.title }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="creator">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'Create by' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let todo"> {{ todo.creator | email | titlecase }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="owner">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Owner' | translate }} </mat-header-cell>
                        <mat-cell *matCellDef="let todo">{{ todo.owner | email | titlecase }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="dateTarget">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'Target date' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let todo">
                            <span *ngIf="todo?.dateTarget">{{
                                todo?.dateTarget.toDate() | date: 'dd/MM/yyyy'
                            }}</span></mat-cell
                        >
                    </ng-container>

                    <ng-container matColumnDef="close">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <button
                                mat-button
                                (click)="openDialogCloseReopen(row?.key, row?.isDone)"
                                *ngIf="!row?.isDone && row?.teamID === key"
                                matTooltip="{{ 'close todo' | translate }}"
                            >
                                <mat-icon style="color: #006837">archive</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="message">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let row">
                    <button mat-button
                    *ngIf="row?.teamID === key"
                        matTooltip="{{ 'Comments' | translate }}"
                        matTooltipPosition="above"
                        (click)="openDialogMessages(row?.key, row?.owner, row?.title)"
                    >
                        <mat-icon matBadge="{{ row?.messageCount }}" matBadgeOverlap="true">message </mat-icon>
                        </button>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="drive">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let row">
                <button mat-button *ngIf="row?.teamID === key" (click)='openDrivePicker(row?.key)'matTooltip="{{ 'Documents' | translate }}" matTooltipPosition="above">
                    <mat-icon matBadge="{{row?.driveFiles?.length}}" matBadgeOverlap="true"><img [src]="logoDrive" height="18px" width="20px" style='vertical-align: 2px;'></mat-icon>
                    </button>
              </mat-cell>
            </ng-container>

                    <ng-container matColumnDef="edit">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <button
                                mat-button
                                (click)="editForm(row?.teamID, row?.key)"
                                *ngIf="!row?.isDone && row?.teamID === key"
                                matTooltip="{{ 'Update' | translate }}"
                            >
                                <mat-icon>edit</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>



                    <ng-container matColumnDef="shared">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <span *ngIf="row?.teamID !== key">{{ row?.teamName }}</span>
                            <mat-icon
                                *ngIf="row?.teamID !== key"
                                style="color: rgb(241, 150, 75)"
                                matTooltip="{{'sharedTooltip' | translate }}"
                                >share</mat-icon>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="createdAt">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'Create at' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let todo">
                            {{ todo?.createdAt?.toDate() | date: 'dd/MM/yyyy' }}</mat-cell
                        >
                    </ng-container>

                    <ng-container matColumnDef="tags">
                        <mat-header-cell *matHeaderCellDef> Tags </mat-header-cell>
                        <mat-cell *matCellDef="let todo">
                            <div *ngIf="todo?.tags">
                                <span *ngFor="let tag of todo?.tags">
                                    {{ tag?.display }}
                                </span>
                            </div>
                        </mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="category">
                      <mat-header-cell *matHeaderCellDef mat-sort-header> Cat. </mat-header-cell>
                      <mat-cell *matCellDef="let todo"> {{ todo.category }} </mat-cell>
                  </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: displayedColumns"
                        [ngClass]="{ 'closed': row.isDone, 'late': !row.isDone && isLate(row.dateTarget) }"
                        class="animate"
                    ></mat-row>
                </mat-table>
            </div>
        </mat-tab>
        <mat-tab label="{{ 'Closed Todo' | translate }} ({{ closedTodosLength }})">
            <div style="margin: 10px">
                <div class="example-header">
                    <mat-form-field>
                        <input matInput (keyup)="applyFilterClosed($any($event.target).value)"  [(ngModel)]="searchClosed" placeholder="{{'search' | translate}}" />
                        <button mat-button *ngIf="searchClosed" matSuffix mat-icon-button aria-label="Clear" (click)="resetSearchClosedFilter()">
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                </div>

                <mat-table
                    #table
                    #sort2="matSort"
                    [dataSource]="dataSource2"
                    [trackBy]="trackByUid"
                    matSort
                    class="animate"
                    style="height: 100%"
                >
                    <ng-container matColumnDef="title">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Title' | translate }} </mat-header-cell>
                        <mat-cell *matCellDef="let todo"> {{ todo.title }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="creator">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'Create by' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let todo"> {{ todo.creator | email | titlecase }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="owner">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Owner' | translate }} </mat-header-cell>
                        <mat-cell *matCellDef="let todo"> {{ todo.owner | email | titlecase }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="closedAt">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'closedDate' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let todo"> {{ todo?.closedAt?.toDate() | date: 'dd/MM/yyyy' }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="createdAt">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'Create at' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let todo">
                            {{ todo?.createdAt?.toDate() | date: 'dd/MM/yyyy' }}</mat-cell
                        >
                    </ng-container>

                    <ng-container matColumnDef="tags">
                        <mat-header-cell *matHeaderCellDef> Tags </mat-header-cell>
                        <mat-cell *matCellDef="let todo">
                            <div *ngIf="todo?.tags">
                                <span *ngFor="let tag of todo?.tags">
                                    {{ tag?.display }}
                                </span>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumnsClosed"></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: displayedColumnsClosed"
                        [ngClass]="{ 'closed': row.isDone, 'late': !row.isDone && isLate(row.dateTarget) }"
                        class="animate"
                    ></mat-row>
                </mat-table>
            </div>
        </mat-tab>
        <mat-tab label="{{ 'Members' | translate }} ({{ team?.members.length }})">
            <div style="margin: 10px;">
                <mat-list *ngFor="let member of team?.members; let i = index">
                    <mat-list-item>
                        <p [ngClass]="{ 'team-creator': isCreatorForDelete(member, team?.creator) }">
                            {{ member | email | titlecase }}
                        </p>
                    </mat-list-item>
                </mat-list>
                <br />
                <mat-divider></mat-divider>
                <br />

                <button mat-stroked-button matTooltip="{{ 'memberAdd' | translate }}" (click)="openFormMembers(key)">
                    <mat-icon>group_add</mat-icon>
                </button>
            </div>
        </mat-tab>
        <!-- <mat-tab label="{{ 'indicators' | translate }}">
            <div fxLayout="row">
                <mat-card fxFlex style="width: 100%;display: block;">
                    <highcharts-chart
                        [Highcharts]="Highcharts"
                        [constructorType]="chartConstructor"
                        [options]="chartOptions"
                        [(update)]="updateFlag"
                        [oneToOne]="oneToOneFlag"
                    >
                    </highcharts-chart>
                </mat-card>
            </div>
        </mat-tab> -->
    </mat-tab-group>
</div>
