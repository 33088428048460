import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import * as moment from 'moment';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { FirestoreService } from './firestore.service';
import firebase from 'firebase/compat/app';

const CALENDAR_API_URL = 'https://www.googleapis.com/calendar/v3';

export interface User {
    name: string;
}

@Injectable({
    providedIn: 'root',
})
export class TodoService {
    todoCollection: AngularFirestoreCollection<any>;
    teamsCollection: AngularFirestoreCollection<any>;
    teamDocument: AngularFirestoreDocument<any>;
    todoDocument: AngularFirestoreDocument<any>;
    usersSoitecList: AngularFireList<any>;
    teamName = '';
    todo$: Observable<any>;
    teams$: Observable<any>;
    usersSoitecArray: User[] = [];
    today;
    dateToday;
    siteUrl = environment.siteUrl;
    currentEmail;
    myteams;
    myteamsSub: Subscription;
    categoriesQVT: string[] = [
        'Environnement/Equipment',
        'Cooperation/Support',
        'Recognition/Feeling listened to',
        'Relationship with hierarchy/team meetings',
        'Workload/fatigue',
        'Confidence in the future/recommendation of the company',
        'Involvement/pride to work for Soitec',
        'Autonomy/well-being/balance',
        'Quality of information/effectiveness of new orga',
        'Debrief meetings',
    ];
    categories8D: string[] = ['3D', '4D', '5D', '6D', '7D', '8D'];

    constructor(
        private auth: AuthService,
        af: AngularFireDatabase,
        public afs: AngularFirestore,
        private fs: FirestoreService,
        private snackBar: MatSnackBar,
        private http: HttpClient,
    ) {
        console.log('init todoservice');
        this.usersSoitecList = af.list('users');
        this.today = new Date();
    }

    openSnackBar(message, action) {
        this.snackBar.open(message, action, { duration: 5000 });
    }

    getUserEmail(): Observable<any> {
        return this.auth.user$;
    }

    getUserTodosKpi(): Observable<any> {
        return this.getUserEmail().pipe(
            mergeMap(currentUser => {
                if (currentUser) {
                    return this.getTodosKpi(currentUser.email);
                } else {
                    return of('');
                }
            }),
        );
    }

    cards() {
        return [71, 78, 39, 66];
    }

    getTodosKpi(user): Observable<any[]> {
        const todosdata = this.afs.collection('todos', ref => ref.where('owner', '==', user));
        return todosdata.valueChanges().pipe(
            map(todos => {
                const myTodosData = [];
                myTodosData['isDone'] = 0;
                myTodosData['open'] = 0;
                myTodosData['late'] = 0;
                myTodosData['openwithouttarget'] = 0;
                todos.forEach(x => {
                    if (x['isDone'] === true) {
                        myTodosData['isDone'] = myTodosData['isDone'] + 1;
                    }
                    if (x['isDone'] === false && !x['dateTarget']) {
                        myTodosData['openwithouttarget'] = myTodosData['openwithouttarget'] + 1;
                    }
                    if (x['dateTarget']) {
                        if (x['dateTarget'].toDate() <= this.today && x['isDone'] === false) {
                            myTodosData['late'] = myTodosData['late'] + 1;
                        } else if (x['dateTarget'].toDate() >= this.today && x['isDone'] === false) {
                            myTodosData['open'] = myTodosData['open'] + 1;
                        }
                    }
                });
                return myTodosData;
            }),
        );
    }

    getUserClosedTodos(): Observable<any> {
        return this.getUserEmail().pipe(
            mergeMap(currentUser => {
                if (currentUser) {
                    return this.getMyClosedTodos(currentUser.email);
                } else {
                    return of('');
                }
            }),
        );
    }

    getUserOpenTodosAll(): Observable<any> {
        return this.getUserEmail().pipe(
            mergeMap(currentUser => {
                if (currentUser) {
                    return this.getMyOpenTodosAll(currentUser.email);
                } else {
                    return of('');
                }
            }),
        );
    }

    getUserOpenTodosWithTargetOK(): Observable<any> {
        return this.getUserEmail().pipe(
            mergeMap(currentUser => {
                if (currentUser) {
                    return this.getMyOpenTodosWithTargetOK(currentUser.email);
                } else {
                    return of('');
                }
            }),
        );
    }

    getUserMyOpenTodosWithoutTeam(): Observable<any> {
        return this.getUserEmail().pipe(
            mergeMap(currentUser => {
                if (currentUser) {
                    return this.getMyOpenTodosWithoutTeam(currentUser.email);
                } else {
                    return of('');
                }
            }),
        );
    }

    getUserLateTodos(): Observable<any> {
        return this.getUserEmail().pipe(
            mergeMap(currentUser => {
                if (currentUser) {
                    return this.getMyLateTodos(currentUser.email);
                } else {
                    return of('');
                }
            }),
        );
    }

    getUserOpenWithoutTargetTodos(): Observable<any> {
        return this.getUserEmail().pipe(
            mergeMap(currentUser => {
                if (currentUser) {
                    return this.getMyOpenTodosWithoutTarget(currentUser.email);
                } else {
                    return of('');
                }
            }),
        );
    }

    getMyOpenTodosAll(user): Observable<any> {
        const myClosedTodos = this.afs.collection('todos', ref =>
            ref.where('owner', '==', user).where('isDone', '==', false),
        );
        return myClosedTodos.valueChanges().pipe(
            map(openTodos => {
                return openTodos.length;
            }),
        );
    }

    getMyOpenTodosWithTargetOK(user): Observable<any> {
        const myClosedTodos = this.afs.collection('todos', ref =>
            ref
                .where('owner', '==', user)
                .where('isDone', '==', false)
                .where('dateTarget', '>', this.today),
        );
        return myClosedTodos.valueChanges().pipe(
            map(openTodos => {
                return openTodos.length;
            }),
        );
    }

    getMyOpenTodosWithoutTarget(user): Observable<any> {
        const myClosedTodos = this.afs.collection('todos', ref =>
            ref
                .where('owner', '==', user)
                .where('isDone', '==', false)
                .where('dateTarget', '==', ''),
        );
        return myClosedTodos.valueChanges().pipe(
            map(openTodos => {
                return openTodos.length;
            }),
        );
    }

    getMyOpenTodosWithoutTeam(user): Observable<any> {
        const myOpenTodosWithoutTeam = this.afs.collection('todos', ref =>
            ref
                .where('owner', '==', user)
                .where('isDone', '==', false)
                .where('teamID', '==', ''),
        );
        return myOpenTodosWithoutTeam.valueChanges().pipe(
            map(r => {
                return r.length;
            }),
        );
    }

    getMyClosedTodos(user): Observable<any> {
        const myClosedTodos = this.afs.collection('todos', ref =>
            ref.where('owner', '==', user).where('isDone', '==', true),
        );
        return myClosedTodos.valueChanges().pipe(
            map(closeTodos => {
                return closeTodos.length;
            }),
        );
    }

    getMyLateTodos(user): Observable<number> {
        const myLateTodos = this.afs.collection('todos', ref =>
            ref
                .where('owner', '==', user)
                .where('isDone', '==', false)
                .where('dateTarget', '<', this.today),
        );
        return myLateTodos.valueChanges().pipe(
            map(lateTodos => {
                return lateTodos.length;
            }),
        );
    }

    getTodoCategoriesFromTeam(teamID): Observable<any> {
        const todoCategories = this.afs.collection('teams').doc(teamID);
        return todoCategories.valueChanges().pipe(
            map(x => {
                return x['categories'];
            }),
        );
    }

    getTodoCampaignsFromTeam(teamID): Observable<any> {
        const todoCampaigns = this.afs.collection('teams').doc(teamID);
        return todoCampaigns.valueChanges().pipe(
            map(x => {
                return x['campaignsQVT'];
            }),
        );
    }

    getTodoTypeFromTeam(teamID): Observable<any> {
        const team = this.afs.collection('teams').doc(teamID);
        return team.valueChanges().pipe(
            map(x => {
                return x['type'];
            }),
        );
    }

    getTodoTeamorgnivFromTeam(teamID): Observable<any> {
        const team = this.afs.collection('teams').doc(teamID);
        return team.valueChanges().pipe(
            map(x => {
                return {
                    'orgniv1': x['orgniv1'],
                    'orgniv2': x['orgniv2'],
                    'orgniv3': x['orgniv3'],
                    'orgniv4': x['orgniv4'],
                    'orgniv5': x['orgniv5'],
                };
            }),
        );
    }

    getMyTags(user): Observable<any> {
        const myTags = this.afs.collection('todos', ref => ref.where('owner', '==', user));
        const tagArray = [];
        return myTags.valueChanges().pipe(
            map(todo => {
                todo.forEach(t => {
                    if (t['tags']) {
                        t['tags'].forEach(element => {
                            tagArray.push(element.display.substring(1));
                        });
                    }
                    // console.log(t['tags']);
                });
                return _.union(tagArray);
            }),
        );
    }

    getMyTeamsTags(user): Observable<any> {
        const myTags = this.afs.collection('teams', ref => ref.where('members', 'array-contains', user));
        const tagArray = [];
        return myTags.valueChanges().pipe(
            map(todo => {
                todo.forEach(t => {
                    if (t['tags']) {
                        t['tags'].forEach(element => {
                            tagArray.push(element.display.substring(1));
                        });
                    }
                    // console.log(t['tags']);
                });
                return _.union(tagArray);
            }),
        );
    }

    getUsersSoitec(): User[] {
        this.usersSoitecArray = [];
        this.usersSoitecList.valueChanges().subscribe(mails => {
            mails.forEach(mail => {
                this.usersSoitecArray.push({ name: mail.toLowerCase() });
            });
        });
        return this.usersSoitecArray;
    }

    getUserMyTeams(): Observable<any> {
        return this.getUserEmail().pipe(
            mergeMap(currentUser => {
                if (currentUser) {
                    return this.getMyTeams(currentUser.email);
                } else {
                    return of('');
                }
            }),
        );
    }

    getMyTeams(user): Observable<any> {
        const teamsCollection = this.afs.collection('teams', ref => ref.where('members', 'array-contains', user));
        return teamsCollection.snapshotChanges().pipe(
            map(arr =>
                arr.map(snap => {
                    const data = snap.payload.doc.data();
                    const key = snap.payload.doc.id;
                    return { key, ...data as {}};
                }),
            ),
        );
    }

    // Function principale de récupération des todos (du user connecté) combinée suivant les filtres
    getTodoAll(statusFilter$, delayFilter$, periodStart$, periodEnd$): Observable<any[]> {
        return combineLatest(this.getUserEmail(), statusFilter$, delayFilter$, periodStart$, periodEnd$).pipe(
            switchMap(([user, isDone, delayfilter, periodStart, periodEnd]) =>
                this.afs
                    .collection('todos', ref => {
                        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
                        // console.log(periodEnd);
                        if (isDone === false && user) {
                            // console.log(isDone);
                            query = query
                                .where('isDone', '==', isDone)
                                .where('owner', '==', user.email)
                                .orderBy('dateTarget');
                        } else if (isDone === true && periodStart !== null && periodEnd !== null) {
                            query = query
                                .where('owner', '==', user.email)
                                .where('closedAt', '>=', periodStart)
                                .where('closedAt', '<=', periodEnd)
                                .orderBy('closedAt');
                        } else if (
                            (isDone === true && periodStart === null) ||
                            (isDone === true && periodEnd === null)
                        ) {
                            query = query
                                .where('owner', '==', user.email)
                                .where('isDone', '==', true)
                                .orderBy('closedAt', 'desc');
                        } else if (isDone === null) {
                            query = query.where('owner', '==', user.email).orderBy('dateTarget');
                        }
                        if (delayfilter === false && isDone !== true) {
                            query = query.where('isDone', '==', false).where('dateTarget', '<', this.today);
                        }
                        return query;
                    })
                    .snapshotChanges()
                    .pipe(
                        map(arr => {
                            return arr.map(snap => {
                                const data = snap.payload.doc.data();
                                const key = snap.payload.doc.id;
                                return { key, ...data as {}};
                            });
                        }),
                    ),
            ),
        );
    }

    getTodoByTeam(teamID, statusFilter$, delayFilter$, periodStart$, periodEnd$): Observable<any[]> {
        return combineLatest(this.auth.user$, statusFilter$, delayFilter$, periodStart$, periodEnd$).pipe(
            switchMap(([user, isDone, delayfilter, periodStart, periodEnd]) =>
                this.afs
                    .collection('todos', ref => {
                        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
                        if (isDone === false) {
                            query = query
                                .where('teamsShared', 'array-contains', teamID)
                                .where('isDone', '==', isDone)
                                .orderBy('dateTarget');
                        } else if (isDone === true && periodStart !== null && periodEnd !== null) {
                            query = query
                                .where('teamID', '==', teamID)
                                .where('closedAt', '>=', periodStart)
                                .where('closedAt', '<=', periodEnd)
                                .orderBy('closedAt');
                        } else if (
                            (isDone === true && periodStart === null) ||
                            (isDone === true && periodEnd === null)
                        ) {
                            query = query
                                .where('teamID', '==', teamID)
                                .where('isDone', '==', true)
                                .orderBy('closedAt', 'desc');
                        } else if (isDone === null) {
                            query = query.where('teamID', '==', teamID).orderBy('dateTarget');
                        }
                        if (delayfilter === false && isDone !== true) {
                            query = query.where('isDone', '==', false).where('dateTarget', '<', this.today);
                        }
                        return query;
                    })
                    .snapshotChanges()
                    .pipe(
                        map(arr => {
                            return arr.map(snap => {
                                const data = snap.payload.doc.data();
                                const key = snap.payload.doc.id;
                                return { key, ...data as {}};
                            });
                        }),
                    ),
            ),
        );
    }

    getTodoByTeamList(teamID): Observable<any[]> {
        console.log('getTodoByTeamList');
        const teamsCollection = this.afs.collection('todos', ref =>
            ref
                .where('teamsShared', 'array-contains', teamID)
                .where('isDone', '==', false)
                .orderBy('dateTarget'),
        );
        return teamsCollection.snapshotChanges().pipe(
            map(arr =>
                arr.map(snap => {
                    const data = snap.payload.doc.data();
                    const key = snap.payload.doc.id;
                    return { key, ...data as {}};
                }),
            ),
        );
    }

    getClosedTodoByTeamList(teamID: any): Observable<any[]> {
        console.log('getTodoByTeamList');
        const teamsCollection = this.afs.collection('todos', ref =>
            ref
                .where('teamID', '==', teamID)
                .where('isDone', '==', true)
                .orderBy('closedAt'),
        );
        return teamsCollection.snapshotChanges().pipe(
            map(arr =>
                arr.map(snap => {
                    const data = snap.payload.doc.data();
                    const key = snap.payload.doc.id;
                    return { key, ...data as {}};
                }),
            ),
        );
    }

    getUserTeams(): Observable<any> {
        return this.getUserEmail().pipe(
            mergeMap(currentUser => {
                if (currentUser) {
                    return this.getTeams(currentUser.email);
                } else {
                    return of('');
                }
            }),
        );
    }

    getUserTeamsNoTyped(): Observable<any> {
        return this.getUserEmail().pipe(
            mergeMap(currentUser => {
                if (currentUser) {
                    return this.getTeamsNoTyped(currentUser.email);
                } else {
                    return of('');
                }
            }),
        );
    }

    getUserTags(): Observable<any> {
        return this.getUserEmail().pipe(
            mergeMap(currentUser => {
                if (currentUser) {
                    return this.getMyTags(currentUser.email);
                } else {
                    return of('');
                }
            }),
        );
    }

    getUserTeamTags(): Observable<any> {
        return this.getUserEmail().pipe(
            mergeMap(currentUser => {
                if (currentUser) {
                    return this.getMyTeamsTags(currentUser.email);
                } else {
                    return of('');
                }
            }),
        );
    }

    // Function : myTeams = [] Observable
    getTeams(user): Observable<any[]> {
        this.teamsCollection = this.afs.collection('teams', ref => ref.where('members', 'array-contains', user));
        return this.teamsCollection.snapshotChanges().pipe(
            map(teams => {
                const myTeams = [];
                teams.forEach(team => {
                    myTeams.push({ key: team.payload.doc.id, ...team.payload.doc.data() });
                });
                return myTeams;
            }),
        );
    }

    // Function : myTeams = [] Observable
    getTeamsNoTyped(user): Observable<any[]> {
        this.teamsCollection = this.afs.collection('teams', ref => ref.where('members', 'array-contains', user));
        return this.teamsCollection.snapshotChanges().pipe(
            map(teams => {
                const myTeams = [];
                teams.forEach(team => {
                    if (!team.payload.doc.data().type) {
                        myTeams.push({ key: team.payload.doc.id, ...team.payload.doc.data() });
                    }
                });
                return myTeams;
            }),
        );
    }

    // retourne le nom de la team
    getATeamName(teamId) {
        if (teamId !== '') {
            this.teamDocument = this.afs.collection('teams').doc(teamId);
            return this.teamDocument.valueChanges().pipe(
                map((team: any) => {
                    this.teamName = team.title;
                    return this.teamName;
                }),
            );
        } else {
            return null;
        }
    }

    getTeam(teamId): Observable<any[]> {
        this.teamDocument = this.afs.collection('teams').doc(teamId);
        return this.teamDocument.valueChanges();
    }

    getTodo(todoId): Observable<any[]> {
        return combineLatest(this.getUserEmail()).pipe(
            switchMap(([user]) =>
                this.afs
                    .collection('todos', ref => {
                        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
                        {
                            query = query
                                .where('owner', '==', user.email)
                                .where( firebase.firestore.FieldPath.documentId() , '==', todoId)
                        return query;
                        }
                    })
                    .snapshotChanges()
                    .pipe(
                        map(arr => {
                            return arr.map(snap => {
                                const data = snap.payload.doc.data();
                                const key = snap.payload.doc.id;
                                return { key, ...data as {} };
                            });
                        }),
                    ),
            ),
        );
    }

    // A supprimer ?
    getTodosByTeam(statusFilter$, teamID): Observable<any[]> {
        return combineLatest(statusFilter$).pipe(
            switchMap(([isDone]) =>
                this.afs
                    .collection('todos', ref => {
                        console.log(teamID);
                        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
                        if (isDone === 'isDone') {
                            query = query
                                .where('isDone', '==', false)
                                .where('owner', '==', 'gianni.prata@soitec.com')
                                .where('teamID', '==', teamID);
                        } else if (isDone === 'isNotDone') {
                            query = query
                                .where('isDone', '==', true)
                                .where('owner', '==', 'gianni.prata@soitec.com')
                                .where('teamID', '==', teamID);
                        } else if (isDone === 'all') {
                            query = query.where('owner', '==', 'gianni.prata@soitec.com').where('teamID', '==', teamID);
                        } else {
                            query = query
                                .where('isDone', '==', false)
                                .where('owner', '==', 'gianni.prata@soitec.com')
                                .where('teamID', '==', teamID);
                        }
                        return query;
                    })
                    .snapshotChanges()
                    .pipe(
                        map(arr => {
                            console.log(arr);
                            return arr.map(snap => {
                                // console.log(snap);
                                const data = snap.payload.doc.data();
                                const key = snap.payload.doc.id;
                                return { key, ...data as {} };
                            });
                        }),
                    ),
            ),
        );
    }

    calendarCreateEvent(todo) {
        todo.dateTarget.toDate();
        const target = moment(todo.dateTarget.toDate()).format('YYYY-MM-DD');
        console.log(todo);
        console.log(target);

        const event = {
            'username': todo.owner,
            'id': todo.key,
            'event': {
                'end': {
                    'date': target,
                },
                'start': {
                    'date': target,
                },
                'summary': 'Action WeDo App : ' + todo.title,
                'colorId': '7',
                'reminders': {
                    'useDefault': false,
                    'overrides': [
                        {
                            'method': 'popup',
                            'minutes': 720,
                        },
                    ],
                },
                'locked': true,
                'description': todo.description + '\n ' + this.siteUrl + '',
                'transparency': 'transparent',
                'visibility': 'private',
            },
        };

        // requête API Calendar Event insert
        this.http.post('https://calendarapi-186007.appspot.com/api/cal/addevent/', event).subscribe(
            res => {
                this.fs.upsert(`todos/${todo.key}`, { 'eventID': res['id'] }, this.auth.currentEmail);
                console.log(res['id']);
                this.openSnackBar(`L'action a bien été ajoutée à votre Calendar`, 'Info');
            },
            err => {
                console.log('API Calendar AddEvent Error occured');
                this.openSnackBar(`L'action n'a pas été ajoutée à votre Calendar`, 'Error');
            },
        );
    }

    async updateEvent(eventID, target) {
        try {
            const dateForCal = moment(target.toDate()).format('YYYY-MM-DD');
            const token = await this.auth.authorize();
            const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
            await this.http.patch(`${CALENDAR_API_URL}/calendars/primary/events/${eventID}`, {
                    start: {
                        date: dateForCal,
                    },
                    end: {
                        date: dateForCal,
                    },
                    status: 'confirmed',
                },
                { headers },
            ).toPromise();

            this.openSnackBar("L'action a bien été mise à jour dans votre Calendar", 'Info');
        } catch (err) {
            this.openSnackBar('Il y a eu un problème lors de la mise à jour de votre Calendar', 'Error');
            return 'Error :' + err;
        }
    }

    save_todo(value) {
        // Utilise les utilitaires Firestore pour ajouter updatedAt et createdAt
        this.fs
            .update('todos', value, this.auth.currentEmail)
            .then(() => this.openSnackBar('Le todo à bien été mis à jour', 'Info'))
            .catch(err => console.log(err));
    }
}
