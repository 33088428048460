import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class StaticsDataService {
    orgniv1 = [
        { value: 'Soitec Bernin', viewValue: 'Soitec Bernin' },
        { value: 'Soitec worldwide', viewValue: 'Soitec worldwide' },
    ];

    orgniv2 = [
        { value: 'Procurement', viewValue: 'Procurement', parent: 'Soitec Bernin' },
        { value: 'Direction générale', viewValue: 'Direction générale', parent: 'Soitec Bernin' },
        { value: 'Finance', viewValue: 'Finance', parent: 'Soitec Bernin' },
        { value: 'RH/Medical/Sureté/HSE', viewValue: 'RH/Medical/Sureté/HSE', parent: 'Soitec Bernin' },
        { value: 'Légal', viewValue: 'Légal', parent: 'Soitec Bernin' },
        { value: 'Quality', viewValue: 'Quality', parent: 'Soitec Bernin' },
        { value: 'Customer Group', viewValue: 'Customer Group', parent: 'Soitec Bernin' },
        { value: 'Strat Office/Public affairs', viewValue: 'Strat Office/Public affairs', parent: 'Soitec Bernin' },
        { value: 'Innovation', viewValue: 'Innovation', parent: 'Soitec Bernin' },
        { value: 'Global business', viewValue: 'Global business', parent: 'Soitec Bernin' },
        { value: 'Industrial operations', viewValue: 'Industrial operations', parent: 'Soitec Bernin' },
        { value: 'Customer Group ww', viewValue: 'Customer Group ww', parent: 'Soitec worldwide' },
        { value: 'Global Business', viewValue: 'Global Business', parent: 'Soitec worldwide' },
        { value: 'Strat office - Innov ww', viewValue: 'Strat office - Innov ww', parent: 'Soitec worldwide' },
        { value: 'SG&A ww', viewValue: 'SG&A ww', parent: 'Soitec worldwide' },
        { value: 'Global sourcing ww', viewValue: 'Global sourcing ww', parent: 'Soitec worldwide' },
        { value: 'Quality ww', viewValue: 'Quality ww', parent: 'Soitec worldwide' },
        { value: 'Industrial Operations ww', viewValue: 'Industrial Operations ww', parent: 'Soitec worldwide' },
    ];

    orgniv3 = [
        { value: 'Global sourcing', viewValue: 'Global sourcing', parent: 'Procurement' },
        { value: 'Procurement Bernin', viewValue: 'Procurement Bernin', parent: 'Procurement' },
        { value: 'RH/medical/Sureté', viewValue: 'RH/medical/Sureté', parent: 'RH/Medical/Sureté/HSE' },
        { value: 'HSE', viewValue: 'HSE', parent: 'RH/Medical/Sureté/HSE' },
        { value: 'Qualité corporate', viewValue: 'Qualité corporate', parent: 'Quality' },
        {
            value: 'Qualité produit & cust. satisfaction',
            viewValue: 'Qualité produit & cust. satisfaction',
            parent: 'Quality',
        },
        { value: 'Qualité produit France', viewValue: 'Qualité produit France', parent: 'Quality' },
        { value: 'Innovation managers', viewValue: 'Innovation managers', parent: 'Innovation' },
        { value: 'Material Engineering', viewValue: 'Material Engineering', parent: 'Innovation' },
        { value: 'Soitec Lab', viewValue: 'Soitec Lab', parent: 'Innovation' },
        { value: 'Platforms/research', viewValue: 'Platforms/research', parent: 'Innovation' },
        { value: 'IP', viewValue: 'IP', parent: 'Innovation' },
        { value: 'Product dev', viewValue: 'Product dev', parent: 'Innovation' },
        { value: 'Product integration', viewValue: 'Product integration', parent: 'Innovation' },
        { value: 'Expertise labs', viewValue: 'Expertise labs', parent: 'Innovation' },
        { value: 'Pilot Engineering', viewValue: 'Pilot Engineering', parent: 'Innovation' },
        { value: 'Global business BU', viewValue: 'Global business BU', parent: 'Global business' },
        { value: 'Global business managers', viewValue: 'Global business managers', parent: 'Global business' },
        { value: 'Global business planning', viewValue: 'Global business planning', parent: 'Global business' },
        {
            value: 'Industrial operations managers',
            viewValue: 'Industrial operations managers',
            parent: 'Industrial operations',
        },
        { value: 'Info Services/Program mgt', viewValue: 'Info Services/Program mgt', parent: 'Industrial operations' },
        { value: 'Equipement', viewValue: 'Equipement', parent: 'Industrial operations' },
        { value: 'Facilities & IE', viewValue: 'Facilities & IE', parent: 'Industrial operations' },
        { value: 'Manuf B3', viewValue: 'Manuf B3', parent: 'Industrial operations' },
        { value: 'Engineering', viewValue: 'Engineering', parent: 'Industrial operations' },
        { value: 'Process', viewValue: 'Process', parent: 'Industrial operations' },
        { value: 'Production & Logistics', viewValue: 'Production & Logistics', parent: 'Industrial operations' },
        { value: 'Customer Support ww', viewValue: 'Customer Support ww', parent: 'Global Business' },
        { value: 'Epigan BU ww', viewValue: 'Epigan BU ww', parent: 'Global Business' },
        { value: 'Global Business BU ww', viewValue: 'Global Business BU ww', parent: 'Global Business' },
        {
            value: 'Industrial Operations Managers ww',
            viewValue: 'Industrial Operations Managers ww',
            parent: 'Industrial Operations ww',
        },
        { value: 'Facilities  & IE ww', viewValue: 'Facilities  & IE ww', parent: 'Industrial Operations ww' },
        { value: 'CIM ww', viewValue: 'CIM ww', parent: 'Industrial Operations ww' },
        { value: 'Equipment ww', viewValue: 'Equipment ww', parent: 'Industrial Operations ww' },
        { value: 'Process ww', viewValue: 'Process ww', parent: 'Industrial Operations ww' },
        { value: 'Production ww', viewValue: 'Production ww', parent: 'Industrial Operations ww' },
    ];

    orgniv4 = [
        { value: 'Soitec Lab nouvelles filières', viewValue: 'Soitec Lab nouvelles filières', parent: 'Soitec Lab' },
        { value: 'Soitec Lab SiC', viewValue: 'Soitec Lab SiC', parent: 'Soitec Lab' },
        { value: 'Pilot engineering staff', viewValue: 'Pilot engineering staff', parent: 'Pilot Engineering' },
        { value: 'Labo carac', viewValue: 'Labo carac', parent: 'Pilot Engineering' },
        { value: 'Failure Analysis', viewValue: 'Failure Analysis', parent: 'Pilot Engineering' },
        { value: 'Pilot line', viewValue: 'Pilot line', parent: 'Pilot Engineering' },
        { value: 'BU Specialty SOI', viewValue: 'BU Specialty SOI', parent: 'Global business BU' },
        { value: 'BU FD SOI', viewValue: 'BU FD SOI', parent: 'Global business BU' },
        { value: 'Compound/Cust Sup', viewValue: 'Compound/Cust Sup', parent: 'Global business BU' },
        { value: 'BU RF SOI', viewValue: 'BU RF SOI', parent: 'Global business BU' },
        { value: 'Global Business BU Filtres', viewValue: 'Global Business BU Filtres', parent: 'Global business BU' },
        { value: 'Program mgt', viewValue: 'Program mgt', parent: 'Info Services/Program mgt' },
        { value: 'Info Services', viewValue: 'Info Services', parent: 'Info Services/Program mgt' },
        { value: 'Data/Infra/Security', viewValue: 'Data/Infra/Security', parent: 'Info Services/Program mgt' },
        { value: 'Equipement IPR', viewValue: 'Equipement IPR', parent: 'Equipement' },
        { value: 'Equipement MTBE', viewValue: 'Equipement MTBE', parent: 'Equipement' },
        { value: 'Equipement NCPR', viewValue: 'Equipement NCPR', parent: 'Equipement' },
        { value: 'Equipement Semaine Nuit', viewValue: 'Equipement Semaine Nuit', parent: 'Equipement' },
        { value: 'Equipement TTH', viewValue: 'Equipement TTH', parent: 'Equipement' },
        { value: 'Equipement Transverse', viewValue: 'Equipement Transverse', parent: 'Equipement' },
        { value: 'Equipement WE Jour', viewValue: 'Equipement WE Jour', parent: 'Equipement' },
        { value: 'Equipement WE Nuit', viewValue: 'Equipement WE Nuit', parent: 'Equipement' },
        { value: 'Equipement B1/B2 managers', viewValue: 'Equipement B1/B2 managers', parent: 'Equipement' },
        { value: 'Facilities Chem/Gaz', viewValue: 'Facilities Chem/Gaz', parent: 'Facilities & IE' },
        { value: 'Facilities managers/IE/SEexp', viewValue: 'Facilities managers/IE/SEexp', parent: 'Facilities & IE' },
        { value: 'Facilities MEW & Tertiaire', viewValue: 'Facilities MEW & Tertiaire', parent: 'Facilities & IE' },
        { value: 'Facilities project', viewValue: 'Facilities project', parent: 'Facilities & IE' },
        { value: 'Manuf B3 managers', viewValue: 'Manuf B3 managers', parent: 'Manuf B3' },
        { value: 'Maintenance B3', viewValue: 'Maintenance B3', parent: 'Manuf B3' },
        { value: 'Process B3 wet/metro', viewValue: 'Process B3 wet/metro', parent: 'Manuf B3' },
        { value: 'Process B3 Dry', viewValue: 'Process B3 Dry', parent: 'Manuf B3' },
        { value: 'Manuf semaine B3 - CDP', viewValue: 'Manuf semaine B3 - CDP', parent: 'Manuf B3' },
        { value: 'Production B3 semaine', viewValue: 'Production B3 semaine', parent: 'Manuf B3' },
        { value: 'Production E4/Maint/Process WE', viewValue: 'Production E4/Maint/Process WE', parent: 'Manuf B3' },
        { value: 'Engineering managers', viewValue: 'Engineering managers', parent: 'Engineering' },
        { value: 'POI process integration', viewValue: 'POI process integration', parent: 'Engineering' },
        { value: 'Yield & SPCS', viewValue: 'Yield & SPCS', parent: 'Engineering' },
        { value: 'SOI process integration', viewValue: 'SOI process integration', parent: 'Engineering' },
        { value: 'Process B1/B2 managers', viewValue: 'Process B1/B2 managers', parent: 'Process' },
        { value: 'Process IPR', viewValue: 'Process IPR', parent: 'Process' },
        { value: 'Process MTBE', viewValue: 'Process MTBE', parent: 'Process' },
        { value: 'Process NCP2R', viewValue: 'Process NCP2R', parent: 'Process' },
        { value: 'Process Semaine Nuit', viewValue: 'Process Semaine Nuit', parent: 'Process' },
        { value: 'Process TTH', viewValue: 'Process TTH', parent: 'Process' },
        { value: 'Process WE jour', viewValue: 'Process WE jour', parent: 'Process' },
        { value: 'Process WE nuit', viewValue: 'Process WE nuit', parent: 'Process' },
        {
            value: 'Prod planning/contrôle & magasin',
            viewValue: 'Prod planning/contrôle & magasin',
            parent: 'Production & Logistics',
        },
        { value: 'Transport & Logistics', viewValue: 'Transport & Logistics', parent: 'Production & Logistics' },
        { value: 'RdG Prod & Logistics', viewValue: 'RdG Prod & Logistics', parent: 'Production & Logistics' },
        { value: 'Production MTBE', viewValue: 'Production MTBE', parent: 'Production & Logistics' },
        { value: 'Production NCPR', viewValue: 'Production NCPR', parent: 'Production & Logistics' },
        { value: 'Production RIT', viewValue: 'Production RIT', parent: 'Production & Logistics' },
        { value: 'Equipment engineers ww', viewValue: 'Equipment engineers ww', parent: 'Equipment ww' },
        { value: 'Equipment technicians ww', viewValue: 'Equipment technicians ww', parent: 'Equipment ww' },
        { value: 'Process engineers ww', viewValue: 'Process engineers ww', parent: 'Process ww' },
        {
            value: 'Process managers and team leaders ww',
            viewValue: 'Process managers and team leaders ww',
            parent: 'Process ww',
        },
        { value: 'Yield & Integration team ww', viewValue: 'Yield & Integration team ww', parent: 'Process ww' },
        { value: 'Process technicians ww', viewValue: 'Process technicians ww', parent: 'Process ww' },
        { value: 'Production shift A ww', viewValue: 'Production shift A ww', parent: 'Production ww' },
        { value: 'Production shift B ww', viewValue: 'Production shift B ww', parent: 'Production ww' },
        { value: 'Production shift C ww', viewValue: 'Production shift C ww', parent: 'Production ww' },
        { value: 'Production shift D ww', viewValue: 'Production shift D ww', parent: 'Production ww' },
        { value: 'Prod_logistics engineers ww', viewValue: 'Prod_logistics engineers ww', parent: 'Production ww' },
    ];

    orgniv5 = [
        { value: 'BU Filter POI', viewValue: 'BU Filter POI', parent: 'Global Business BU Filtres' },
        { value: 'Frec|n|sys', viewValue: 'Frec|n|sys', parent: 'Global Business BU Filtres' },
        { value: 'Corp and business APPS', viewValue: 'Corp and business APPS', parent: 'Info Services' },
        { value: 'Info Services managers', viewValue: 'Info Services managers', parent: 'Info Services' },
        { value: 'CIM', viewValue: 'CIM', parent: 'Info Services' },
        { value: 'Infra & Security', viewValue: 'Infra & Security', parent: 'Data/Infra/Security' },
        { value: 'Data Management', viewValue: 'Data Management', parent: 'Data/Infra/Security' },
        { value: 'Equipement PolRefCarac', viewValue: 'Equipement PolRefCarac', parent: 'Equipement NCPR' },
        { value: 'Equipement Netcol', viewValue: 'Equipement Netcol', parent: 'Equipement NCPR' },
        { value: 'CDP B3', viewValue: 'CDP B3', parent: 'Manuf semaine B3 - CDP' },
        { value: 'Production B3 E1', viewValue: 'Production B3 E1', parent: 'Production B3 semaine' },
        { value: 'Production B3 E2', viewValue: 'Production B3 E2', parent: 'Production B3 semaine' },
        { value: 'Production B3 E3', viewValue: 'Production B3 E3', parent: 'Production B3 semaine' },
        { value: 'Production B3 E4', viewValue: 'Production B3 E4', parent: 'Production B3 semaine' },
        { value: 'Production B3 E5', viewValue: 'Production B3 E5', parent: 'Production B3 semaine' },
        { value: 'Production B3 staff', viewValue: 'Production B3 staff', parent: 'Production B3 semaine' },
        { value: 'Yield', viewValue: 'Yield', parent: 'Yield & SPCS' },
        { value: 'PCS', viewValue: 'PCS', parent: 'Yield & SPCS' },
        {
            value: 'Prod planning/contrôle & mag managers',
            viewValue: 'Prod planning/contrôle & mag managers',
            parent: 'Prod planning/contrôle & magasin',
        },
        { value: 'Magasin', viewValue: 'Magasin', parent: 'Prod planning/contrôle & magasin' },
        {
            value: 'Contrôle de production',
            viewValue: 'Contrôle de production',
            parent: 'Prod planning/contrôle & magasin',
        },
        { value: 'Production MTBE managers', viewValue: 'Production MTBE managers', parent: 'Production MTBE' },
        { value: 'Prod MTBE E1', viewValue: 'Prod MTBE E1', parent: 'Production MTBE' },
        { value: 'Prod MTBE E2', viewValue: 'Prod MTBE E2', parent: 'Production MTBE' },
        { value: 'Prod MTBE E3', viewValue: 'Prod MTBE E3', parent: 'Production MTBE' },
        { value: 'Prod MTBE E4', viewValue: 'Prod MTBE E4', parent: 'Production MTBE' },
        { value: 'Prod MTBE E5', viewValue: 'Prod MTBE E5', parent: 'Production MTBE' },
        { value: 'Production NCPR managers', viewValue: 'Production NCPR managers', parent: 'Production NCPR' },
        { value: 'Prod NCPR E1', viewValue: 'Prod NCPR E1', parent: 'Production NCPR' },
        { value: 'Prod NCPR E2', viewValue: 'Prod NCPR E2', parent: 'Production NCPR' },
        { value: 'Prod NCPR E3', viewValue: 'Prod NCPR E3', parent: 'Production NCPR' },
        { value: 'Prod NCPR E4', viewValue: 'Prod NCPR E4', parent: 'Production NCPR' },
        { value: 'Prod NCPR E5', viewValue: 'Prod NCPR E5', parent: 'Production NCPR' },
        { value: 'Production RIT managers', viewValue: 'Production RIT managers', parent: 'Production RIT' },
        { value: 'Prod RIT E1 IPR', viewValue: 'Prod RIT E1 IPR', parent: 'Production RIT' },
        { value: 'Prod RIT E1 TTH', viewValue: 'Prod RIT E1 TTH', parent: 'Production RIT' },
        { value: 'Prod RIT E2 IPR', viewValue: 'Prod RIT E2 IPR', parent: 'Production RIT' },
        { value: 'Prod RIT E2 TTH', viewValue: 'Prod RIT E2 TTH', parent: 'Production RIT' },
        { value: 'Prod RIT E3 IPR', viewValue: 'Prod RIT E3 IPR', parent: 'Production RIT' },
        { value: 'Prod RIT E3 TTH', viewValue: 'Prod RIT E3 TTH', parent: 'Production RIT' },
        { value: 'Prod RIT E4 IPR', viewValue: 'Prod RIT E4 IPR', parent: 'Production RIT' },
        { value: 'Prod RIT E4 TTH', viewValue: 'Prod RIT E4 TTH', parent: 'Production RIT' },
        { value: 'Prod RIT E5 IPR', viewValue: 'Prod RIT E5 IPR', parent: 'Production RIT' },
        { value: 'Prod RIT E5 TTH', viewValue: 'Prod RIT E5 TTH', parent: 'Production RIT' },
    ];
}
