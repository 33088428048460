<div *ngIf="team$ | async as team">
  <!-- {{team | json}} -->
  <button mat-icon-button [mat-dialog-close] class="closeModal">
    <mat-icon>close</mat-icon>
  </button>
  <h2>{{'memberAdd' | translate}}</h2>
  <br>
  <form [formGroup]="formGroupAddMember">
    <mat-list-item *ngFor="let member of team.members; let i = index">
      <mat-card id="team-members">
        <p>
          <button mat-icon-button (click)="delMember(i, team.members)" [disabled]="isCreatorForDelete(member, team?.creator, team?.type)" (keydown.enter)="$event.preventDefault()">
            <mat-icon>clear</mat-icon>
          </button>
          <span>{{member | email | titlecase}}</span>
        </p>
      </mat-card>
    </mat-list-item>
    <mat-form-field style="width: 100%">
      <input type="text" placeholder="{{ 'who' | translate }} ?" matInput formControlName="member"
        [matAutocomplete]="auto" (click)="getUsers()">
      <mat-autocomplete autoActiveFirstOption  #auto="matAutocomplete" (optionSelected)="onSelectionChanged($event, team.members)">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</div>
