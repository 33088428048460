<!-- <pre>{{ myForm.value | json }}</pre>
<pre>{{ myForm.valid | json }}</pre> -->
<img  [src]="logoqvt" height="50px" *ngIf="type === 'QVT'">
<img  [src]="logo8d" height="50px" *ngIf="type === '8D'">
<div id="todo-form">
  <button mat-icon-button [mat-dialog-close] class="closeModal">
    <mat-icon>close</mat-icon>
  </button>
  <br>
  <br>
  <form [formGroup]="myForm" appFireForm path='todos/{{todoID}}' (stateChange)="changeHandler($event)"
    [dialogRef]="dialogRef">
    <div class="columns">
      <!-- <div class="column">
      <span>Reactive Form Data</span><br> {{ myForm.value | json }} {{myForm.status}}
    </div> -->
    </div>
    <mat-form-field class="m-right">
      <input #title matInput placeholder="{{ 'Title' | translate }}" formControlName="title" autocomplete="off">
    </mat-form-field>

    <mat-form-field class="m-right">
      <textarea matInput placeholder="Note" formControlName="description" autocomplete="off"></textarea>
    </mat-form-field>
    <!-- Team -->
    <div>
      <mat-form-field style="width: 95%;display: inline-block;" *ngIf="authorizeTeamChange(type)">
        <mat-select #teamInput placeholder="Team" formControlName="teamID" (selectionChange)="teamNameSet($event)">
          <mat-option *ngFor="let team of teamsForList | async" [value]="team.key">
            {{team?.title}}
          </mat-option>
        </mat-select>
        <button mat-button *ngIf="!teamInput.empty" matSuffix mat-icon-button aria-label="Clear" (click)="clearTeam()">
          <mat-icon style='font-size: 16px !important;'>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <br>
    <!-- TEAMS shared -->
    <div>
      <mat-form-field style="width: 95%;display: inline-block;">
        <mat-select #teamSharedInput placeholder="Teams watch list" formControlName="teamsShared" multiple>
          <mat-option *ngFor="let team of teamsForList | async" [value]="team?.key"
            [disabled]="team?.key === myForm.controls['teamID'].value">
            {{team?.title}}
          </mat-option>
        </mat-select>
        <!-- <button mat-button *ngIf="!teamSharedInput.empty" matSuffix mat-icon-button aria-label="Clear"
          (click)="clearSharedTeam()">
          <mat-icon style='font-size: 16px !important;'>close</mat-icon>
        </button> -->
      </mat-form-field>
    </div>

    <!-- <mat-form-field class="m-right">
      <input matInput placeholder="teamName" formControlName="teamName">
    </mat-form-field> -->
    <div>
    <mat-form-field style="width: 30%">
      <input #owner type="text" placeholder="{{ 'Owner' | translate }}" matInput formControlName="owner"
        [matAutocomplete]="auto" (keyup)="changeMyControl()">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngIf="(filteredOptions | async).length == 0 " class="is-loading">
          <mat-spinner diameter="20"></mat-spinner>
        </mat-option>
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name"
          (onSelectionChange)="onSelectedOption($event.source.selected, option['id']);">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
      <button mat-button *ngIf="owner.value" matSuffix mat-icon-button aria-label="Clear" (click)="owner.value=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-checkbox formControlName="transverse" (change)="transverse($event)" *ngIf="type === 'QVT'">{{'Transverse' | translate}}</mat-checkbox>
  </div>
<br>
    <div>
      <mat-form-field style="width: 150px;display: inline-block;">
        <input #dateInput matInput [matDatepicker]="picker" placeholder="{{ 'Target date' | translate }}"
          formControlName="dateTarget" autocomplete="off">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #picker></mat-datepicker>
      </mat-form-field>
      <!-- <button mat-button *ngIf="!dateInput.empty" matSuffix mat-icon-button aria-label="Clear"
        (click)="clearTargetDate()">
        <mat-icon style='font-size: 16px !important;'>close</mat-icon>
      </button> -->
    </div>

    <div *ngIf="type === 'QVT'">
      <mat-form-field style="width: 95%;display: inline-block;">
        <mat-select  placeholder="{{ 'campaign' | translate }}" formControlName="campaignQVT">
          <mat-option *ngFor="let campaign of campaignsqvt" [value]="campaign">
            {{campaign}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="categories">
      <mat-form-field style="width: 95%;display: inline-block;">
        <mat-select #catInput placeholder="{{ 'category' | translate }}" formControlName="category">
          <mat-option *ngFor="let category of categories" [value]="category">
            {{category | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <tag-input matInput formControlName="tags" placeholder="+ Tag" secondaryPlaceholder="+ Tag" [addOnBlur]="true"
      [clearOnBlur]="true" [theme]="'dark'" [onAdding]="transform">
      <tag-input-dropdown [focusFirstElement]="true" [autocompleteItems]="autocompleteItems">
      </tag-input-dropdown>
    </tag-input>
    <p style="color: #2B377B; font-size: 11px;font-style: italic">{{'tagHelp' | translate}}</p>
    <br>
    <div style="text-align: right;">
      <button mat-flat-button class="valid-button"
        [disabled]="myForm?.invalid || state !== 'modified'">{{ 'button_save' | translate }}</button>
    </div>

  </form>
  <br>
  <div class="formStatus">
    <div>
      <span *ngIf="state === 'modified'">{{ 'Data not saved' | translate }}</span>
      <span *ngIf="state === 'synced'">{{ 'Data saved' | translate }}</span>
      <span *ngIf="state === 'error'">{{ 'Data error' | translate }}</span>
    </div>
  </div>
</div>
