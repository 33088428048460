<mat-sidenav-container class="team-container" autosize>
    <mat-sidenav #sidenav [mode]="mode" class="sidenav-list" (backdropClick)="close()" opened="true">
        <p style="float: right">
            <button
                mat-button
                (click)="sidenav.toggle()"
                style="
            margin: 10px;"
            >
                <mat-icon>close</mat-icon>
            </button>
        </p>

        <app-team-list></app-team-list>
        <a
            mat-fab
            class="addTeam-button"
            matTooltip="{{ 'Add Team' | translate }}"
            matTooltipPosition="above"
            (click)="openBottomSheet()"
        >
            <mat-icon>add</mat-icon>
        </a>
    </mat-sidenav>
    <mat-sidenav-content class="team-content">
        <p>
            <button mat-button (click)="sidenav.toggle()" *ngIf="!sidenav.opened">
                <mat-icon>list</mat-icon> {{'Teams list' | translate}}
            </button>
        </p>

        <router-outlet> </router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
