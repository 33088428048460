import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
})
export class LoginComponent implements AfterViewInit {
    
    @ViewChild('googleButton')
    googleButton: ElementRef<HTMLDivElement>;

    constructor(public auth: AuthService) {}

    ngAfterViewInit(): void {
        this.auth.initLogin('onetap', this.googleButton.nativeElement);
    }
}
