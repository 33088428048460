import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'SearchPipe', pure: false })
@Injectable()
export class SearchPipe implements PipeTransform {
    transform(array: any[], query: string): any {
        if (query) {
            query = query.toLowerCase();
            return array.filter((value: any, index: number, arr: any) => {
                // tslint:disable-next-line:max-line-length
                if (query.startsWith('#')) {
                    // console.log(query);
                    if (value.tags) {
                        for (let i = 0; i < value.tags.length; i++) {
                            if (value.tags[i].value.toLowerCase().indexOf(query) > -1) {
                                return value.tags[i].value.toLowerCase().indexOf(query) > -1;
                            }
                        }
                    }
                } else if (value.category) {
                    return (
                        value.title.toLowerCase().indexOf(query) > -1 ||
                        value.owner.toLowerCase().indexOf(query) > -1 ||
                        value.category.toLowerCase().indexOf(query) > -1 ||
                        value.campaignQVT.toLowerCase().indexOf(query) > -1 ||
                        value.description.toLowerCase().indexOf(query) > -1
                    );
                } else {
                    return (
                        value.title.toLowerCase().indexOf(query) > -1 ||
                        value.owner.toLowerCase().indexOf(query) > -1 ||
                        value.description.toLowerCase().indexOf(query) > -1
                    );
                }
            });
        }
        return array;
    }
}
