<mat-nav-list>
    <a mat-list-item (click)="openFormQVT()">
        <span mat-line>{{'Team QVT' | translate}}</span>
        <span mat-line>{{ 'For QVT actions plan' | translate }}</span>
    </a>

    <a mat-list-item (click)="openForm()">
        <span mat-line>Team Standard</span>
    </a>

</mat-nav-list>
