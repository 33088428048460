import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import * as Highcharts from 'highcharts';
import { Observable, of as observableOf, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { TeamService } from 'src/app/services/team.service';
import { TodoService } from 'src/app/services/todo.service';
import { FormTodoComponent } from 'src/app/todo/form/form.component';
import { DrivefilesComponent, MessagesComponent, TodocloseComponent } from 'src/app/todo/todo.component';
import { FormMembersComponent } from '../../form-members/form-members.component';
import { FormTeamQvtComponent } from '../../form-team/form-team-qvt.component';
import { FormTeamComponent } from '../../form-team/form-team.component';
import { DeleteTeamModalComponent } from '../../team.component';

export interface User {
    name: string;
}
@Component({
    selector: 'app-team-details',
    templateUrl: './team-details.component.html',
    styleUrls: ['./team-details.component.css'],
})
export class TeamDetailsComponent implements OnInit, OnDestroy {
    public team;
    public key: string;
    myteams$: Observable<any>;
    mytagsSub: Subscription;
    teamsCollection: AngularFirestoreCollection<any>;
    options: User[] = [];
    filteredOptions: Observable<User[]>;
    formGroupAddMember: FormGroup;
    // search pipe
    search: string = null;
    searchClosed: string = null;
    autocompleteItems = ['P1'];
    // Firestore Document
    private docRef: AngularFirestoreDocument;
    todosOfTeam$: Observable<any[]>;
    displayedColumns = [
        'title',
        'dateTarget',
        'owner',
        'tags',
        'category',
        'createdAt',
        'creator',
        'shared',
        'message',
        'drive',
        'edit',
        'close',
    ];
    displayedColumnsClosed = ['title', 'createdAt', 'creator', 'closedAt', 'owner', 'tags'];
    Highcharts = Highcharts; // required
    chartConstructor = 'chart'; // optional string, defaults to 'chart'
    chartOptions: Highcharts.Options = {
        title: {
            text: 'Team Kpi',
        },
        tooltip: {
            pointFormat: '<b>{point.y}</b> ({point.percentage:.1f}%)',
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
            },
        },
        series: [
            {
                type: 'pie',
                allowPointSelect: true,
                colorByPoint: true,
                keys: ['name', 'y'],
                data: [],
            },
        ],
    }; // required
    updateFlag = true; // optional boolean
    oneToOneFlag = true; // optional boolean, defaults to false
    runOutsideAngular = false; // optional boolean, defaults to false
    dataSource: MatTableDataSource<any>;
    dataSource2: MatTableDataSource<any>;
    @ViewChild('sort', { static: true }) sort: MatSort;
    @ViewChild('sort2', { static: true }) sort2: MatSort;
    logoqvt: string;
    logo8d: string;
    today: Date;
    kpi;
    closedTodosLength: number;
    openTodosLength: number;
    logoDrive: string;

    constructor(
        public auth: AuthService,
        private router: Router,
        private activatedroute: ActivatedRoute,
        public afs: AngularFirestore,
        private teamservice: TeamService,
        private todoservice: TodoService,
        public dialog: MatDialog,
        private fb: FormBuilder,
        public snackBar: MatSnackBar,
    ) {
        this.logoqvt = './assets/images/logoqvt.png';
        this.logo8d = './assets/images/logo8d.png';
        this.logoDrive = './assets/images/drive.png';
        this.today = new Date();
    }

    ngOnInit() {
        // console.log(this.chartOptions.series[0].data.length);
        this.activatedroute.params.subscribe((params: Params) => {
            if (params.key) {
                this.key = params.key;
                this.createUpdateDataTable(this.key);
            } else {
                this.key = '';
            }
            this.teamservice.getTeam(this.key).subscribe(team => {
                this.team = team;
            });
            this.teamservice.getTeamsKpi(this.key).subscribe(kpi => {
                this.chartOptions.series[0]['data'] = [];
                this.chartOptions.series[0]['data'].push(
                    ['open', kpi['open'], true],
                    ['open (without target)', kpi['open2'], true],
                    ['closed', kpi['isDone'], true],
                    ['late (open)', kpi['late'], true],
                );
                this.updateFlag = true;
                this.kpi = kpi;
            });
        });
        this.myteams$ = this.todoservice.getUserTeams();

        // this.options = this.todoservice.getUsersSoitec();
        this.formGroupAddMember = this.fb.group({
            member: new FormControl(''),
        });
        this.filteredOptions = this.formGroupAddMember.controls.member.valueChanges.pipe(
            startWith<string | User>(''),
            map(value => (typeof value === 'string' ? value : value.name)),
            map(name => (name ? this._filter(name) : this.options.slice())),
        );
        // Subscription : mytags
        this.mytagsSub = this.todoservice.getUserTeamTags().subscribe(
            mytags => {
                this.autocompleteItems = mytags;
                // console.log(mytags);
            },
            error => console.log('Observer a reçu une erreur : ' + error),
        );
    }

    private _filter(name: string): User[] {
        const filterValue = name.toLowerCase();
        return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    ngOnDestroy() {
        // avoid memory leaks here by cleaning up after ourselves. If we
        // don't then we will continue to run our initialiseInvites()
        // method on every navigationEnd event.
        if (this.mytagsSub) {
            this.mytagsSub.unsubscribe();
        }
    }

    /**
     * @description Creates update data table
     * @param key
     */
    createUpdateDataTable(key: any) {
        this.todoservice.getTodoByTeamList(key).subscribe(data => {
            this.openTodosLength = data.length;
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.sort = this.sort;
            this.applyFilter(this.search);
        });
        this.todoservice.getClosedTodoByTeamList(this.key).subscribe(data2 => {
            this.closedTodosLength = data2.length;
            this.dataSource2 = new MatTableDataSource(data2);
            this.dataSource2.sort = this.sort2;
        });
    }

    openSnackBar(message, action) {
        this.snackBar.open(message, action, { duration: 5000 });
    }

    /**
     * @description Applys filter
     * @param filterValue
     */
    applyFilter(filterValue: string) {
        if (filterValue) {
            filterValue = filterValue.trim();
            filterValue = filterValue.toLowerCase();
            this.dataSource.filter = filterValue;
        }
    }

    /**
     * @description Reset search input text and remove filter in cache.
     */
    resetSearchFilter() {
        this.search = null;
        this.dataSource.filter = null;
        // this.cache.delete(CacheKey.DASHBOARD_SEARCH_FILTER);
    }

    /**
     * @description Reset search input text and remove filter in cache.
     */
    resetSearchClosedFilter() {
        this.searchClosed = null;
        this.dataSource2.filter = null;
        // this.cache.delete(CacheKey.DASHBOARD_SEARCH_FILTER);
    }

    /**
     * @description Applys filter closed
     * @param filterValue
     */
    applyFilterClosed(filterValue: string) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource2.filter = filterValue;
    }

    trackByUid(index, item) {
        return item.uid;
    }

    openForm(teamID?, type?): void {
        if (type === 'QVT') {
            const dialogRef = this.dialog.open(FormTeamQvtComponent, {
                height: '90%',
                width: '75%',
                autoFocus: false,
                data: { teamID: teamID },
            });
            dialogRef.afterClosed().subscribe(result => {
                this.createUpdateDataTable(this.key);
            });
        } else {
            const dialogRef = this.dialog.open(FormTeamComponent, {
                height: '90%',
                width: '75%',
                autoFocus: false,
                data: { teamID: teamID },
            });
            dialogRef.afterClosed().subscribe(result => {
                this.createUpdateDataTable(this.key);
            });
        }
    }

    openDialogMessages(key, owner, title): void {
        const dialogRef = this.dialog.open(MessagesComponent, {
            height: '99%',
            width: '80%',
            data: { fsid: key, owner: owner, title: title },
        });
        dialogRef.afterClosed().subscribe(result => {
            this.createUpdateDataTable(this.key);
        });
    }

    openDrivePicker(key): void {
        window.scrollTo(0, 0);
        setTimeout(() => {
            this.openDialogFD(key);
        }, 500);
    }

    openDialogFD(key: any): void {
        const dialogRef = this.dialog.open(DrivefilesComponent, {
            width: '600px',
            data: { fsid: key },
        });

        dialogRef.afterClosed().subscribe(result => {
            this.createUpdateDataTable(this.key);
        });
    }

    addForm(key, name): void {
        const dialogRef = this.dialog.open(FormTodoComponent, {
            height: '90%',
            width: '80%',
            autoFocus: false,
            data: { teamid: key, teamname: name },
        });
        dialogRef.afterClosed().subscribe(result => {
            this.createUpdateDataTable(this.key);
        });
    }

    editForm(teamID, todoID): void {
        const dialogRef = this.dialog.open(FormTodoComponent, {
            height: '90%',
            width: '80%',
            autoFocus: false,
            data: { teamid: teamID, todoid: todoID },
        });
        dialogRef.afterClosed().subscribe(result => {
            this.createUpdateDataTable(this.key);
        });
    }

    openDialogCloseReopen(key, isdone): void {
        const dialogRef = this.dialog.open(TodocloseComponent, {
            height: '99%',
            width: '80%',
            data: { fsid: key, isdone: isdone },
        });
        dialogRef.afterClosed().subscribe(result => {
            this.createUpdateDataTable(this.key);
        });
    }

    openFormMembers(teamID?): void {
        const dialogRef = this.dialog.open(FormMembersComponent, {
            height: '90%',
            width: '75%',
            autoFocus: false,
            data: { teamID: teamID },
        });
    }

    // TAGS add
    public transform(value): Observable<object> {
        console.log(value);
        if (value.value) {
            const item = { display: `#${value.value}`, value: `#${value.value}` };
            return observableOf(item);
        } else if (value) {
            const item = { display: `#${value}`, value: `#${value}` };
            return observableOf(item);
        } else {
            return;
        }
    }

    removeTag(event, key) {
        // console.log(event);
        const db = firebase.firestore();
        const todRef = db.collection('teams').doc(key);
        todRef
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    const tagsModified = doc.data().tags.filter(t => t.value !== event.value);
                    // console.log(tagsModified);
                    db.runTransaction(async transaction => {
                        // This code may get re-run multiple times if there are conflicts.
                        transaction.update(todRef, { tags: tagsModified });
                    })
                        .then(function() {
                            // console.log('Transaction successfully committed!');
                        })
                        .catch(function(error) {
                            // console.log('Transaction failed: ', error);
                        });
                } else {
                    // doc.data() will be undefined in this case
                    // console.log('No such document!');
                }
            })
            .catch(function(error) {
                console.log('Error getting document:', error);
            });
    }

    addTag(event, key) {
        const db = firebase.firestore();
        const todRef = db.collection('teams').doc(key);
        db.runTransaction(async transaction => {
            // This code may get re-run multiple times if there are conflicts.
            const doc = await transaction.get(todRef);
            // console.log('doc', doc);
            if (!doc.data().tags) {
                transaction.update(todRef, {
                    tags: [event],
                });
            } else {
                // console.log('else tag');
                const tags = doc.data().tags;
                tags.push(event);
                transaction.update(todRef, { tags: tags });
            }
        })
            .then(function() {
                console.log('Transaction successfully committed!');
            })
            .catch(function(error) {
                console.log('Transaction failed: ', error);
            });
    }

    filterTag(tag) {
        // console.log(tag);
        this.search = tag.value;
    }

    isLate(dateTarget): boolean {
        if (dateTarget) {
            return dateTarget.toDate() < this.today;
        }
    }

    isCreatorForDelete(member, creator): boolean {
        return member === creator;
    }

    isOwnerUserConnected(creator): boolean {
        return this.auth.currentEmail === creator;
    }

    DeleteModal(key): void {
        const dialogRef = this.dialog.open(DeleteTeamModalComponent);
        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                const teamtodelete = this.afs.collection('teams').doc(key);
                teamtodelete
                    .delete()
                    .then(() => {
                        this.openSnackBar('Team supprimée !', 'Info');
                    })
                    .then(() => {
                        this.router.navigate([`myteamsList`]);
                    })
                    .catch(err => {
                        this.openSnackBar("La Team n'a pas été supprimée !", 'Attention');
                    });
            }
        });
    }
}
