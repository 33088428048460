import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of as observableOf, Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { TodoService } from '../../services/todo.service';
import { StaticsDataService } from './../../services/statics-data.service';

export interface User {
    name: string;
}

@Component({
    selector: 'app-form-team-qvt',
    templateUrl: './form-team-qvt.component.html',
    styleUrls: ['./form-team-qvt.component.css'],
})
export class FormTeamQvtComponent implements OnInit, OnDestroy {
    currentEmail;
    myForm: FormGroup;
    docID;
    type;
    teamNameSub: Subscription;
    mytagsSub: Subscription;
    startDate = new Date();
    state: string;
    options: User[] = [];
    filteredOptions: Observable<User[]>;
    TeamupdatedAt;
    autocompleteItems = ['P1'];
    niv2Filtered = [];
    niv3Filtered = [];
    niv4Filtered = [];
    niv5Filtered = [];

    logoqvt;
    orgniv1: { value: string; viewValue: string }[];
    orgniv2: { value: string; viewValue: string; parent: string }[];
    orgniv3: { value: string; viewValue: string; parent: string }[];
    orgniv4: { value: string; viewValue: string; parent: string }[];
    orgniv5: { value: string; viewValue: string; parent: string }[];

    constructor(
        public dialogRef: MatDialogRef<string>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private auth: AuthService,
        private todoservice: TodoService,
        private fb: FormBuilder,
        public staticsdataservice: StaticsDataService,
    ) {
        this.docID = data.teamID;
        this.type = data.type;
        this.logoqvt = './assets/images/logoqvt.png';
    }

    ngOnInit() {
        // this.docID = this.route.snapshot.params['key'];
        this.myForm = this.fb.group({
            title: ['', Validators.required],
            note: [''],
            processusID: [''],
            members: [[this.auth.currentEmail], Validators.required],
            creator: [this.auth.currentEmail, Validators.required],
            tags: [[{ display: '#QVT', value: '#QVT' }]],
            orgniv1: ['', Validators.required],
            orgniv2: ['', Validators.required],
            orgniv3: [''],
            orgniv4: [''],
            orgniv5: [''],
            updatedAt: [''],
            categories: [[]],
            type: ['QVT'],
        });
        this.orgniv1 = this.staticsdataservice.orgniv1;
        this.orgniv2 = this.staticsdataservice.orgniv2.sort((a, b) => (a.value > b.value ? 1 : -1));
        this.orgniv3 = this.staticsdataservice.orgniv3.sort((a, b) => (a.value > b.value ? 1 : -1));
        this.orgniv4 = this.staticsdataservice.orgniv4.sort((a, b) => (a.value > b.value ? 1 : -1));
        this.orgniv5 = this.staticsdataservice.orgniv5.sort((a, b) => (a.value > b.value ? 1 : -1));
        // Subscription : mytags
        this.mytagsSub = this.todoservice.getUserTeamTags().subscribe(
            mytags => {
                this.autocompleteItems = mytags;
                // console.log(mytags);
            },
            error => console.log('Observer a reçu une erreur : ' + error),
        );
    }

    get title() {
        return this.myForm.get('title');
    }

    get niv2Value() {
        return this.myForm.get('orgniv2');
    }

    get niv3Value() {
        return this.myForm.get('orgniv3');
    }

    get niv4Value() {
        return this.myForm.get('orgniv4');
    }

    get niv5Value() {
        return this.myForm.get('orgniv5');
    }

    get dateTarget() {
        return this.myForm.get('dateTarget');
    }
    get isDone() {
        return this.myForm.get('isDone');
    }

    ngOnDestroy(): void {
        if (this.teamNameSub) {
            this.teamNameSub.unsubscribe();
        }
        if (this.mytagsSub) {
            this.mytagsSub.unsubscribe();
        }
    }

    private _filter(name: string): User[] {
        const filterValue = name.toLowerCase();
        return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    // TAGS add
    public transform(value): Observable<object> {
        console.log(value);
        if (value.value) {
            const item = { display: `#${value.value}`, value: `#${value.value}` };
            return observableOf(item);
        } else if (value) {
            const item = { display: `#${value}`, value: `#${value}` };
            return observableOf(item);
        } else {
            return;
        }
    }

    changeHandler(e) {
        this.state = e;
        // this.afs.doc(`teams/${this.docID}`).valueChanges().subscribe(team => {
        //   if (team) {
        //     this.TeamupdatedAt = team['updatedAt'].toDate();
        //   }
        // });
    }

    // Rebuild list every time the parent changes.
    orgniv1Changed(): void {
        const niv1 = this.myForm.get('orgniv1').value;
        this.niv2Filtered = this.orgniv2.filter(p => p.parent === niv1);
        this.myForm.controls['orgniv2'].patchValue('');
        this.myForm.controls['orgniv3'].patchValue('');
        this.myForm.controls['orgniv4'].patchValue('');
        this.myForm.controls['orgniv5'].patchValue('');
        if (niv1 === 'Soitec worldwide') {
            if (this.auth.currentEmail === 'valerie.hillairet@soitec.com') {
                this.myForm.controls['members'].setValue([this.auth.currentEmail, 'joanne.lin@soitec.com']);
            } else {
                this.myForm.controls['members'].setValue([
                    this.auth.currentEmail,
                    'valerie.hillairet@soitec.com',
                    'joanne.lin@soitec.com',
                ]);
            }
        } else if (niv1 === 'Soitec Bernin') {
            if (this.auth.currentEmail !== 'valerie.hillairet@soitec.com') {
                this.myForm.controls['members'].setValue([
                    this.auth.currentEmail,
                    'valerie.hillairet@soitec.com',
                ]);
            }
        }
    }

    // Rebuild list every time the parent changes.
    orgniv2Changed(e): void {
        const niv2 = this.myForm.get('orgniv2').value;
        this.niv3Filtered = this.orgniv3.filter(p => p.parent === niv2);
        this.myForm.controls['orgniv3'].patchValue('');
        this.myForm.controls['orgniv4'].patchValue('');
        this.myForm.controls['orgniv5'].patchValue('');
        this.myForm.controls['title'].patchValue(`QVT - ${e}`);
        if (this.niv3Filtered.length) {
            this.myForm.controls['orgniv3'].setValidators([Validators.required]);
            this.myForm.controls['orgniv3'].updateValueAndValidity();
        } else {
            this.myForm.controls['orgniv3'].setValidators([Validators.nullValidator]);
            this.myForm.controls['orgniv3'].updateValueAndValidity();
        }
    }

    // Rebuild list every time the parent changes.
    orgniv3Changed(e: any): void {
        const niv3 = this.myForm.get('orgniv3').value;
        this.niv4Filtered = this.orgniv4.filter(p => p.parent === niv3);
        this.myForm.controls['orgniv4'].patchValue('');
        this.myForm.controls['orgniv5'].patchValue('');
        this.myForm.controls['title'].patchValue(`QVT - ${e}`);
        if (this.niv4Filtered.length) {
            this.myForm.controls['orgniv4'].setValidators([Validators.required]);
            this.myForm.controls['orgniv4'].updateValueAndValidity();
        } else {
            this.myForm.controls['orgniv4'].setValidators([Validators.nullValidator]);
            this.myForm.controls['orgniv4'].updateValueAndValidity();
        }
    }

    // Rebuild list every time the parent changes.
    orgniv4Changed(e): void {
        const niv4 = this.myForm.get('orgniv4').value;
        this.niv5Filtered = this.orgniv5.filter(p => p.parent === niv4);
        this.myForm.controls['orgniv5'].patchValue('');
        this.myForm.controls['title'].patchValue(`QVT - ${e}`);
        if (this.niv5Filtered.length) {
            this.myForm.controls['orgniv5'].setValidators([Validators.required]);
            this.myForm.controls['orgniv5'].updateValueAndValidity();
        } else {
            this.myForm.controls['orgniv5'].setValidators([Validators.nullValidator]);
            this.myForm.controls['orgniv5'].updateValueAndValidity();
        }
    }

    // Rename title.
    orgniv5Changed(e): void {
        this.myForm.controls['title'].patchValue(`QVT - ${e}`);
    }
}
