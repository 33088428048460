import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

declare let google: any;

const SESSION_STORAGE_KEY = 'access_token';
const SESSION_DATE_KEY = 'token_date';

interface User {
    uid: string;
    email: string;
    photoURL?: string;
    displayName?: string;
    language?: string;
    viewMode?: string;
    createdAt?: any;
    fcmTokens?: { [token: string]: true };
    lastConnexion?: any;
}

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    user$: Observable<User>;
    userNotifs$: Observable<any>;
    onboarding = true;
    calendarItems: any[];
    calendarItem: any[];
    currentEmail;
    private loggedGoogle = false;

    constructor(
        private readonly afAuth: AngularFireAuth,
        private readonly afs: AngularFirestore,
        private readonly router: Router,
        private readonly ngZone: NgZone,
    ) {
        this.user$ = this.afAuth.authState.pipe(
            switchMap(user => {
                if (user) {
                    this.currentEmail = user.email;
                    return this.afs.doc<User>(`usersApp/${user.uid}`).valueChanges();
                } else {
                    return of(null);
                }
            }),
        );

        this.userNotifs$ = this.afAuth.authState.pipe(
            switchMap(user => {
                if (user) {
                    return this.afs
                        .collection(`usersApp/${user.uid}/notifications`, ref => ref.orderBy('sentAt', 'desc'))
                        .valueChanges({ idField: 'id' });
                } else {
                    return of(null);
                }
            }),
        );
    }

    isLoggedGoogle() {
        return this.loggedGoogle;
    }

    /// Firebase Server Timestamp
    get timestamp() {
        return firebase.firestore.FieldValue.serverTimestamp();
    }

    /**
     * Init google sign in
     * @param promptParentId 
     * @param buttonParentDiv 
     */
    initLogin(promptParentId: string, buttonParentDiv: HTMLDivElement) {
        google.accounts.id.initialize({
            client_id: environment.clientId,
            auto_select: true,
            cancel_on_tap_outside: false,
            prompt_parent_id: promptParentId,

            callback: resp => this.ngZone.run(() => {
                // We receive Google token
                this.loggedGoogle = true;
                const credential = firebase.auth.GoogleAuthProvider.credential(resp.credential);
                this.afAuth.signInWithCredential(credential).then(resp2 => {
                    try {
                        this.updateUserData(resp2.user);
                        this.router.navigate(['home']);
                    } catch (error) {
                        console.log(error);
                    }
                });
            }),
        });
        google.accounts.id.renderButton(buttonParentDiv, {
            theme: "filled_blue",
        });
        google.accounts.id.prompt();
   }

    logout() {
        this.loggedGoogle = false;
        this.afAuth.signOut().then(() => {
            google.accounts.id.disableAutoSelect();
            this.router.navigate(['login']);
        });
    }

    /**
     * @returns Token for Google APIs
     */
    async authorize(): Promise<string> {
        return new Promise((resolve, reject) => {
            const token = this.getAccessToken();
            if (token != null) {
                resolve(token);
                return;
            }

            const tokenClient = google.accounts.oauth2.initTokenClient({
                client_id: environment.clientId,
                scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/drive',
                prompt: '',
                callback: async resp => {
                    console.log("resp",resp);
                    if (resp.error_description) {
                        alert(`Le Token Google n'a pu être enregistré, désactivez vos bloqueurs de pop-up: ${resp.error_description}`);
                        reject(resp.error_description);
                        return;
                    }
                    const expirationDate = moment().add(resp.expires_in, 'seconds');
                    sessionStorage.setItem(SESSION_DATE_KEY, expirationDate.toISOString());
                    sessionStorage.setItem(SESSION_STORAGE_KEY, resp.access_token);
                    setTimeout(() => sessionStorage.removeItem(SESSION_STORAGE_KEY), resp.expires_in * 1000);
                    resolve(resp.access_token);
                }
            });

            tokenClient.requestAccessToken();
        });
    }

    private updateUserData(user: firebase.User) {
        // Sets user data to firebase on login
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`usersApp/${user.uid}`);
        const data: User = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
        };
        return userRef.set(data, { merge: true });
    }

    private getAccessToken(): string | null {
        const date = new Date(sessionStorage.getItem(SESSION_DATE_KEY));
        if (date < new Date()) {
            return null;
        }
        else {
            return sessionStorage.getItem(SESSION_STORAGE_KEY);
        }
    }
}
