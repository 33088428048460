import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
    selector: 'app-widget-pie',
    templateUrl: './pie.component.html',
    styleUrls: ['./pie.component.css'],
})
export class PieComponent implements OnInit {
    @Input() data: any;
    @Input() title: any;

    Highcharts = Highcharts;
    chartOptions: {};

    constructor() {}

    ngOnInit() {
        this.chartOptions = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
            },
            title: {
                text: this.title,
            },
            credits: {
                enabled: false,
            },
            tooltip: {
                pointFormat: '<b>{point.y}</b> ({point.percentage:.1f}%)',
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false,
                    },
                    showInLegend: false,
                },
            },
            series: [
                {
                    type: 'pie',
                    allowPointSelect: true,
                    colorByPoint: true,
                    keys: ['name', 'y'],
                    data: this.data,
                },
            ],
        };
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 300);
    }
}
