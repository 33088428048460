import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TeamService } from './../../../services/team.service';

@Component({
    selector: 'app-team-list',
    templateUrl: './team-list.component.html',
    styleUrls: ['./team-list.component.css'],
})
export class TeamListComponent implements AfterViewInit {
    public teams: [];
    search: string = null;
    displayedColumns = ['title', 'createdAt', 'type', 'tags'];
    dataSource: MatTableDataSource<any>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    constructor(private teamservice: TeamService, private router: Router) {}

    ngAfterViewInit() {
        this.teamservice.getUserTeams().subscribe(data => {
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.sort = this.sort;
        });
    }

    /**
     * @description Applys filter
     * @param filterValue
     */
    applyFilter(filterValue: string) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    /**
     * @description Reset search input text and remove filter in cache.
     */
    resetSearchFilter() {
        this.search = null;
        this.dataSource.filter = null;
        // this.cache.delete(CacheKey.DASHBOARD_SEARCH_FILTER);
    }

    /**
     * @description The trackByUid method is optional,
     *  but it will prevent the view from re-rendering every row when data changes.
     *  If you have a very large data table, trackBy can provide a significant boost in rendering performance.
     * @param index
     * @param item
     * @returns
     */
    trackByUid(index, item) {
        return item.uid;
    }

    /**
     * @description Details team on row click
     * @param row
     */
    detailTeam(row) {
        this.router.navigate([`myteamsList/${row.key}`]);
    }
}
