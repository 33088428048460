<div mat-dialog-content>
    <button mat-icon-button [mat-dialog-close] class="closeModal">
        <mat-icon>close</mat-icon>
    </button><br>
    <h3 style="display: inline;">{{data.title}}</h3>
    <p *ngIf="data.note" style="overflow-wrap:break-word" [innerHTML]="data?.note |  linkify"></p>
    <p *ngIf="!data.note">{{ 'no description' | translate }}</p>
    <div id="closedAt" style="width: 350px;float: left;height: 100%;line-height: 18px;white-space: nowrap;margin-left:2px;text-align: center;border: solid 1px #dbe0e4;border-radius: 5px;">
            <div>{{ 'Create by' | translate }} : {{data.creator | email | titlecase}}</div>
        </div>
    <div id="closedAt" style="width: 350px;float: left;height: 100%;line-height: 18px;white-space: nowrap;margin-left:2px;text-align: center;border: solid 1px #dbe0e4;border-radius: 5px;">
        <div>{{ 'Create at' | translate }} : {{data.createdAt?.toDate() | date: 'dd MMM yyyy'}}</div>
    </div>
</div>
