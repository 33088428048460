<div style="margin-left: 2%">
  <mat-form-field>
      <input matInput (keyup)="applyFilter($any($event.target).value)" [(ngModel)]="search" autocomplete="off" placeholder="{{'search' | translate}}" />
      <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="resetSearchFilter()">
        <mat-icon>close</mat-icon>
      </button>
  </mat-form-field>
</div>

<mat-table #table [dataSource]="dataSource" [trackBy]="trackByUid" matSort class="animate">


    <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Title' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let team"> {{ team.title }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdAt">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Create at' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let team"> {{ team?.createdAt?.toDate() | date: 'dd/MM/yyyy' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Type </mat-header-cell>
        <mat-cell *matCellDef="let todo">
            <div *ngIf="todo?.type">{{ todo?.type }}</div></mat-cell
        >
    </ng-container>

    <ng-container matColumnDef="tags">
        <mat-header-cell *matHeaderCellDef> Tags </mat-header-cell>
        <mat-cell *matCellDef="let team">
            <div *ngIf="team?.tags">
                <span *ngFor="let tag of team?.tags">
                    {{ tag?.display }}
                </span>
            </div>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row (click)="detailTeam(row)" *matRowDef="let row; columns: displayedColumns" class="animate"></mat-row>
</mat-table>
