import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class TeamService {
    public teams: BehaviorSubject<any> = new BehaviorSubject(null);
    today: Date;

    constructor(private auth: AuthService, public afs: AngularFirestore) {
        this.initTeams();
        this.today = new Date();
    }

    initTeams(): void {
        this.getUserTeams().subscribe(teams => {
            this.teams.next(teams);
        });
    }

    getUserEmail(): Observable<any> {
        return this.auth.user$;
    }

    getUserTeams(): Observable<any> {
        return this.getUserEmail().pipe(
            mergeMap(currentUser => {
                if (currentUser) {
                    return this.getTeams(currentUser.email);
                } else {
                    return of('');
                }
            }),
        );
    }

    getUserTeamsAdmin(): Observable<any> {
        return this.getUserEmail().pipe(
            mergeMap(currentUser => {
                if (currentUser) {
                    return this.getTeamsAdmin(currentUser.email);
                } else {
                    return of('');
                }
            }),
        );
    }

    getUserTeams8D(): Observable<any> {
        return this.getUserEmail().pipe(
            mergeMap(currentUser => {
                if (currentUser) {
                    return this.getTeams8D(currentUser.email);
                } else {
                    return of('');
                }
            }),
        );
    }

    getUserTeamsQVT(): Observable<any> {
        return this.getUserEmail().pipe(
            mergeMap(currentUser => {
                if (currentUser) {
                    return this.getTeamsQVT(currentUser.email);
                } else {
                    return of('');
                }
            }),
        );
    }

    // Function : myTeams = [] Observable
    getTeams(user): Observable<any[]> {
        const teamsCollection = this.afs.collection<any>('teams', ref => ref.where('members', 'array-contains', user));
        return teamsCollection.snapshotChanges().pipe(
            map(teams => {
                const myTeams = [];
                teams.forEach(team => {
                    myTeams.push({ key: team.payload.doc.id, ...team.payload.doc.data() });
                });
                return myTeams;
            }),
        );
    }

    getTeams8D(user): Observable<any[]> {
        const teamsCollection = this.afs.collection<any>('teams', ref =>
            ref.where('members', 'array-contains', user).where('type', '==', '8D'),
        );
        return teamsCollection.snapshotChanges().pipe(
            map(teams => {
                const myTeams = [];
                teams.forEach(team => {
                    myTeams.push({ key: team.payload.doc.id, ...team.payload.doc.data() });
                });
                return myTeams;
            }),
        );
    }

    getTeamsQVT(user): Observable<any[]> {
        const teamsCollection = this.afs.collection<any>('teams', ref =>
            ref.where('members', 'array-contains', user).where('type', '==', 'QVT'),
        );
        return teamsCollection.snapshotChanges().pipe(
            map(teams => {
                const myTeams = [];
                teams.forEach(team => {
                    myTeams.push({ key: team.payload.doc.id, ...team.payload.doc.data() });
                });
                return myTeams;
            }),
        );
    }

    // Function : myTeams = [] Observable
    getTeamsAdmin(user): Observable<any[]> {
        const teamsCollection = this.afs.collection<any>('teams', ref => ref.where('creator', '==', user));
        return teamsCollection.snapshotChanges().pipe(
            map(teams => {
                const myTeams = [];
                teams.forEach(team => {
                    myTeams.push({ key: team.payload.doc.id, ...team.payload.doc.data() });
                });
                return myTeams;
            }),
        );
    }

    getTeamsKpi(team): Observable<any[]> {
        const teamsdata = this.afs.collection('todos', ref => ref.where('teamID', '==', team));
        return teamsdata.valueChanges().pipe(
            map(todos => {
                const myTeamsData = [];
                myTeamsData['isDone'] = 0;
                myTeamsData['open'] = 0;
                myTeamsData['late'] = 0;
                myTeamsData['open2'] = 0;
                todos.forEach(x => {
                    if (x['isDone'] === true) {
                        myTeamsData['isDone'] = myTeamsData['isDone'] + 1;
                    }
                    if (x['isDone'] === false && !x['dateTarget']) {
                        myTeamsData['open2'] = myTeamsData['open2'] + 1;
                    }
                    if (x['dateTarget']) {
                        if (x['dateTarget'].toDate() <= this.today && x['isDone'] === false) {
                            myTeamsData['late'] = myTeamsData['late'] + 1;
                        } else if (x['dateTarget'].toDate() >= this.today && x['isDone'] === false) {
                            myTeamsData['open'] = myTeamsData['open'] + 1;
                        }
                    }
                });
                return myTeamsData;
            }),
        );
    }

    getTeam(key: string): Observable<any> {
        const teamDoc = this.afs.doc(`teams/${key}`);
        return teamDoc.valueChanges();
    }
}
