import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollDispatcher, ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, Injectable } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HighchartsChartModule } from 'highcharts-angular';
import { TagInputModule } from 'ngx-chips';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DisableControlDirective } from './disable.directive';
import { FooterComponent } from './footer/footer.component';
import { AuthGuard } from './guards/auth.guard';
import { TeammemberGuard } from './guards/teammember.guard';
import { HomeDashboardComponent } from './home-dashboard/home-dashboard.component';
import { LoginComponent } from './login/login.component';
import { FireFormSettingsDirective } from './menu/form-settings/fire-form-settings.directive';
import { SettingsComponent } from './menu/form-settings/form-settings.component';
import { MenuComponent, notificationsModalComponent } from './menu/menu.component';
import { EmailPipe } from './pipes/email.pipe';
import { LinkifyjsPipe } from './pipes/linkifyjs.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { SearchTeamPipe } from './pipes/searchteam.pipe';
import { SearchtutoPipe } from './pipes/searchtuto.pipe';
import { AuthService } from './services/auth.service';
import { SidenavService } from './services/sidenav.service';
import { TodoService } from './services/todo.service';
import { CoreModule } from './shared/modules/core.module';
import { LayoutModule } from './shared/modules/layout.module';
import { FormMembersComponent } from './team/form-members/form-members.component';
import { FireFormTeamDirective } from './team/form-team/fire-form-team.directive';
import { FormTeamQvtComponent } from './team/form-team/form-team-qvt.component';
import { FormTeamComponent } from './team/form-team/form-team.component';
import { TeamContainerComponent } from './team/team-container/team-container.component';
import { TeamDetailsComponent } from './team/team-container/team-details/team-details.component';
import { TeamListComponent } from './team/team-container/team-list/team-list.component';
import { BottomTeamChoicesSheetComponent, DeleteTeamModalComponent, TeamComponent } from './team/team.component';
import { FireFormDirective } from './todo/form/fire-form.directive';
import { FormTodoComponent } from './todo/form/form.component';
import {
    DeleteModalComponent,
    DetailForListComponent,
    DrivefilesComponent,
    efficiencyDialog,
    MessagesComponent,
    TodocloseComponent,
    TodoComponent,
} from './todo/todo.component';
import { CardComponent } from './widgets/card/card.component';
import { PieComponent } from './widgets/pie/pie.component';

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
    overrides = <any>{
        'pinch': { enable: false },
        'rotate': { enable: false },
    };
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

@NgModule({
    declarations: [
        AppComponent,
        TodoComponent,
        TodocloseComponent,
        TeamContainerComponent,
        TeamDetailsComponent,
        TeamListComponent,
        MenuComponent,
        LoginComponent,
        FooterComponent,
        FormTodoComponent,
        SettingsComponent,
        FireFormDirective,
        FireFormSettingsDirective,
        SearchPipe,
        EmailPipe,
        TeamComponent,
        BottomTeamChoicesSheetComponent,
        efficiencyDialog,
        MessagesComponent,
        DeleteModalComponent,
        DeleteTeamModalComponent,
        DrivefilesComponent,
        DetailForListComponent,
        FormTeamComponent,
        FormTeamQvtComponent,
        FireFormTeamDirective,
        DisableControlDirective,
        notificationsModalComponent,
        SafePipe,
        SearchtutoPipe,
        SearchTeamPipe,
        FormMembersComponent,
        HomeDashboardComponent,
        PieComponent,
        CardComponent,
        LinkifyjsPipe,
    ],
    imports: [
        BrowserModule,
        // import HttpClientModule after BrowserModule.
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireDatabaseModule,
        AngularFirestoreModule,
        AngularFireAuthModule,
        LayoutModule,
        CoreModule,
        ReactiveFormsModule,
        FormsModule,
        TagInputModule,
        ScrollingModule,
        DragDropModule,
        ExperimentalScrollingModule,
        HighchartsChartModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ],
    providers: [
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: CustomHammerConfig,
        },
        AuthGuard,
        TeammemberGuard,
        AuthService,
        TodoService,
        SidenavService,
        ScrollDispatcher,
        { provide: MAT_DATE_LOCALE, useValue: 'fr' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
