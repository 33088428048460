import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface User {
  name: string;
}

@Component({
  selector: 'app-form-settings',
  templateUrl: './form-settings.component.html',
  styleUrls: ['./form-settings.component.scss']
})

export class SettingsComponent implements OnInit {

  myForm: FormGroup;
  state: string;
  uid;
  displayName;
  email;
  photoURL;
  language;

   constructor(public dialogRef: MatDialogRef<string>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder) {
      // console.log(data);
      this.uid = data.userData.uid;
      this.displayName = data.userData.displayName;
      this.email = data.userData.email;
      this.photoURL = data.userData.photoURL;
      this.language = data.userData.language;
  }

  ngOnInit() {
    // on récupère les options, pour les afficher dans les choix (liste déroulante)
    this.myForm = this.fb.group({
      viewMode: [''],
      language: [''],
    });
  }

  changeHandler(e) {
    this.state = e;
  }

}
