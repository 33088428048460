import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'searchtuto', pure: false })

@Injectable()
export class SearchtutoPipe implements PipeTransform {
    transform(array: any[], query: string): any {
        if (query) {
            query = query.toLowerCase();
            return array.filter((value: any, index: number, arr: any) => {
                return (value.description.toLowerCase().indexOf(query) > -1) || (value.title.toLowerCase().indexOf(query) > -1);
            });
        }
        return array;
    }
}
