import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthService } from './services/auth.service';
import { LoadingService } from './services/loading.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'Wedo App';
    private subscriptions: Subscription[] = [];

    constructor(
        private auth: AuthService,
        private translate: TranslateService,
        private loading: LoadingService,
    ) {
        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    }

    ngOnInit() {
        console.log('init AppComponent');
        this.loading.isLoading$.next(true);
        this.subscriptions.push(this.auth.user$.subscribe(user => {
            if (user) {
                if (user.language) {
                    this.translate.use(user.language);
                }
            }
        }));
    }

    public ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
