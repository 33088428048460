import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root',
})
export class SnackbarService {
    /**
     * Creates an instance of snackbar service.
     * @param _snackBar material snackbar component
     */
    constructor(private _snackBar: MatSnackBar) {}

    /**
     * Notify message with information level.
     * @param message message to notify
     */
    showInformationMessage(message: string) {
        this._snackBar.open(message, 'x', {
            duration: 5000,
            panelClass: 'snack-bar-success',
        });
    }

    /**
     * Notify message with warning level.
     * @param message message to notify
     */
    showWarningMessage(message: string) {
        this._snackBar.open(message, 'x', {
            panelClass: 'snack-bar-warning',
        });
    }

    /**
     * Notify message with error level.
     * @param message message to notify
     */
    showErrorMessage(message: string) {
        this._snackBar.open(message, 'x', {
            panelClass: 'snack-bar-error',
        });
    }
}
