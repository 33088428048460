<div class="text">
  <h4>{{ label }}</h4>

  <span class="total">{{ total }}</span>

  <span>
      <mat-icon color="primary">trending_up</mat-icon>
  </span>

  <span class="description">
      {{ percentage }}%
  </span>

  <span> of target</span>
</div>
<div class="widget">
  <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"
      style="width: 100%; height: 60px; display: block;">
  </highcharts-chart>
</div>
