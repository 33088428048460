<div *ngIf="auth.user$ | async as user">
    <div id="todoIndicator" style="border: solid 0px; z-index: 0;">
        <div style="width: 126.5px;height: 36px;"></div>
    </div>
    <mat-form-field appearance="outline" class="searchBar">
        <mat-label>{{ 'search' | translate }}</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="{{ 'search_placeholder' | translate }}" [(ngModel)]="search" autocomplete="off" />
        <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="search = ''">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <div style="position: fixed;top: 70px;height: 29px;width: 100%;">
        <mat-progress-bar mode="indeterminate" *ngIf="!(myteams$ | async)"></mat-progress-bar>
    </div>
    <cdk-virtual-scroll-viewport itemSize="300px" class="centered">
        <div *ngIf="(myteams$ | async)?.length == 0" style="text-align: center;">
            <h1>{{ 'No Team' | translate }}</h1>
        </div>
        <div class="cards">
            <!-- <div *ngIf="todoAll.length === 0">No results, try clearing filters</div> -->
            <div class="card" *ngFor="let team of myteams$ | async | SearchTeamPipe: search">
                <div class="card-header">
                    <img [src]="logoqvt" height="25px" *ngIf="team?.type === 'QVT'" style="float:left" />
                    <img [src]="logo8d" height="25px" *ngIf="team?.type === '8D'" style="float:left" />
                    <div class="card-title">{{ team?.title }}</div>
                </div>

                <span matBadge="{{ team?.todosCount }}" matBadgeColor="primary">
                    <button
                        mat-stroked-button
                        style="color:#006837; margin: 1%;"
                        [routerLink]="['/todo', team?.key]"
                        [queryParams]="{ name: team?.title }"
                        matTooltip="{{ 'click to go team' | translate }}"
                    >
                        {{ 'teams todos' | translate }}
                    </button>
                </span>
                <div class="card-content">
                    <!-- {{team | json}} -->
                    <mat-accordion>
                        <mat-expansion-panel style="margin: 5px;">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>account_circle</mat-icon>
                                </mat-panel-title>
                                <mat-panel-description>
                                    {{ 'Members' | translate }} ({{ team.members.length }})
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <mat-list-item *ngFor="let member of team.members; let i = index">
                                <mat-card id="team-members">
                                    <p
                                        style="text-align: left"
                                        [ngClass]="{ 'team-creator': isCreatorForDelete(member, team?.creator) }"
                                    >
                                        <span>{{ member | email | titlecase }}</span>
                                    </p>
                                </mat-card>
                            </mat-list-item>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion>
                        <mat-expansion-panel
                            style="margin: 5px;"
                        >
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>more_horiz</mat-icon>
                                </mat-panel-title>
                                <mat-panel-description> </mat-panel-description>
                            </mat-expansion-panel-header>
                            <p *ngIf="team?.note">Note : {{ team?.note }}</p>
                            <p *ngIf="team?.creator">{{ 'Create by' | translate }} : {{ team?.creator | email | titlecase}}</p>
                            <p *ngIf="team?.createdAt">
                                {{ 'Create at' | translate }} : {{ team?.createdAt?.toDate() | date: 'dd/MM/yyyy' }}
                            </p>
                            <!-- <p>teamID : {{team?.key}}</p> -->
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <!-- <mat-chip-list *ngFor="let tag of team?.tags; let i = index">
                    <mat-chip class="card-tags">{{ tag.value }}</mat-chip>
                </mat-chip-list> -->
                <div>
                    <div>
                        <tag-input
                            [ngModel]="team?.tags"
                            [editable]="true"
                            [addOnBlur]="true"
                            [theme]="'minimal'"
                            [clearOnBlur]="true"
                            [onAdding]="transform"
                            (onRemove)="removeTag($event, team?.key)"
                            (onAdd)="addTag($event, team?.key)"
                            (onSelect)="filterTag($event)"
                        >
                            <tag-input-dropdown [focusFirstElement]="true" [autocompleteItems]="autocompleteItems">
                            </tag-input-dropdown>
                        </tag-input>
                    </div>
                </div>
                <br />
                <mat-chip-list>
                    <!-- <mat-chip color="primary" selected *ngIf="team?.type">Type : {{team?.type}}</mat-chip> -->
                    <mat-chip *ngIf="team?.teamQVT">{{ 'Team QVT' | translate }} : {{ team?.teamQVT }}</mat-chip>
                </mat-chip-list>
                <br />
                <div id="card-buttons" style="float:right">
                    <button
                        mat-icon-button
                        matTooltip="{{ 'Update' | translate }}"
                        (click)="openForm(team?.key, team?.type)"
                    >
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        matTooltip="{{ 'memberAdd' | translate }}"
                        (click)="openFormMembers(team?.key)"
                    >
                        <mat-icon>group_add</mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        matTooltip="{{ 'Delete' | translate }}"
                        [disabled]="!isOwnerUserConnected(team?.creator) || team?.todosCount > 0"
                        (click)="DeleteModal(team?.key)"
                    >
                        <mat-icon>delete_forever</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </cdk-virtual-scroll-viewport>
</div>

<a
    mat-fab
    class="addtodo-button"
    matTooltip="{{ 'Add Team' | translate }}"
    matTooltipPosition="above"
    (click)="openBottomSheet()"
>
    <mat-icon>add</mat-icon>
</a>
