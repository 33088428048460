<div>
  <button mat-icon-button [mat-dialog-close] class="closeModal">
    <mat-icon>close</mat-icon>
  </button>
<h1 mat-dialog-title>Efficacité de l'action ?</h1>
<div mat-dialog-content>
    <mat-form-field class="form-full-width">
      <mat-select #efficiencyInput  placeholder="Efficiency" [value]="efficiency">
        <mat-option  value ="Yes">Yes</mat-option>
        <mat-option  value ="No">No</mat-option>
      </mat-select>
    </mat-form-field>
</div>
<mat-form-field class="form-full-width">
  <textarea  matInput #efficiencyCommentInput placeholder="Efficiency comment">{{efficiencyComment }}</textarea>
</mat-form-field>
<span class="formStatus" *ngIf="efficiencyUpdateDate">Efficiency last update: {{efficiencyUpdateDate?.toDate() | date: 'dd MMM yyyy'}} by: {{owner}}</span>
<div style="text-align: right;">
  <button mat-flat-button class="valid-button" (click)="SaveEfficiency(efficiencyInput.value, efficiencyCommentInput.value)" cdkFocusInitial [disabled]="!efficiencyInput.value || !efficiencyCommentInput.value">{{ 'button_save' | translate }}</button>
</div>
</div>
