<div>
  <button mat-icon-button [mat-dialog-close] class="closeModal">
    <mat-icon>close</mat-icon>
  </button>
  <br>
  <br>
  <form [formGroup]="myForm" appFireFormTeam path='teams/{{(docID)}}' (stateChange)="changeHandler($event)" [dialogRef]="dialogRef">
    <!-- <div class="columns">
      <div class="column">
        <span>Reactive Form Data</span><br> {{ myForm.value | json }} {{myForm.status}}
      </div>
      <div class="column">
        <h3>Firestore Data</h3><br> {{ myDoc | async | json }}
      </div>
    </div> -->

    <mat-form-field class="m-right">
      <input matInput placeholder="{{ 'Title' | translate }}" formControlName="title">
    </mat-form-field>

    <!-- <mat-form-field class="m-right">
      <input matInput placeholder="members" formControlName="members">
    </mat-form-field> -->

    <mat-form-field class="m-right">
      <textarea matInput placeholder="Note" formControlName="note"></textarea>
    </mat-form-field>

    <tag-input matInput formControlName="tags" placeholder="+ Tag" secondaryPlaceholder="+ Tag" [addOnBlur]="true"
      [clearOnBlur]="true" [theme]="'dark'" [onAdding]="transform">
      <tag-input-dropdown [focusFirstElement]="true" [autocompleteItems]="autocompleteItems">
      </tag-input-dropdown>
    </tag-input>
    <br>
    <div style="text-align: right;">
      <button mat-flat-button class="valid-button" [disabled]="myForm.invalid || state !== 'modified'">{{ 'button_save' | translate }}</button>
    </div>
  </form>
  <br>
  <div class="formStatus">
    <div>
      <span *ngIf="state === 'modified'">{{ 'Data not saved' | translate }}</span>
      <!-- <span *ngIf="state === 'synced'">Dernière mise à jour : {{TeamupdatedAt |date:'medium'}}</span> -->
      <span *ngIf="state === 'synced'">{{ 'Data saved' | translate }}</span>
      <span *ngIf="state === 'error'">{{ 'Data error' | translate }}</span>
    </div>
  </div>
</div>