import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { Observable, of as observableOf, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TodoService, User } from '../../services/todo.service';


@Component({
    selector: 'app-form-members',
    templateUrl: './form-members.component.html',
    styleUrls: ['./form-members.component.css'],
})
export class FormMembersComponent implements OnInit, OnDestroy {
    docID;
    teamNameSub: Subscription;
    private options: User[] = [];
    filteredOptions: Observable<User[]>;
    formGroupAddMember: FormGroup;

    constructor(
        public readonly dialogRef: MatDialogRef<FormMembersComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: any,
        private readonly todoservice: TodoService,
        private readonly fb: FormBuilder,
        private readonly afs: AngularFirestore,
        public readonly snackBar: MatSnackBar,
    ) {
        this.docID = data.teamID;
    }

    teamDoc: AngularFirestoreDocument<any>;
    team$: Observable<any>;

    ngOnInit() {
        this.teamDoc = this.afs.doc(`teams/${this.docID}`);
        this.team$ = this.teamDoc.valueChanges();
        this.formGroupAddMember = this.fb.group({
            member: new FormControl(''),
        });
        this.filteredOptions = this.formGroupAddMember.controls.member.valueChanges.pipe(
            startWith<string | User>(''),
            map(value => (typeof value === 'string' ? value : value.name)),
            map(name => (name ? this._filter(name) : this.options.slice())),
        );
    }

    openSnackBar(message, action) {
        this.snackBar.open(message, action, { duration: 5000 });
    }

    ngOnDestroy(): void {
        if (this.teamNameSub) {
            this.teamNameSub.unsubscribe();
        }
    }

    private _filter(name: string): User[] {
        const filterValue = name.toLowerCase();
        return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    onSelectionChanged(event: MatAutocompleteSelectedEvent, membersArray) {
        if (_.includes(membersArray, event.option.value)) {
            this.openSnackBar('Déjà présent dans cette Team', 'Info');
            this.formGroupAddMember.patchValue({ 'member': '' });
        } else {
            membersArray.push(event.option.value);
            const ma = _.uniq(membersArray);
            this.formGroupAddMember.patchValue({ 'member': '' });
            this.update(ma);
            this.openSnackBar('Membre ajouté', 'Info');
        }
    }

    getUsers() {
        this.options = this.todoservice.getUsersSoitec();
    }

    // TAGS add
    public transform(value): Observable<object> {
        if (value.value) {
            const item = { display: `#${value.value}`, value: `#${value.value}` };
            return observableOf(item);
        } else {
            const item = { display: `#${value}`, value: `#${value}` };
            return observableOf(item);
        }
    }

    async update(membersArray) {
        try {
            const docRef = this.afs.doc(`teams/${this.docID}`);
            await docRef.update({ members: membersArray });
        } catch (err) {
            this.openSnackBar('Attention il y a eu une erreur', 'Info');
        }
    }

    delMember(index, membersArray) {
        try {
            membersArray.splice(index, 1);
            this.update(membersArray);
            this.openSnackBar('Membre supprimé', 'Info');
        } catch (err) {
            this.openSnackBar('Attention il y a eu une erreur', 'Info');
        }
    }

    isCreatorForDelete(member, creator, type): boolean {
        if (type === 'QVT' && member === 'valerie.hillairet@soitec.com') {
            return true;
        } else if (member === creator) {
            return true;
        } else {
            return false;
        }
    }
}
