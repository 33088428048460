import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { FirestoreService } from '../services/firestore.service';
import { SidenavService } from '../services/sidenav.service';
import { TodoService } from '../services/todo.service';
import { SnackbarService } from './../services/snackbar.service';
import { SettingsComponent } from './form-settings/form-settings.component';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit, OnDestroy {
    dataUser: any;
    home;
    countOpenTodos: number;
    countOpenWithoutTargetTodos: number;
    countClosedTodos: number;
    countLateTodos: number;
    logowedo: string;
    myteams = false;
    path = '';
    dataUserNotifsLength: any;
    dataUserNotifs: any;
    private subscriptions: Subscription[] = [];

    constructor(
        public auth: AuthService,
        private dialog: MatDialog,
        public todoservice: TodoService,
        private sidenav: SidenavService,
        private router: Router,
        private location: Location,
    ) {
        this.logowedo = './assets/images/logowedo.png';
        router.events.subscribe(val => {
            if (location.path() === '/mytodos') {
                this.home = true;
            } else {
                this.home = false;
            }
        });
    }

    ngOnInit() {
        console.log('init MenuComponent');
        this.subscriptions.push(this.auth.user$.subscribe(user => {
            this.dataUser = user;
        }));

        this.subscriptions.push(this.auth.userNotifs$.subscribe(userNotifs => {
            this.dataUserNotifs = userNotifs;
            this.dataUserNotifsLength = this.dataUserNotifs ? this.dataUserNotifs.length : 0;
        }));
        // Je récupère le count des todo fermés
        this.subscriptions.push(this.todoservice.getUserClosedTodos().subscribe(
            x => (this.countClosedTodos = x),
            error => console.log('Observer a reçu une erreur : ' + error),
        ));
        // Je récupère le count des todo ouvertes
        this.subscriptions.push(this.todoservice.getUserOpenTodosAll().subscribe(
            x => (this.countOpenTodos = x),
            error => console.log('Observer a reçu une erreur : ' + error),
        ));
        // Je récupère le count des todo ouvertes
        this.subscriptions.push(this.todoservice.getUserLateTodos().subscribe(
            x => (this.countLateTodos = x),
            error => console.log('Observer a reçu une erreur : ' + error),
        ));
        // Je récupère le count des todo ouvertes sans dates target
        this.subscriptions.push(this.todoservice.getUserOpenWithoutTargetTodos().subscribe(
            x => (this.countOpenWithoutTargetTodos = x),
            error => console.log('Observer a reçu une erreur : ' + error),
        ));
    }

    ngOnDestroy(): void {
        // avoid memory leaks here by cleaning up after ourselves. If we
        // don't then we will continue to run our initialiseInvites()
        // method on every navigationEnd event.
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    editForm(): void {
        const dialogRef = this.dialog.open(SettingsComponent, {
            width: '80%',
            autoFocus: false,
            data: { userData: this.dataUser },
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

    openDialogNotifications(): void {
        const dialogRef = this.dialog.open(notificationsModalComponent, {
            width: '75%',
        });
    }

    setTeamsMenu(val: boolean, path: string): void {
        this.path = path;
        this.myteams = val;
        this.sidenav.open();
    }

    goToHome() {
        this.router.navigate(['home']);
        this.myteams = false;
    }
}


// COMPOSANT NOTIFICATIONS  MODAL
@Component({
    selector: 'app-notifications-dialog',
    templateUrl: 'app-notifications-dialog.html',
})
export class notificationsModalComponent implements OnDestroy {
    uid: string;
    data$: Observable<any>;
    snacktext: string;
    private subscriptions: Subscription[] = [];
    constructor(
        public dialogRef: MatDialogRef<notificationsModalComponent>,
        public afs: AngularFirestore,
        private fs: FirestoreService,
        private auth: AuthService,
        public snackbar: SnackbarService,
        public translate: TranslateService,
        private router: Router,
    ) {
        this.subscriptions.push(this.auth.user$.subscribe(user => {
            this.uid = user.uid;
            console.log('uid', this.uid);
        }));

        this.data$ = this.auth.userNotifs$;
        this.subscriptions.push(translate.get('notif deleted').subscribe((text: string) => {
            this.snacktext = text;
        }));
    }

    ngOnDestroy(): void {
        // avoid memory leaks here by cleaning up after ourselves. If we
        // don't then we will continue to run our initialiseInvites()
        // method on every navigationEnd event.
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }


    onNoClick(): void {
        this.dialogRef.close();
    }

    linkToTodo(id): void {
        this.router.navigate(['/'])
        .then(x => {
            this.router.navigate([`todo/single/${id}`])
        })
        .then(x => {this.dialogRef.close();});
    }

    delNotif(id): void {
        const notifRef = this.afs
            .collection('usersApp')
            .doc(this.uid)
            .collection('notifications')
            .doc(id);
        this.fs.delete(notifRef).then(res => {
            this.snackbar.showInformationMessage(this.snacktext);
        });
    }
}
