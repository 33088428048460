import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { TodoService } from 'src/app/services/todo.service';
import { AuthService } from '../services/auth.service';

@Injectable()

export class TeammemberGuard implements CanActivate {
  key;
  myteams;
  myteamsSub: Subscription;

  constructor(private auth: AuthService, private todoservice: TodoService, private router: Router) {
  }

  isTeamMember(teams, teamID): boolean {
    return _.some(teams, ['key', teamID]);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.todoservice.getUserMyTeams().pipe(
      map (teams => {
        if (!this.isTeamMember(teams, route.params['key'])) {
          console.log('accès non autorisé');
          this.router.navigate(['myteams']);
          return false;
        }
        // console.log(teams);
        console.log('accès autorisé');
        return true;
      }));
  }


}
