<div>
  <button mat-icon-button [mat-dialog-close] class="closeModal">
    <mat-icon>close</mat-icon>
  </button>
  <img class="logoDrive" [src]="logoDrive">
  <h2 style="display: inline-block; margin-left: 3px;font-weight: 100;">{{'Drive links' | translate }}</h2>
  <br>
  <div mat-dialog-content cdkFocusInitial>
    <div>
      <mat-list *ngFor="let drivefile of driveFile">
        <mat-list-item>
          <a href="{{ drivefile?.url }}" target="_blank" matTooltip="{{'Click to open' | translate}}"
            matTooltipPosition="above">{{ drivefile?.name }}</a>
          <button mat-icon-button matTooltip="{{'Remove the Drive link' | translate}}" matTooltipPosition="above"
            (click)="delDriveFile(driveFile.indexOf(drivefile))">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>
    </div>
  </div>
  <div mat-dialog-actions style='text-align: right; display: block !important'>
    <button mat-mini-fab color="black" style="margin-right: 2%;" matTooltip="{{'Add a Drive link' | translate}}"
      matTooltipPosition="above" (click)=" onApiLoad()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <p *ngIf="driveFile?.length == 0">{{'No drive file' | translate }}</p>
</div>
