import { animate, keyframes, transition, trigger } from '@angular/animations';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, Observable, of as observableOf, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { FormTodoComponent } from '../todo/form/form.component';
import { environment } from './../../environments/environment';
import { FirestoreService } from './../services/firestore.service';
import { TodoService } from './../services/todo.service';
import * as kf from './keyframes';
export interface List {
    value: string;
    viewValue: string;
}
declare const google: any;
declare const gapi: any;
@Component({
    selector: 'app-todo',
    templateUrl: './todo.component.html',
    styleUrls: ['./todo.component.css'],
    animations: [
        trigger('cardAnimator', [
            transition('* => wobble', animate(1000, keyframes(kf.wobble))),
            transition('* => swing', animate(1000, keyframes(kf.swing))),
            transition('* => jello', animate(1000, keyframes(kf.jello))),
            transition('* => zoomOutRight', animate(1000, keyframes(kf.zoomOutRight))),
            transition('* => slideOutLeft', animate(1000, keyframes(kf.slideOutLeft))),
            transition('* => rotateOutUpRight', animate(1000, keyframes(kf.rotateOutUpRight))),
            transition('* => flipOutY', animate(1000, keyframes(kf.flipOutY))),
        ]),
    ],
})
export class TodoComponent implements OnInit, OnDestroy {
    todoCollection: AngularFirestoreCollection<any>;
    todoRef: AngularFirestoreDocument<any>;
    todoAll$: Observable<any>;
    statusFilter$: BehaviorSubject<boolean | null>;
    delayFilter$: BehaviorSubject<boolean | null>;
    periodStart$: BehaviorSubject<Date | null>;
    periodEnd$: BehaviorSubject<Date | null>;
    filter;
    latefilter;
    teamID;
    todoID;
    // search pipe
    search: string = null;
    editImage: string;
    today = Date.now();
    filterdelay = false;
    key;
    teamTodoName;
    myteams;
    // Permet de récupérer l'Id du team dans on clique sur le champ team du todo
    // param utilisé lors du Add todo pour preloder la team
    teamTodoId;
    myViewPreference;
    fromControl = new FormControl();
    toControl = new FormControl();
    periodChecked = false;
    showPeriod = true;
    currentEmail;
    autocompleteItems = ['P1'];
    animationState: string;
    swipeleft;
    swiperight;
    currentidx;
    shared;
    todoAllWitoutShared$: Observable<any>;
    logoqvt: string;
    logo8d: string;
    logoDrive: string;
    Eff8DChoices: List[] = [
        { value: 'yes', viewValue: 'Yes' },
        { value: 'no', viewValue: 'No' },
        { value: 'N/A', viewValue: 'N/A' },
    ];
    id: any;
    private subscriptions: Subscription[] = [];

    constructor(
        public auth: AuthService,
        private firestoreService: FirestoreService,
        private afs: AngularFirestore,
        public todoservice: TodoService,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        public translate: TranslateService,
        private snackBar: MatSnackBar,
        private swupdate: SwUpdate,
    ) {
        this.logoqvt = './assets/images/logoqvt.png';
        this.logo8d = './assets/images/logo8d.png';
        this.logoDrive = './assets/images/drive.png';
        this.key = this.route.snapshot.params['key'];
        this.id = this.route.snapshot.params['id'];
        this.editImage = './assets/images/edit.png';
        this.statusFilter$ = new BehaviorSubject(false);
        this.delayFilter$ = new BehaviorSubject(null);
        this.periodStart$ = new BehaviorSubject(null);
        this.periodEnd$ = new BehaviorSubject(null);
        this.filter = false;
        this.latefilter = false;
        this.teamTodoName = '';
        this.teamTodoId = '';
        this.teamID = '';
        this.subscriptions.push(
            this.router.events.subscribe(
            (e: any) => {
                // If it is a NavigationEnd event re-initalise the component
                if (e instanceof NavigationEnd) {
                    this.initialiseInvites();
                }
            },
            error => console.log('Observer a reçu une erreur : ' + error),
        ));

        this.subscriptions.push(this.swupdate.available.subscribe(event => {
            if (event) {
                this.swupdate.activateUpdate().then(() => window.location.reload());
            }
        }));

        try {
            this.swupdate.checkForUpdate();
        }
        catch(e) {
            // No service worker with ng serve
            console.log(e);
        }
    }

    ngOnInit() {
        console.log('init todoComponent');
        this.currentEmail = this.auth.currentEmail;
        // console.log(this.currentEmail);
        // Subscription : myViewPreference
        this.subscriptions.push(this.auth.user$
            .pipe(
                map(settings => {
                    return settings;
                }),
            )
            .subscribe(
                settings => {
                    if (settings) {
                        this.myViewPreference = settings.viewMode;
                    }
                },
                error => console.log('Observer a reçu une erreur : ' + error),
            ));

        this.subscriptions.push(this.todoservice.getUserTeams().subscribe(
            myteams => (this.myteams = myteams),
            error => console.log('Observer a reçu une erreur : ' + error),
        ));

        // Subscription : mytags
        this.subscriptions.push(this.todoservice.getUserTags().subscribe(
            mytags => {
                this.autocompleteItems = mytags;
                // console.log(mytags);
            },
            error => console.log('Observer a reçu une erreur : ' + error),
        ));

        // je teste si key, alors ça veut dire que la requête vient de Team
        if (this.key) {
            this.route.queryParams.subscribe(params => {
                // pour affichage du team Name sur une liste todos regroupés par team
                console.log(params);
                this.teamTodoName = params.name;
                this.teamTodoId = this.key;
            });
            this.todoAll$ = this.todoservice.getTodoByTeam(
                this.key,
                this.statusFilter$,
                this.delayFilter$,
                this.periodStart$,
                this.periodEnd$,
            );
        } 
        else if (this.id) {
            console.log(this.id)
            this.todoAll$ = this.todoservice.getTodo(this.id);
        }
        else {
            this.todoAll$ = this.todoservice.getTodoAll(
                this.statusFilter$,
                this.delayFilter$,
                this.periodStart$,
                this.periodEnd$,
            );
            this.teamTodoName = '';
            this.teamTodoId = '';
        }

        this.todoAllWitoutShared$ = this.todoAll$.pipe(
            map(todos => todos.filter(todo => todo.teamID === this.teamTodoId).length),
        );
    }

    ngOnDestroy() {
        // avoid memory leaks here by cleaning up after ourselves. If we
        // don't then we will continue to run our initialiseInvites()
        // method on every navigationEnd event.
            this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    trackTodo(todo) {
        return todo.key;
    }

    openSnackBar(message, action) {
        this.snackBar.open(message, action, { duration: 5000 });
    }

    startAnimation(state) {
        console.log(state);
        if (!this.animationState) {
            this.animationState = state;
        }
    }

    resetAnimationState() {
        this.animationState = '';
    }

    hidePeriod() {
        this.showPeriod = !this.showPeriod;
    }

    findPeriod() {
        if (this.fromControl.value) {
            this.periodStart$.next(this.fromControl.value._d);
        }
        if (this.toControl.value) {
            const utc = moment.utc(this.toControl.value._d).add(1, 'days');
            this.periodEnd$.next(utc.toDate());
        }
    }

    periodClicked() {
        this.fromControl.reset();
        this.toControl.reset();
        this.periodStart$.next(null);
        this.periodEnd$.next(null);
    }

    isTeamMember(teamID): boolean {
        return _.some(this.myteams, ['key', teamID]);
    }

    isShared(teamID, teamsShared): boolean {
        if (this.teamTodoId === teamID) {
            return false;
        } else if (teamsShared) {
            if (teamsShared.length > 0 && this.teamTodoName !== '') {
                return true;
            }
        } else {
            return false;
        }
    }

    initialiseInvites() {
        // Set default values and re-fetch any data you need.
        this.ngOnInit();
    }

    isOwnerUserConnected(owner): boolean {
        return this.currentEmail === owner;
    }

    getTeamTodo(teamID, teamName) {
        this.teamTodoName = teamName;
        this.teamTodoId = teamID;
        this.todoAll$ = this.todoservice.getTodoByTeam(
            teamID,
            this.statusFilter$,
            this.delayFilter$,
            this.periodStart$,
            this.periodEnd$,
        );
        this.todoAllWitoutShared$ = this.todoAll$.pipe(
            map(todos => todos.filter(todo => todo.teamID === this.teamTodoId).length),
        );
    }

    addForm(key, name): void {
        const dialogRef = this.dialog.open(FormTodoComponent, {
            height: '90%',
            width: '80%',
            autoFocus: false,
            data: { teamid: key, teamname: name },
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

    // TAGS add
    public transform(value): Observable<object> {
        console.log(value);
        if (value.value) {
            const item = { display: `#${value.value}`, value: `#${value.value}` };
            return observableOf(item);
        } else if (value) {
            const item = { display: `#${value}`, value: `#${value}` };
            return observableOf(item);
        } else {
            return;
        }
    }

    removeTag(event, key) {
        // console.log(event);
        const db = firebase.firestore();
        const todRef = db.collection('todos').doc(key);
        todRef
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    const tagsModified = doc.data().tags.filter(t => t.value !== event.value);
                    // console.log(tagsModified);
                    db.runTransaction(async transaction => {
                        // This code may get re-run multiple times if there are conflicts.
                        transaction.update(todRef, { tags: tagsModified });
                    })
                        .then(function() {
                            // console.log('Transaction successfully committed!');
                        })
                        .catch(function(error) {
                            // console.log('Transaction failed: ', error);
                        });
                } else {
                    // doc.data() will be undefined in this case
                    // console.log('No such document!');
                }
            })
            .catch(function(error) {
                console.log('Error getting document:', error);
            });
    }

    addTag(event, key) {
        const db = firebase.firestore();
        const todRef = db.collection('todos').doc(key);
        db.runTransaction(async transaction => {
            // This code may get re-run multiple times if there are conflicts.
            const doc = await transaction.get(todRef);
            // console.log('doc', doc);
            if (!doc.data().tags) {
                transaction.update(todRef, {
                    tags: [event],
                });
            } else {
                // console.log('else tag');
                const tags = doc.data().tags;
                tags.push(event);
                transaction.update(todRef, { tags: tags });
            }
        })
            .then(function() {
                console.log('Transaction successfully committed!');
            })
            .catch(function(error) {
                console.log('Transaction failed: ', error);
            });
    }

    editForm(teamID, todoID): void {
        const dialogRef = this.dialog.open(FormTodoComponent, {
            height: '90%',
            width: '80%',
            autoFocus: false,
            data: { teamid: teamID, todoid: todoID },
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

    editSwipeForm(idx, key, teamID, isdone): void {
        this.currentidx = idx;
        if ((this.teamTodoName === '' || this.teamTodoId === teamID) && isdone == false) {
            this.swiperight = true;
            setTimeout(() => {
                const dialogRef = this.dialog.open(FormTodoComponent, {
                    height: '90%',
                    width: '80%',
                    autoFocus: false,
                    data: { teamid: teamID, todoid: key },
                });
                dialogRef.afterClosed().subscribe(result => {
                    console.log('The dialog was closed');
                    this.swiperight = false;
                });
            }, 200);
        }
    }

    openDialogMessages(key, owner, title): void {
        const dialogRef = this.dialog.open(MessagesComponent, {
            height: '99%',
            width: '80%',
            data: { fsid: key, owner: owner, title: title },
        });
        dialogRef.afterClosed().subscribe(result => {
            // console.log('The dialog was closed');
        });
    }

    openDialogCloseReopen(key, isdone): void {
        const dialogRef = this.dialog.open(TodocloseComponent, {
            height: '99%',
            width: '80%',
            data: { fsid: key, isdone: isdone },
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result`);
            // ici message
        });
    }

    openEfficiency(todo) {
        const dialogRef = this.dialog.open(efficiencyDialog, {
            height: '40%',
            width: '50%',
            data: { todo },
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result`);
        });
    }

    openSwipeDialogCloseReopen(idx, key, isdone, teamID): void {
        this.currentidx = idx;
        if ((this.teamTodoName === '' || this.teamTodoId === teamID) && isdone == false) {
            this.swipeleft = true;
            setTimeout(() => {
                const dialogRef = this.dialog.open(TodocloseComponent, {
                    height: '99%',
                    width: '80%',
                    data: { fsid: key, isdone: isdone },
                });
                dialogRef.afterClosed().subscribe(result => {
                    console.log(`Dialog result`);
                    this.swipeleft = false;
                    // ici message
                });
            }, 200);
        }
    }

    openDrivePicker(key): void {
        window.scrollTo(0, 0);
        setTimeout(() => {
            this.openDialogFD(key);
        }, 500);
    }

    openDialogFD(key): void {
        const dialogRef = this.dialog.open(DrivefilesComponent, {
            width: '600px',
            data: { fsid: key },
        });

        dialogRef.afterClosed().subscribe(result => {
            // console.log('The dialog was closed');
        });
    }

    delay(dateTarget, isDone): boolean {
        const dateTodo = dateTarget.seconds;
        const dateToday = moment(this.today).format('X');
        return dateToday > dateTodo && !isDone;
    }

    DeleteModal(key): void {
        const dialogRef = this.dialog.open(DeleteModalComponent);
        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                const todotodelete = this.afs.collection('todos').doc(key);
                const path = `todos/${key}/messages`;
                this.firestoreService.deleteCollection(path, 15);
                todotodelete.delete();
            }
        });
    }

    filterIsDone(isDone: boolean | null) {
        this.filter = isDone;
        this.latefilter = false;
        this.statusFilter$.next(isDone);
        this.periodStart$.next(null);
        this.periodEnd$.next(null);
        this.fromControl.reset();
        this.toControl.reset();
        this.periodChecked = false;
        this.delayFilter$.next(null);
    }

    filterDelay() {
        this.latefilter = true;
        this.statusFilter$.next(null);
        this.delayFilter$.next(this.filterdelay);
    }

    filterTag(tag) {
        this.search = tag;
    }

    clickList(title, note, createdAt, creator): void {
        const dialogRef = this.dialog.open(DetailForListComponent, {
            width: '60%',
            data: { title: title, note: note, createdAt: createdAt, creator: creator },
            autoFocus: false,
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }
}

// COMPOSANT DETAILS for LIST
@Component({
    selector: 'app-detailforlist',
    templateUrl: 'detailforlist.html',
})
export class DetailForListComponent {
    constructor(public dialogRef: MatDialogRef<DetailForListComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

    onNoClick(): void {
        this.dialogRef.close();
    }
}

// COMPOSANT SHOW MESSAGES

@Component({
    selector: 'app-messages',
    templateUrl: 'messages.html',
})
export class MessagesComponent implements OnDestroy {
    logoMessages: string;
    todomessagesCollection: AngularFirestoreCollection<any>;
    usersAppCollection: AngularFirestoreCollection<any>;
    todomessages$: Observable<any>;
    usersAppSub: Subscription;
    values = '';
    formMessage: FormGroup;
    owner;
    uid;
    title;

    constructor(
        public dialogRef: MatDialogRef<MessagesComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private afs: AngularFirestore,
        private fb: FormBuilder,
        private snackBar: MatSnackBar,
    ) {
        this.logoMessages = './assets/images/messaging.png';
        // console.log(data.fsid);
        this.owner = data.owner;
        this.title = data.title;
        this.todomessagesCollection = this.afs
            .collection('todos')
            .doc(data.fsid)
            .collection('messages', ref => ref.orderBy('sentAt', 'desc'));
        this.todomessages$ = this.todomessagesCollection.valueChanges();
        this.usersAppCollection = this.afs.collection('usersApp', ref => ref.where('email', '==', data.owner));
        this.usersAppSub = this.usersAppCollection.valueChanges().subscribe(u => {
            this.uid = u[0].uid;
            console.log(this.uid);
        });
        this.formMessage = this.fb.group({
            iscancelled: false,
            content: ['', Validators.required],
        });
    }

    ngOnDestroy(): void {
        if (this.usersAppSub) {
            this.usersAppSub.unsubscribe();
        }
    }

    openSnackBar(message, action) {
        this.snackBar.open(message, action, { duration: 5000 });
    }

    sendMessage(formvalue, title, uid) {
        const message = {
            type: 'chat',
            author: firebase.auth().currentUser.email,
            img: firebase.auth().currentUser.photoURL,
            content: formvalue.content,
            sentAt: new Date(),
            todoTitle: title,
            authorUid: uid,
        };
        this.openSnackBar('Message envoyé', 'Info');
        this.formMessage.reset();
        this.todomessagesCollection.add(message);
    }
}

// COMPOSANT CLOSE / REOPEN  MESSAGES
@Component({
    selector: 'app-close-messages',
    templateUrl: 'closeOpenMessage.html',
})
export class TodocloseComponent implements OnInit {
    logoMessages: string;
    todomessagesCollection: AngularFirestoreCollection<any>;
    todoDoc: AngularFirestoreDocument<any>;
    todomessagesObservable: Observable<any>;
    values = '';
    formMessage: FormGroup;
    currentEmail;

    constructor(
        public dialogRef: MatDialogRef<TodocloseComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private afs: AngularFirestore,
        private fb: FormBuilder,
        private snackBar: MatSnackBar,
        private fs: FirestoreService,
        private auth: AuthService,
    ) {
        this.logoMessages = './assets/images/messaging.png';
        // console.log(data.fsid);
        this.todomessagesCollection = this.afs
            .collection('todos')
            .doc(data.fsid)
            .collection('messages', ref => ref.orderBy('sentAt', 'desc'));
        this.todoDoc = this.afs.collection('todos').doc(data.fsid);
        this.todomessagesObservable = this.todomessagesCollection.valueChanges();
        this.formMessage = this.fb.group({
            iscancelled: false,
            content: ['', Validators.required],
        });
    }

    ngOnInit() {
        this.currentEmail = this.auth.currentEmail;
    }

    openSnackBar(message, action) {
        this.snackBar.open(message, action, { duration: 5000 });
    }

    /**
     * @description Closes message
     * @param formvalue
     */
    closeMessage(formvalue) {
        console.log(formvalue.iscancelled);
        console.log(this.todoDoc);
        if (!formvalue.iscancelled) {
            const message = {
                type: 'Closed',
                author: firebase.auth().currentUser.email,
                img: firebase.auth().currentUser.photoURL,
                content: formvalue.content,
                sentAt: new Date(),
            };
            this.formMessage.setValue({ content: '', iscancelled: '' });
            this.todomessagesCollection.add(message);
            this.fs.update(this.todoDoc, { isDone: true, closedAt: this.fs.timestamp }, this.currentEmail);
            this.dialogRef.close();
            this.openSnackBar('Todo fermé', 'Info');
        } else {
            this.formMessage.controls['content'].clearValidators();
            this.formMessage.controls['content'].updateValueAndValidity();
            const message = {
                type: 'Cancelled',
                content: formvalue.content,
                author: firebase.auth().currentUser.email,
                img: firebase.auth().currentUser.photoURL,
                sentAt: new Date(),
            };
            this.formMessage.setValue({ content: '', iscancelled: '' });
            this.todomessagesCollection.add(message);
            this.fs.update(
                this.todoDoc,
                { isCancelled: true, isDone: true, closedAt: this.fs.timestamp },
                this.currentEmail,
            );
            this.dialogRef.close();
            this.openSnackBar('Todo Annulé', 'Info');
        }
    }
}

// COMPOSANT DELETE MODAL
@Component({
    selector: 'app-delete-dialog',
    templateUrl: 'app-delete-dialog.html',
})
export class DeleteModalComponent {
    constructor(public dialogRef: MatDialogRef<DeleteModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

    onNoClick(): void {
        this.dialogRef.close();
    }
}

// COMPOSANT DRIVE PICKER
@Component({
    selector: 'app-drive-dialog',
    templateUrl: 'drive.html',
})
export class DrivefilesComponent implements OnInit, OnDestroy {
    todoDoc: AngularFirestoreDocument<any>;
    myCurrentEmailSub: Subscription;
    logoDrive: string;
    driveFile = [];
    key: String;
    fsid: string;
    currentEmail;

    // The Browser API key obtained from the Google Developers Console.
    developerKey = environment.firebaseConfig.apiKey;

    // The Client ID obtained from the Google Developers Console. Replace with your own Client ID.
    // clients OAuth 2.0 projet todo-app-dev (gianni.prata@soitec.com)
    clientId = environment.clientId;

    // Scope to use to access to drive.
    scope = 'https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/drive';

    pickerApiLoaded = false;
    infosClicked = 0;

    constructor(
        public dialogRef: MatDialogRef<DrivefilesComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private afs: AngularFirestore,
        private firestoreService: FirestoreService,
        private auth: AuthService,
    ) {
        this.logoDrive = './assets/images/drive.png';
        this.todoDoc = this.afs.collection('todos').doc(data.fsid);
        this.todoDoc.valueChanges().subscribe(d => {
            if (d.driveFiles) {
                this.driveFile = d.driveFiles;
            }
        });
    }

    ngOnInit() {
        this.myCurrentEmailSub = this.auth.user$
            .pipe(
                map(settings => {
                    return settings.email;
                }),
            )
            .subscribe(
                email => {
                    this.currentEmail = email;
                },
                error => console.log('Observer a reçu une erreur : ' + error),
            );
    }

    ngOnDestroy() {
        if (this.myCurrentEmailSub) {
            this.myCurrentEmailSub.unsubscribe();
        }
    }

    infos() {
        if (this.infosClicked === 0) {
            this.infosClicked = 1;
        } else {
            this.infosClicked = 0;
        }
    }

    delDriveFile(dfile: any) {
        this.driveFile.splice(dfile);
        this.todoDoc.update({ driveFiles: this.driveFile });
    }

    onApiLoad() {
        gapi.load('picker', this.onPickerApiLoad.bind(this));
    }

    onPickerApiLoad() {
        this.pickerApiLoaded = true;
        this.createPicker();
    }

    async createPicker() {
        const TeamsView = new google.picker.DocsView()
            .setIncludeFolders(true)
            .setEnableTeamDrives(true)
            .setOwnedByMe(undefined);

        const DocView = new google.picker.DocsView()
            .setSelectFolderEnabled(true)
            .setIncludeFolders(true)
            .setOwnedByMe(false);

        const uploadView = new google.picker.DocsUploadView();

        const token = await this.auth.authorize();
        const pickerBuilder = new google.picker.PickerBuilder();
        const picker = pickerBuilder
            .setOAuthToken(token)
            // .enableFeature(google.picker.Feature.MINE_ONLY)
            .addView(DocView)
            .setSize(1000)
            .addView(TeamsView)
            .addView(google.picker.ViewId.RECENTLY_PICKED)
            .addView(uploadView)
            .setDeveloperKey(this.developerKey)
            .setCallback(this.pickerCallback.bind(this))
            .build();
        picker.setVisible(true);
    }

    pickerCallback(data) {
        let url = 'nothing';
        let nameFile = 'nothing';
        if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
            const doc = data[google.picker.Response.DOCUMENTS][0];
            if (doc.embedUrl) {
                console.log(doc);
                url = doc.embedUrl;
                nameFile = doc.name;
                this.AddDriveUrlFile(url, nameFile);
            } else {
                this.createPicker();
            }
        }
    }

    AddDriveUrlFile(url, nameFile) {
        this.driveFile.push({ 'url': url, 'name': nameFile });
        this.firestoreService.upsert(this.todoDoc, { driveFiles: this.driveFile }, this.currentEmail);
    }
}

@Component({
    selector: 'efficiency-dialog',
    templateUrl: 'efficiency.html',
})
export class efficiencyDialog {
  efficiency: any;
  efficiencyComment: any;
  efficiencyUpdateDate: any;
  owner: any;
  updatedAt: any;
  key: any;
    constructor(public dialogRef: MatDialogRef<efficiencyDialog>, @Inject(MAT_DIALOG_DATA) public data,
      public fs: FirestoreService) {
        this.key = data.todo.key;
        this.efficiency = data.todo.efficiency;
        this.efficiencyComment = data.todo.efficiencyComment;
        this.owner = data.todo.owner;
        this.updatedAt = data.todo.updatedAt;
        this.efficiencyUpdateDate = data.todo.efficiencyUpdateDate;
        console.log(data.todo);
    }

    SaveEfficiency(value, comment) {
        // this.fs.update(`8d/${docId}`, { countclosed2D: countclosed2D.length });
        this.fs.update(`todos/${this.key}`, {efficiency: value, efficiencyComment: comment}, this.owner).then( x => {
          this.fs.update(`todos/${this.key}`, {efficiencyUpdateDate: this.updatedAt}, this.owner);
        });
        
        console.log(this.owner);
        console.log(value);
        console.log(comment);
        this.dialogRef.close();
    }
}
