<mat-toolbar class="menu" *ngIf="auth.user$ | async as user">
    <mat-menu #menuMore="matMenu">
        <button mat-menu-item (click)="editForm()">
            <mat-icon>settings</mat-icon>
            <span>Settings</span>
        </button>
        <a
            mat-button
            target="_blank"
            href="https://yuzit.soitec.com/yuzit/ls/community/wedo-app-forum"
            matTooltip="{{ 'Community' | translate }}"
            matTooltipPosition="above"
        >
            <mat-icon style="margin-right: 16px;color:#707070">group</mat-icon>
            <span
                style="font-size: 15px !important;
      color: #2e2c2c;
      font: 400 13.3333px Arial;"
                >Forum</span
            >
        </a>
        <br />
        <a
            mat-button
            target="_blank"
            href="https://hangouts.google.com/group/10kDWzm08xmhCWl92"
            matTooltip="Hangouts"
            matTooltipPosition="above"
        >
            <mat-icon style="margin-right: 16px;color:#707070">chat</mat-icon>
            <span
                style="font-size: 15px !important;
    color: #2e2c2c;
    font: 400 13.3333px Arial;"
                >Hangouts</span
            >
        </a>
        <button mat-menu-item (click)="auth.logout()">
            <mat-icon>power_settings_new</mat-icon>
            <span>Logout</span>
        </button>
    </mat-menu>
    <button mat-icon-button (click)="goToHome()" matTooltip="Home - dashboard">
        <img src="./assets/images/logowedo.png" style="height: 30px;margin-right: 1%;"/>
    </button>
    <span class="bar-spacer"></span>
    <button mat-button routerLink="home" (click)="goToHome()">
        <span class="menuItem" routerLinkActive="menuItemActive">Dashboard</span>
    </button>
    <button mat-button routerLink="mytodos" (click)="setTeamsMenu(false, 'mytodos')">
        <span class="menuItem" routerLinkActive="menuItemActive">My ToDos</span>
    </button>
    <button mat-button routerLink="myteamsList" (click)="setTeamsMenu(true, 'myteamsList')" id='myteamsListButton'>
        <span class="menuItem" [ngClass]="{'menuItemActive': myteams}">My Teams</span>
    </button>
    <button mat-button routerLink="myteams" (click)="setTeamsMenu(true, 'myteamsList')" id='myteamsButton' style="display: none">
        <span class="menuItem" [ngClass]="{'menuItemActive': myteams}">My Teams</span>
    </button>
    <button mat-button *ngIf="path == 'myteamsList'" routerLink="myteams" (click)="setTeamsMenu(true, 'myteams')" id='myteamsListIcon'><mat-icon>view_module</mat-icon> {{ 'cards view' | translate }}</button>
    <button mat-button *ngIf="path == 'myteams'" routerLink="myteamsList" (click)="setTeamsMenu(true, 'myteamsList')" id='myteamsIcon'><mat-icon>view_headline</mat-icon> {{ 'list view' | translate }}</button>
    <mat-icon mat-button (click)="openDialogNotifications()" class="menu-notifications-bell">
        <mat-icon matBadge="{{dataUserNotifsLength}}" matBadgeColor="warn" matBadgePosition="below after" class="small-badge">notifications</mat-icon>
    </mat-icon>
    <img [src]="user.photoURL" class="menu-GooglePicture" (click)="editForm()" alt="photo" />
    <div class="menuTrigger">
        <button mat-icon-button [matMenuTriggerFor]="menuMore">
            <mat-icon>more_vert</mat-icon>
        </button>
    </div>
</mat-toolbar>
