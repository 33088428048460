import { AfterViewInit, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from 'src/app/services/sidenav.service';
import { TeamService } from 'src/app/services/team.service';
import { BottomTeamChoicesSheetComponent } from '../team.component';

@Component({
    selector: 'app-team-container',
    templateUrl: './team-container.component.html',
    styleUrls: ['./team-container.component.css'],
    providers: [TeamService],
    encapsulation: ViewEncapsulation.None,
})
export class TeamContainerComponent implements AfterViewInit {
    mode: MatDrawerMode = 'push';

    @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;
    constructor(private bottomSheet: MatBottomSheet, private sidenavService: SidenavService) {}

    ngAfterViewInit(): void {
        this.sidenavService.setSidenav(this.sidenav);
    }

    openBottomSheet(): void {
        this.bottomSheet.open(BottomTeamChoicesSheetComponent);
    }

    close() {
        this.sidenav.close();
    }

    open() {
        this.sidenav.open();
    }
}
