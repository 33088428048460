<div>
  <button mat-icon-button [mat-dialog-close] class="closeModal">
  <mat-icon>close</mat-icon>
  </button>
  <h2 style="margin-left: 3px;font-weight: 100"><mat-icon style="vertical-align: text-bottom;">message</mat-icon>{{'closing comment' | translate}}</h2>
  <br>
  <form [formGroup]="formMessage">
    <mat-checkbox  formControlName="iscancelled" #iscancelled>{{'isCancelled' | translate}}</mat-checkbox>
    <mat-form-field class="form-full-width" *ngIf="formMessage.controls['iscancelled'].value === false">
      <textarea matInput formControlName="content" type="text" placeholder="{{ 'Your closing comment' | translate }}"
        #content ></textarea>
    </mat-form-field>
    <div mat-dialog-actions style='text-align: right; display: block !important'>
      <button mat-flat-button class="valid-button" style="margin-bottom: 2%;margin-right: 2%;"
        matTooltip="{{'Send message' | translate}}" matTooltipPosition="above" (click)="closeMessage(formMessage.value)"
        *ngIf="formMessage.controls['content'].value !=='' && formMessage.controls['iscancelled'].value === false">
        {{'close todo' | translate}}
      </button>
      <button mat-flat-button class="valid-button" style="margin-bottom: 2%;margin-right: 2%;"
      matTooltip="{{'Send message' | translate}}" matTooltipPosition="above" (click)="closeMessage(formMessage.value)"
      *ngIf="formMessage.controls['iscancelled'].value === true">
      {{'cancel todo' | translate}}
    </button>
    </div>
  </form>
  <div mat-dialog-content style="max-height: 20%">
    <!-- <p *ngIf="(todomessagesObservable | async)?.length == 0">Pas encore de messages pour ce todo</p> -->
    <div *ngFor="let message of todomessagesObservable | async" style="margin-bottom: 1%">
      <mat-card id="messages">
        <mat-card-header>
          <div mat-card-avatar>
            <img [src]="message?.img" height="32px" alt="photo" *ngIf='message?.img'>
          </div>
          <mat-card-subtitle>{{message?.author}} | {{message?.sentAt?.toDate() | date:'dd-MM-yyyy HH:mm:ss'}} |
            ({{message?.type}})
          </mat-card-subtitle>
          <mat-card-subtitle *ngIf="message?.type == 'reject'">
            <mat-chip-list>
              <mat-chip color="warn" selected="true">todo terminé</mat-chip>
            </mat-chip-list>
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <p>
            {{message?.content}}
          </p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <!-- {{formMessage.value | json }} -->
</div>
