<div>
  <button mat-icon-button [mat-dialog-close] class="closeModal">
    <mat-icon>close</mat-icon>
  </button>
  <!-- {{teamID}} -->
  <br>
  <br>
  <mat-card class="userSettings">
    <mat-card-header>
      <div mat-card-avatar style="height: 65px !important; width: 65px !important">
        <img [src]="photoURL" height="65px" alt="photo">
      </div>
      <mat-card-title>{{ displayName }}</mat-card-title>
      <mat-card-subtitle>{{ email }}</mat-card-subtitle>
    </mat-card-header>
    <br>

    <form [formGroup]="myForm" appFireFormSettings path='usersApp/{{uid}}' [dialogRef]="dialogRef" (stateChange)="changeHandler($event)">
      <!-- <div class="columns">
      <div class="column">
        <span>Reactive Form Data</span><br> {{ myForm.value | json }} {{myForm.status}}
      </div>
      <div class="column">
        <h3>Firestore Data</h3><br> {{ myDoc | async | json }}
      </div>
    </div> -->
      <label style="margin: 10px;">{{ 'Display mode' | translate }} (*) :</label><br>
      <mat-radio-group formControlName="viewMode">
        <mat-radio-button style="margin: 10px;" value="cards"><mat-icon>view_module</mat-icon></mat-radio-button>
        <mat-radio-button style="margin: 10px;" value="lines"><mat-icon>view_headline</mat-icon></mat-radio-button>
      </mat-radio-group>
      <h6 style="margin: 0px">* {{ 'Display mode condition' | translate }}</h6>
      <br>
      <br>
      <label style="margin: 10px;">{{ 'Language' | translate }} :</label><br>
      <mat-radio-group formControlName="language">
        <mat-radio-button style="margin: 10px;" value="fr">Français</mat-radio-button>
        <mat-radio-button style="margin: 10px;" value="en">English</mat-radio-button>
      </mat-radio-group>
      <br>
      <br>
      <div style="text-align: right;">
        <button mat-flat-button class="valid-button" [disabled]="myForm.invalid || state !== 'modified'">{{ 'button_save' | translate }}</button>
      </div>
    </form>
  </mat-card>
  <br>
  <div class="formStatus">
    <div>
      <span *ngIf="state === 'modified'">{{ 'Data not saved' | translate }}</span>
      <span *ngIf="state === 'synced'">{{ 'Data saved' | translate }}</span>
      <span *ngIf="state === 'error'">{{ 'Data error' | translate }}</span>
    </div>
  </div>
</div>