import { Pipe, PipeTransform } from "@angular/core";
import linkifyStr from 'linkifyjs/string';
import { Options } from 'linkifyjs';

@Pipe({
    name: 'linkify'
})
export class LinkifyjsPipe implements PipeTransform {
    
    transform(value: string, options?: Options): string {
        return value ? linkifyStr(value, options) : value;
    }
    
}
