<div>
  <button mat-icon-button [mat-dialog-close] class="closeModal">
    <mat-icon>close</mat-icon>
  </button>
  <br>
  <br>
  <form [formGroup]="myForm" appFireFormTeam path='teams/{{(docID)}}' (stateChange)="changeHandler($event)" [dialogRef]="dialogRef">
    <!-- <div class="columns">
      <div class="column">
        <span>Reactive Form Data</span><br> {{ myForm.value | json }} {{myForm.status}}
      </div>
      <div class="column">
        <h3>Firestore Data</h3><br> {{ myDoc | async | json }}
      </div>
    </div> -->
    <img class="logoqvt" [src]="logoqvt" height="100px">
    <h3>{{ 'For QVT actions plan' | translate }}</h3>

    <mat-form-field class="m-right" *ngIf="title.value">
      <input matInput placeholder="{{ 'Title' | translate }}" formControlName="title" readonly>
    </mat-form-field>

    <!-- <mat-form-field class="m-right">
      <input matInput placeholder="members" formControlName="members">
    </mat-form-field> -->

    <mat-form-field class="m-right">
      <textarea matInput placeholder="Note" formControlName="note"></textarea>
    </mat-form-field>

    <!-- orgniv1 -->
    <div>
      <mat-form-field style="width: 95%;display: inline-block;">
        <mat-select placeholder="{{ 'orgniv1' | translate }}" formControlName="orgniv1" (ngModelChange)="orgniv1Changed()">
          <mat-option *ngFor="let niv1 of orgniv1" [value]="niv1.value">
            {{niv1?.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- orgniv2 -->
    <div>
      <mat-form-field style="width: 95%;display: inline-block;">
        <mat-select placeholder="{{ 'orgniv2' | translate }}" formControlName="orgniv2" (ngModelChange)="orgniv2Changed($event)">
          <mat-option *ngFor="let niv2 of niv2Filtered" [value]="niv2.value">
            {{ niv2?.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

       <!-- orgniv3 -->
       <div *ngIf="niv3Filtered.length || niv3Value.value">
          <mat-form-field style="width: 95%;display: inline-block;">
            <mat-select placeholder="{{ 'orgniv3' | translate }}" formControlName="orgniv3" (ngModelChange)="orgniv3Changed($event)">
              <mat-option *ngFor="let niv3 of niv3Filtered" [value]="niv3.value">
                {{ niv3?.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

           <!-- orgniv4 -->
    <div *ngIf="niv4Filtered.length || niv4Value.value">
        <mat-form-field style="width: 95%;display: inline-block;">
          <mat-select placeholder="{{ 'orgniv4' | translate }}" formControlName="orgniv4" (ngModelChange)="orgniv4Changed($event)">
            <mat-option *ngFor="let niv4 of niv4Filtered" [value]="niv4.value">
              {{ niv4?.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

         <!-- orgniv5 -->
    <div *ngIf="niv5Filtered.length || niv5Value.value">
        <mat-form-field style="width: 95%;display: inline-block;">
          <mat-select placeholder="{{ 'orgniv5' | translate }}" formControlName="orgniv5" (ngModelChange)="orgniv5Changed($event)">
            <mat-option *ngFor="let niv5 of niv5Filtered" [value]="niv5.value">
              {{ niv5?.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    <tag-input matInput formControlName="tags" placeholder="+ Tag" secondaryPlaceholder="+ Tag" [addOnBlur]="true"
      [clearOnBlur]="true" [theme]="'dark'" [onAdding]="transform">
      <tag-input-dropdown [focusFirstElement]="true" [autocompleteItems]="autocompleteItems">
      </tag-input-dropdown>
    </tag-input>
    <br>
    <div style="text-align: right;">
      <button mat-flat-button class="valid-button" [disabled]="myForm.invalid || state !== 'modified'">{{ 'button_save' | translate }}</button>
    </div>
  </form>
  <br>
  <div class="formStatus">
    <div>
      <span *ngIf="state === 'modified'">{{ 'Data not saved' | translate }}</span>
      <!-- <span *ngIf="state === 'synced'">Dernière mise à jour : {{TeamupdatedAt |date:'medium'}}</span> -->
      <span *ngIf="state === 'synced'">{{ 'Data saved' | translate }}</span>
      <span *ngIf="state === 'error'">{{ 'Data error' | translate }}</span>
    </div>
  </div>
</div>
