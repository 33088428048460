import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  logoSoitecFooter: string;
  stage = environment.stage;
  version = environment.version;

  constructor() {
    this.logoSoitecFooter  = './assets/logoSoitecFooter.png';
   }

  ngOnInit() {
  }

}
