import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import firebase from 'firebase/compat/app';
import * as _ from 'lodash';
import { Observable, of as observableOf, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { TodoService } from '../services/todo.service';
import { FormTeamComponent } from '../team/form-team/form-team.component';
import { FormMembersComponent } from './form-members/form-members.component';
import { FormTeamQvtComponent } from './form-team/form-team-qvt.component';
export interface User {
    name: string;
}

@Component({
    selector: 'app-team',
    templateUrl: './team.component.html',
    styleUrls: ['./team.component.css'],
})
export class TeamComponent implements OnInit, OnDestroy {
    myteams$: Observable<any>;
    mytagsSub: Subscription;
    teamsCollection: AngularFirestoreCollection<any>;
    options: User[] = [];
    filteredOptions: Observable<User[]>;
    formGroupAddMember: FormGroup;
    key;
    // search pipe
    search: string = null;
    autocompleteItems = ['P1'];
    // Firestore Document
    private docRef: AngularFirestoreDocument;
    logoqvt: string;
    logo8d: string;

    constructor(
        public auth: AuthService,
        public dialog: MatDialog,
        private todoservice: TodoService,
        public afs: AngularFirestore,
        private fb: FormBuilder,
        public snackBar: MatSnackBar,
        public translate: TranslateService,
        private bottomSheet: MatBottomSheet,
    ) {
        this.logoqvt = './assets/images/logoqvt.png';
        this.logo8d = './assets/images/logo8d.png';
    }

    ngOnInit() {
        console.log('init teamsComponent');
        this.myteams$ = this.todoservice.getUserTeams();

        // this.options = this.todoservice.getUsersSoitec();
        this.formGroupAddMember = this.fb.group({
            member: new FormControl(''),
        });
        this.filteredOptions = this.formGroupAddMember.controls.member.valueChanges.pipe(
            startWith<string | User>(''),
            map(value => (typeof value === 'string' ? value : value.name)),
            map(name => (name ? this._filter(name) : this.options.slice())),
        );
        // Subscription : mytags
        this.mytagsSub = this.todoservice.getUserTeamTags().subscribe(
            mytags => {
                this.autocompleteItems = mytags;
                // console.log(mytags);
            },
            error => console.log('Observer a reçu une erreur : ' + error),
        );
    }

    ngOnDestroy() {
        // avoid memory leaks here by cleaning up after ourselves. If we
        // don't then we will continue to run our initialiseInvites()
        // method on every navigationEnd event.
        if (this.mytagsSub) {
            this.mytagsSub.unsubscribe();
        }
    }

    openBottomSheet(): void {
        this.bottomSheet.open(BottomTeamChoicesSheetComponent);
    }

    isOwnerUserConnected(creator): boolean {
        return this.auth.currentEmail === creator;
    }

    isCreatorForDelete(member, creator): boolean {
        return member === creator;
    }

    getUsers() {
        this.options = this.todoservice.getUsersSoitec();
    }

    openSnackBar(message, action) {
        this.snackBar.open(message, action, { duration: 5000 });
    }

    private _filter(name: string): User[] {
        const filterValue = name.toLowerCase();
        return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    openForm(teamID?, type?): void {
        if (type === 'QVT') {
            const dialogRef = this.dialog.open(FormTeamQvtComponent, {
                height: '90%',
                width: '75%',
                autoFocus: false,
                data: { teamID: teamID },
            });
            dialogRef.afterClosed().subscribe(result => {
                console.log('The dialog was closed');
            });
        } else {
            const dialogRef = this.dialog.open(FormTeamComponent, {
                height: '90%',
                width: '75%',
                autoFocus: false,
                data: { teamID: teamID },
            });
            dialogRef.afterClosed().subscribe(result => {
                console.log('The dialog was closed');
            });
        }
    }

    openFormMembers(teamID?): void {
        const dialogRef = this.dialog.open(FormMembersComponent, {
            height: '90%',
            width: '75%',
            autoFocus: false,
            data: { teamID: teamID },
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

    addMember(value) {
        console.log(value);
    }

    // TAGS add
    public transform(value): Observable<object> {
        console.log(value);
        if (value.value) {
            const item = { display: `#${value.value}`, value: `#${value.value}` };
            return observableOf(item);
        } else if (value) {
            const item = { display: `#${value}`, value: `#${value}` };
            return observableOf(item);
        } else {
            return;
        }
    }

    removeTag(event, key) {
        // console.log(event);
        const db = firebase.firestore();
        const todRef = db.collection('teams').doc(key);
        todRef
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    const tagsModified = doc.data().tags.filter(t => t.value !== event.value);
                    // console.log(tagsModified);
                    db.runTransaction(async transaction => {
                        // This code may get re-run multiple times if there are conflicts.
                        transaction.update(todRef, { tags: tagsModified });
                    })
                        .then(function() {
                            // console.log('Transaction successfully committed!');
                        })
                        .catch(function(error) {
                            // console.log('Transaction failed: ', error);
                        });
                } else {
                    // doc.data() will be undefined in this case
                    // console.log('No such document!');
                }
            })
            .catch(function(error) {
                console.log('Error getting document:', error);
            });
    }

    addTag(event, key) {
        const db = firebase.firestore();
        const todRef = db.collection('teams').doc(key);
        db.runTransaction(async transaction => {
            // This code may get re-run multiple times if there are conflicts.
            const doc = await transaction.get(todRef);
            // console.log('doc', doc);
            if (!doc.data().tags) {
                transaction.update(todRef, {
                    tags: [event],
                });
            } else {
                // console.log('else tag');
                const tags = doc.data().tags;
                tags.push(event);
                transaction.update(todRef, { tags: tags });
            }
        })
            .then(function() {
                console.log('Transaction successfully committed!');
            })
            .catch(function(error) {
                console.log('Transaction failed: ', error);
            });
    }

    filterTag(tag) {
        // console.log(tag);
        this.search = tag.value;
    }

    onSelectionChanged(event: MatAutocompleteSelectedEvent, Teamkey, membersArray) {
        this.key = Teamkey;
        if (_.includes(membersArray, event.option.value)) {
            this.openSnackBar('Déjà présent dans cette Team', 'Info');
            this.formGroupAddMember.patchValue({ member: '' });
        } else {
            membersArray.push(event.option.value);
            console.log(membersArray);
            const ma = _.uniq(membersArray);
            this.update(ma);
            this.openSnackBar('Membre ajouté', 'Info');
        }
    }

    async update(membersArray) {
        try {
            const docRef = this.afs.doc(`teams/${this.key}`);
            await docRef.update({ members: membersArray });
            this.formGroupAddMember.patchValue({ member: '' });
        } catch (err) {
            this.openSnackBar('Attention il y a eu une erreur', 'Info');
        }
    }

    // Writes changes to Firestore
    async setDoc() {
        try {
            await this.docRef.set(this.formGroupAddMember.value, { merge: true });
        } catch (err) {
            console.log(err);
            this.openSnackBar('Attention il y a eu une erreur', 'Info');
        }
    }

    delMember(index, key, membersArray) {
        this.key = key;
        this.docRef = this.afs.doc(`teams/${key}`);
        console.log(key);
        membersArray.splice(index, 1);
        console.log(membersArray);
        this.update(membersArray);
        this.openSnackBar('Membre supprimé', 'Info');
    }

    DeleteModal(key): void {
        const dialogRef = this.dialog.open(DeleteTeamModalComponent);
        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                const teamtodelete = this.afs.collection('teams').doc(key);
                teamtodelete.delete();
            }
        });
    }
}

// COMPOSANT DELETE MODAL

@Component({
    selector: 'app-team-delete-dialog',
    templateUrl: 'app-team-delete-dialog.html',
})
export class DeleteTeamModalComponent {
    constructor(public dialogRef: MatDialogRef<DeleteTeamModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

    onNoClick(): void {
        this.dialogRef.close();
    }
}

@Component({
    selector: 'app-botton-create-team-choices',
    templateUrl: 'botton-create-team-choices.html',
})
export class BottomTeamChoicesSheetComponent {
    constructor(
        public dialog: MatDialog,
        private _bottomSheetRef: MatBottomSheetRef<BottomTeamChoicesSheetComponent>,
    ) {}

    openLink(event: MouseEvent): void {
        this._bottomSheetRef.dismiss();
        event.preventDefault();
    }

    openForm(teamID?): void {
        const dialogRef = this.dialog.open(FormTeamComponent, {
            height: '90%',
            width: '75%',
            autoFocus: false,
            data: { teamID: teamID },
        });

        dialogRef.afterClosed().subscribe(result => {
            this._bottomSheetRef.dismiss();
            event.preventDefault();
            console.log('The dialog was closed');
        });
    }

    openFormQVT(teamID?): void {
        const dialogRef = this.dialog.open(FormTeamQvtComponent, {
            height: '90%',
            width: '75%',
            autoFocus: false,
            data: { teamID: teamID },
        });

        dialogRef.afterClosed().subscribe(result => {
            this._bottomSheetRef.dismiss();
            event.preventDefault();
            console.log('The dialog was closed');
        });
    }
}
