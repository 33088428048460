<div *ngIf="auth.user$ | async as user">
    <div id="todoIndicator">
        <mat-menu #filtres="matMenu">
            <button mat-menu-item (click)="filterIsDone(false)">
                <span translate>{{ 'Open' | translate }}</span>
                <mat-icon style="color: rgb(128, 212, 233);margin-right: 0;">remove</mat-icon>
                <mat-icon style="color: rgb(252, 136, 136)">remove</mat-icon>
            </button>
            <button mat-menu-item (click)="filterIsDone(true)">
                <span translate>{{ 'Closed' | translate }}</span>
                <mat-icon style="color: #006837">remove</mat-icon>
            </button>
            <button mat-menu-item (click)="filterIsDone(null)">
                <span translate>{{ 'Open/Closed' | translate }}</span>
                <mat-icon style="color: rgb(128, 212, 233);margin-right: 0;">remove</mat-icon>
                <mat-icon style="color: #006837;margin-right: 0;">remove</mat-icon>
                <mat-icon style="color: rgb(252, 136, 136)">remove</mat-icon>
            </button>
            <button mat-menu-item (click)="filterDelay()">
                <span translate>{{ 'Open' | translate }} {{ 'Late' | translate }}</span>
                <mat-icon style="color: rgb(252, 136, 136)">remove</mat-icon>
            </button>
        </mat-menu>
        <button mat-flat-button [matMenuTriggerFor]="filtres" *ngIf="filter===false && !latefilter">
            <span *ngIf="teamTodoName !== ''" style="padding: 5px;" matBadge="{{todoAllWitoutShared$ | async}}"
                matBadgePosition="after" matBadgeOverlap="true">{{ 'Open' | translate }}
            </span>
            <span *ngIf="teamTodoName === ''" style="padding: 5px;" matBadge="{{(todoAll$ | async)?.length}}"
                matBadgePosition="after" matBadgeOverlap="true">{{ 'Open' | translate }}
            </span>
            <mat-icon style="color: rgb(128, 212, 233);margin-right: 0;">remove</mat-icon>
            <mat-icon style="color: rgb(252, 136, 136)">remove</mat-icon>
        </button>
        <button mat-flat-button [matMenuTriggerFor]="filtres" *ngIf="filter===true && !latefilter">
            <span style="padding: 5px;" matBadge="{{(todoAll$ | async)?.length}}" matBadgePosition="after"
                matBadgeOverlap="true">{{'Closed' | translate }}</span>
            <mat-icon style="color: #006837" matTooltip="Todo Terminé" matTooltipPosition="above">remove</mat-icon>
        </button>
        <button mat-flat-button [matMenuTriggerFor]="filtres" *ngIf="filter===null && !latefilter">
            <span style="padding: 5px;" matBadge="{{(todoAll$ | async)?.length}}" matBadgePosition="after"
                matBadgeOverlap="true">
                {{ 'Open/Closed' | translate }}
            </span>
            <mat-icon style="color: rgb(128, 212, 233);margin-right: 0;">remove</mat-icon>
            <mat-icon style="color: #006837;margin-right: 0;">remove</mat-icon>
            <mat-icon style="color: rgb(252, 136, 136)">remove</mat-icon>
        </button>
        <button mat-flat-button [matMenuTriggerFor]="filtres" *ngIf="latefilter">
            <span style="padding: 5px;" matBadge="{{(todoAll$ | async)?.length}}" matBadgePosition="after"
                matBadgeOverlap="true">
                {{ 'Open' | translate }} {{ 'Late' | translate }}
            </span>
            <mat-icon style="color: rgb(252, 136, 136)">remove</mat-icon>
        </button>
        <br>
        <span matTooltip="{{ 'periodTooltip' | translate }}" matTooltipPosition="above" *ngIf="filter === true">
            <mat-checkbox [(ngModel)]="periodChecked" (click)="periodClicked()" style="font-size: 11px;">
                {{ 'period' | translate }}
            </mat-checkbox>
        </span>
        <span matTooltip="{{ 'hide' | translate }}" (click)="hidePeriod()" matTooltipPosition="above"
            *ngIf="periodChecked">
            <button mat-icon-button>
                <mat-icon>remove_red_eye</mat-icon>
            </button>
        </span>
        <div *ngIf="filter === true && periodChecked && showPeriod">
            <mat-form-field>
                <input matInput [matDatepicker]="from" placeholder="{{ 'from' | translate }}" autocomplete="off"
                    [formControl]="fromControl">
                <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
                <mat-datepicker #from></mat-datepicker>
                <mat-datepicker touchUi #from></mat-datepicker>
            </mat-form-field><br>
            <mat-form-field>
                <input matInput [matDatepicker]="to" placeholder="{{ 'to' | translate }}" autocomplete="off"
                    [formControl]="toControl">
                <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
                <mat-datepicker #to></mat-datepicker>
                <mat-datepicker touchUi #to></mat-datepicker>
            </mat-form-field><br>
            <button mat-stroked-button (click)="findPeriod()">{{ 'apply' | translate }}</button>
        </div>
    </div>
    <!-- Search bar -->
    <mat-form-field appearance="outline" class="searchBar">
        <mat-label>{{ 'search' | translate }}</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="{{ 'search_placeholder' | translate }}" [(ngModel)]="search" autocomplete="off" />
        <button mat-button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="search=''">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <div style='position: fixed;top: 70px;height: 29px;width: 100%;'>
        <mat-progress-bar mode="indeterminate"
            *ngIf="(todoAll$ | async)?.length !== 0 && !((todoAll$ | async)?.length > 0)"></mat-progress-bar>
    </div>
    <mat-chip-list>
        <mat-chip *ngIf="teamTodoName !== ''" id="teamChip">
            Team : {{teamTodoName}}
        </mat-chip>
    </mat-chip-list>
    <div *ngIf="(todoAll$ | async)?.length !== 0 && !((todoAll$ | async)?.length > 0)"
        style="margin-top: 56px; text-align: center">loading...</div>
    <cdk-virtual-scroll-viewport itemSize="300px" class="centered">

        <div *ngIf="(todoAll$ | async)?.length==0 && !id" style="text-align: center;">
            <h1>{{ 'No Todo' | translate }}</h1>
        </div>
        <div *ngIf="(todoAll$ | async)?.length==0 && id" style="text-align: center;">
            <h1>{{ 'oops' | translate }}</h1>
        </div>
        <ul class="cards" style='list-style: none; margin-top: 0%' [ngClass]="{'density': myViewPreference ==='lines'}">
            <!-- <div *ngIf="todoAll.length === 0">No results, try clearing filters</div> -->
            <li class="card fadeIn" *cdkVirtualFor="let todo of todoAll$ | async | SearchPipe:search; trackBy:trackTodo; let idx=index"
                [ngClass]="{'line-li': myViewPreference ==='lines', 'swipeleft':swipeleft && currentidx === idx, 'swiperight':swiperight && currentidx === idx }"
                (swipeleft)="openSwipeDialogCloseReopen(idx, todo?.key, todo?.isDone, todo?.teamID)"
                (swiperight)="editSwipeForm(idx, todo?.key, todo?.teamID, todo?.isDone)">

                <!-- LINE1 -->
                <div style='width: 100%; height: 32px;'>
                    <div class="card-header-status" [ngClass]="{'line-header-status': myViewPreference ==='lines'}">
                        <div id="card-check"
                            [ngClass]="{'statusGreen': todo?.isDone, 'statusBlue': !todo?.isDone, 'statusRed': delay(todo?.dateTarget, todo?.isDone)}">
                        </div>
                    </div>
                    <img  [src]="logoqvt" height="25px" *ngIf="todo?.type === 'QVT'" style="float:left">
                    <img  [src]="logo8d" height="25px" *ngIf="todo?.type === '8D'" style="float:left">
                    <div class="card-title" [ngClass]="{'line-header-title': myViewPreference ==='lines'}"
                        matTooltip="{{todo?.title}}">{{todo?.title}}
                    </div>

                    <!-- Note de la todo uniquement en mode lines -->
                    <div class="card-noteHide" [ngClass]="{'line-note': myViewPreference ==='lines'}"
                        (click)="clickList(todo?.title, todo?.description, todo?.createdAt, todo?.creator)">
                        {{todo?.description}}</div>

                    <mat-chip *ngIf="isTeamMember(todo?.teamID) && (teamTodoName !== todo?.teamName)"
                        class="card-teamHide" [ngClass]="{'line-team': myViewPreference ==='lines'}"
                        (click)="getTeamTodo(todo?.teamID, todo?.teamName)"
                        style="background-color: white;border: 1px solid #e0e0e0;"
                        matTooltip="{{ 'click to go team' | translate }}">{{todo?.teamName}}
                    </mat-chip>

                    <mat-chip *ngIf="!isTeamMember(todo?.teamID) && todo?.teamName" class="card-teamHide"
                        [ngClass]="{'line-team': myViewPreference ==='lines'}"
                        style="background-color: rgb(214, 214, 214); color: red;"
                        matTooltip="{{ 'you are no longer a member of the team' | translate }}">{{todo?.teamName}}
                    </mat-chip>

                    <mat-chip *ngIf="isShared(todo?.teamID, todo?.teamsShared)" class="card-teamHide"
                        [ngClass]="{'line-team': myViewPreference ==='lines'}"
                        style="background-color: white;border: 1px solid rgb(241, 150, 75);color: rgb(241, 150, 75)"
                        matTooltip="{{ 'sharedTooltip' | translate }}">{{ 'shared' | translate }}
                    </mat-chip>

                </div>

                <!-- LINE2 -->
                <div [ngClass]="{'line2': myViewPreference ==='lines'}">
                    <div class="card-datesHide" [ngClass]="{'line-ownerDate': myViewPreference ==='lines'}">
                        <div id="owner"
                            style="float: left;height: 100%;line-height: 18px;white-space: nowrap;text-align: center;border: solid 1px #dbe0e4;border-radius: 5px;padding-right:5px; padding-left:5px"
                            *ngIf="teamTodoName !== ''">
                            <div>{{ 'Owner' | translate }} : {{todo?.owner | email | titlecase}}</div>
                        </div>
                        <div *ngIf="todo?.dateTarget" id="dateTarget"
                            [ngClass]="{'dateTargetRed': delay(todo?.dateTarget, todo?.isDone)}"
                            style="float: left;height: 100%;line-height: 18px;white-space: nowrap;margin-left:2px;text-align: center;border: solid 1px #dbe0e4;border-radius: 5px;padding-right:5px; padding-left:5px">
                            <div>{{ 'Target date' | translate }} : {{todo?.dateTarget?.toDate() | date: 'dd MMM yyyy'}}
                            </div>
                        </div>
                        <div *ngIf="todo?.dateTarget" id="dateTargetMini" style="display: none"
                            [ngClass]="{'dateTargetRed': delay(todo?.dateTarget, todo?.isDone)}">
                            <span>{{todo?.dateTarget?.toDate() | date: 'dd MMM yyyy'}}</span>
                        </div>
                        <div *ngIf="todo?.closedAt" id="closedAt"
                            style="float: left;height: 100%;line-height: 18px;white-space: nowrap;margin-left:2px;text-align: center;border: solid 1px #dbe0e4;border-radius: 5px;padding-right:5px; padding-left:5px">
                            <div>{{ 'closedDate' | translate }} : <span style="color: #006837">{{todo?.closedAt?.toDate()
                                    | date: 'dd MMM yyyy'}}</span></div>
                        </div>
                    </div>

                    <div style="float:left;max-width: 75%; line-height: 2px;" class="card-tagsHide"
                        [ngClass]="{'line-tags': myViewPreference ==='lines'}" *ngIf='todo?.tags'>
                        <mat-chip-list *ngFor="let tag of todo?.tags; let i = index">
                            <mat-chip class="card-tags-line" (click)="filterTag(tag.value)">{{ tag?.value }}</mat-chip>
                        </mat-chip-list>
                    </div>
                    <div style="float:left;max-width: 75%; line-height: 2px;" class="card-catsHide" [ngClass]="{'line-cats': myViewPreference ==='lines'}" *ngIf="todo?.category">
                        <mat-chip-list aria-label="Fish selection">
                          <mat-chip style="background-color: #53BED0" (click)="filterTag(todo?.category)">{{todo?.category}}</mat-chip>
                          <mat-chip style="background-color: #E6C372" *ngIf="todo?.transverse">{{'Transverse' | translate}}</mat-chip>
                        </mat-chip-list>
                    </div>

                    <div style="width: 100%;">
                        <!-- Bloc des bouton pour LINE -->
                        <div id="line-buttons" class="card-buttonsHide"
                            [ngClass]="{'line-buttons': myViewPreference ==='lines'}"
                            *ngIf="!isShared(todo?.teamID, todo?.teamsShared)">

                            <mat-chip-list>

                                <mat-chip class="chipAsButton" disableRipple matRipple
                                matTooltip="efficiency" *ngIf="todo?.isDone && todo?.type ==='8D'" matTooltipPosition="above"
                                (click)="openEfficiency(todo)"
                                [ngClass]="{'density-card-content-closebuttonmini': myViewPreference ==='lines'}">
                                <mat-icon>thumbs_up_down</mat-icon>
                                </mat-chip>

                                <mat-chip class="chipAsButton" disableRipple matRipple
                                    matTooltip="{{ 'Close the todo' | translate }}" *ngIf='!todo.isDone'
                                    (click)='openDialogCloseReopen(todo?.key, todo?.isDone)' matTooltipPosition="above"
                                    [ngClass]="{'density-card-content-closebuttonmini': myViewPreference ==='lines'}">
                                    <mat-icon style="color: #006837">archive</mat-icon>
                                </mat-chip>

                                <mat-chip class="chipAsButton" disableRipple matRipple
                                    matTooltip="{{ 'Create a Google Calendar event' | translate }}"
                                    *ngIf="!todo?.eventID && todo?.dateTarget && isOwnerUserConnected(todo?.owner)"
                                    matTooltipPosition="above" (click)="todoservice.calendarCreateEvent(todo)">
                                    <mat-icon>perm_contact_calendar</mat-icon>
                                </mat-chip>

                                <mat-chip class="chipAsButton" disableRipple matRipple
                                    matTooltip="{{ 'Update the  Google calendar event' | translate }}"
                                    *ngIf="todo?.eventID && todo?.dateTarget && isOwnerUserConnected(todo?.owner)"
                                    matTooltipPosition="above"
                                    (click)="todoservice.updateEvent(todo?.eventID, todo?.dateTarget)">
                                    <mat-icon>update</mat-icon>
                                </mat-chip>

                                <mat-chip class="chipAsButton" disableRipple matRipple
                                    matTooltip="{{ 'Comments' | translate }}" matTooltipPosition="above"
                                    (click)="openDialogMessages(todo?.key, todo?.owner, todo?.title)">
                                    <mat-icon matBadge="{{todo?.messageCount}}" matBadgeOverlap="true">message
                                    </mat-icon>
                                </mat-chip>

                                <mat-chip class="chipAsButton" disableRipple matRipple
                                    matTooltip="{{ 'Documents' | translate }}" matTooltipPosition="above"
                                    (click)='openDrivePicker(todo?.key)'>
                                    <mat-icon matBadge="{{todo?.driveFiles?.length}}" matBadgeOverlap="true" style="text-align: center;"><img [src]="logoDrive" height="18px" width="20px" style='vertical-align: 4px;'></mat-icon>
                                </mat-chip>

                                <mat-chip class="chipAsButton" disableRipple matRipple
                                    matTooltip="{{ 'Update' | translate }}" matTooltipPosition="above"
                                    (click)="editForm(todo?.teamID, todo?.key)" *ngIf="!todo?.isDone">
                                    <mat-icon>edit</mat-icon>
                                </mat-chip>

                                <mat-chip class="chipAsButton" disableRipple matRipple
                                    matTooltip="{{ 'Delete' | translate }}" matTooltipPosition="above"
                                    *ngIf="isOwnerUserConnected(todo?.creator)" (click)="DeleteModal(todo?.key)">
                                    <mat-icon>delete_forever</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </div>
                    </div>
                </div>

                <mat-chip-list [ngClass]="{'line-teamHide': myViewPreference ==='lines'}"
                    *ngIf='!isTeamMember(todo?.teamID)'>
                    <mat-chip *ngIf="todo?.teamName"
                        style="background-color: rgb(214, 214, 214); color: red; max-width: 280px !important;font-size: 0.7em;"
                        matTooltip="{{ 'you are no longer a member of the team' | translate }}">{{todo?.teamName}}
                    </mat-chip>
                </mat-chip-list>

                <mat-chip-list [ngClass]="{'line-teamHide': myViewPreference ==='lines'}"
                    *ngIf="isTeamMember(todo?.teamID) && (teamTodoName !== todo?.teamName)">
                    <mat-chip (click)="getTeamTodo(todo?.teamID, todo?.teamName)"
                        matTooltip="{{ 'click to go team' | translate }}"
                        style="background-color: white !important; max-width: 280px !important;font-size: 0.7em;border: 1px solid #e0e0e0;">
                        {{todo?.teamName}}
                    </mat-chip>
                </mat-chip-list>

                <mat-chip-list [ngClass]="{'line-teamHide': myViewPreference ==='lines'}"
                    *ngIf="isShared(todo?.teamID, todo?.teamsShared)">
                    <mat-chip matTooltip="{{ 'sharedTooltip' | translate }}"
                        style="background-color: white;border: 1px solid rgb(241, 150, 75);color: rgb(241, 150, 75);font-size: 0.7em;">
                        {{ 'shared' | translate }}
                    </mat-chip>
                </mat-chip-list>


                <div class="card-content" [ngClass]="{'line-tagsHide': myViewPreference ==='lines'}">
                    <div class="content">
                        <p *ngIf="teamTodoName !== ''">{{ 'Owner' | translate }} : {{todo?.owner | email | titlecase}}</p>
                        <div>
                            <p *ngIf="todo.dateTarget">{{ 'Target date' | translate }} :
                                <span [ngClass]="{'dateTargetRed': delay(todo?.dateTarget, todo?.isDone)}">{{todo?.dateTarget?.toDate()
                                    | date: 'dd/MM/yyyy'}}</span>
                            </p>
                            <p *ngIf="todo.closedAt"><span>{{ 'closedDate' | translate }}</span> :
                                <span style="color: #006837">{{todo?.closedAt?.toDate() | date: 'dd/MM/yyyy'}}</span>
                            </p>
                        </div>
                    </div>
                </div>

                <mat-expansion-panel
                    [ngClass]="{'line-contentexpansionHide': myViewPreference ==='lines'}" style="margin: 5px;">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>more_horiz</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <p *ngIf="todo?.description" class='notes-card' style="overflow-wrap:break-word; max-width: 350px;"
                        [innerHTML]="todo?.description |  linkify"></p>
                    <p *ngIf="todo?.creator">{{ 'Create by' | translate }} : {{todo?.creator | email | titlecase }}</p>
                    <p *ngIf="todo?.createdAt">{{ 'Create at' | translate }} : {{todo?.createdAt?.toDate() | date:
                        'dd/MM/yyyy'}}
                    </p>
                </mat-expansion-panel>

                <div [ngClass]="{'line-tagsHide': myViewPreference ==='lines'}">
                    <div *ngIf="!isShared(todo?.teamID, todo?.teamsShared)">
                        <tag-input [ngModel]="todo.tags" [editable]="true" [addOnBlur]="true" [theme]="'minimal'"
                            [clearOnBlur]="true" [onAdding]="transform" (onRemove)="removeTag($event,todo.key)"
                            (onAdd)="addTag($event,todo.key)" (onSelect)="filterTag($event)">
                            <tag-input-dropdown [focusFirstElement]="true" [autocompleteItems]="autocompleteItems">
                            </tag-input-dropdown>
                        </tag-input>
                    </div>
                </div>
                <div [ngClass]="{'line-tagsHide': myViewPreference ==='lines'}">
                    <div *ngIf="isShared(todo?.teamID, todo?.teamsShared)">
                        <tag-input [ngModel]="todo.tags" [disable]="true" [theme]="'minimal'"
                            (onSelect)="filterTag($event)">
                        </tag-input>
                    </div>
                </div>
                <div [ngClass]="{'line-catsHide': myViewPreference ==='lines'}" *ngIf="todo?.type == '8D'">
                    <mat-chip-list>
                        <mat-chip style="background-color: #53BED0" (click)="filterTag(todo?.category)">{{todo?.category}}</mat-chip>
                    </mat-chip-list>
                </div>
                <div [ngClass]="{'line-catsHide': myViewPreference ==='lines'}" *ngIf="todo?.type == 'QVT'">
                    <mat-chip-list>
                        <mat-chip style="background-color: #53BED0" (click)="filterTag(todo?.category)">{{todo?.category}}</mat-chip>
                        <mat-chip style="background-color: #406DA1; color: white;" (click)="filterTag(todo?.campaignQVT)">{{todo?.campaignQVT}}</mat-chip>
                        <mat-chip style="background-color: #E6C372" *ngIf="todo?.transverse">Transverse</mat-chip>
                    </mat-chip-list>
                </div>

                <div id="card-buttons" class="buttonsBlock" style="float:right"
                    [ngClass]="{'line-buttonsHide': myViewPreference ==='lines'}"
                    *ngIf="!isShared(todo?.teamID, todo?.teamsShared)">

                    <mat-chip class="chipAsButton" disableRipple matRipple
                                matTooltip="efficiency" *ngIf="todo?.isDone && todo?.type ==='8D'" matTooltipPosition="above"
                                (click)="openEfficiency(todo)"
                                [ngClass]="{'density-card-content-closebuttonmini': myViewPreference ==='lines'}">
                                <mat-icon>thumbs_up_down</mat-icon>
                    </mat-chip>

                    <mat-chip class="chipAsButton" disableRipple matRipple
                        matTooltip="{{ 'Close the todo' | translate }}" *ngIf='!todo.isDone'
                        (click)='openDialogCloseReopen(todo?.key, todo?.isdone)' matTooltipPosition="above"
                        [ngClass]="{'density-card-content-closebuttonmini': myViewPreference ==='lines'}">
                        <mat-icon style="color: #006837;">archive</mat-icon>
                    </mat-chip>

                    <mat-chip class="chipAsButton" disableRipple matRipple
                        matTooltip="{{ 'Create a Google Calendar event' | translate }}"
                        *ngIf="!todo?.eventID && todo?.dateTarget && isOwnerUserConnected(todo?.owner)"
                        matTooltipPosition="above" (click)="todoservice.calendarCreateEvent(todo)">
                        <mat-icon>perm_contact_calendar</mat-icon>
                    </mat-chip>

                    <mat-chip class="chipAsButton" disableRipple matRipple
                        matTooltip="{{ 'Update the  Google calendar event' | translate }}"
                        *ngIf="todo?.eventID && todo?.dateTarget && isOwnerUserConnected(todo?.owner)"
                        matTooltipPosition="above" (click)="todoservice.updateEvent(todo?.eventID, todo?.dateTarget)">
                        <mat-icon>update</mat-icon>
                    </mat-chip>

                    <mat-chip class="chipAsButton" disableRipple matRipple matTooltip="{{ 'Comments' | translate }}"
                        matTooltipPosition="above" (click)="openDialogMessages(todo?.key, todo?.owner, todo?.title)">
                        <mat-icon matBadge="{{todo?.messageCount}}" matBadgeOverlap="true">message</mat-icon>
                    </mat-chip>

                    <mat-chip class="chipAsButton" disableRipple matRipple matTooltip="{{ 'Documents' | translate }}"
                        matTooltipPosition="above" (click)='openDrivePicker(todo?.key)'>
                        <mat-icon matBadge="{{todo?.driveFiles?.length}}" matBadgeOverlap="true" style="text-align: center;"><img [src]="logoDrive" height="18px" width="20px" style='vertical-align: 4px;'></mat-icon>
                    </mat-chip>

                    <mat-chip class="chipAsButton" disableRipple matRipple matTooltip="{{ 'Update' | translate }}"
                        matTooltipPosition="above" (click)="editForm(todo?.teamID, todo?.key)" *ngIf="!todo?.isDone">
                        <mat-icon>edit</mat-icon>
                    </mat-chip>

                    <mat-chip class="chipAsButton" disableRipple matRipple matTooltip="{{ 'Delete' | translate }}"
                        matTooltipPosition="above" *ngIf="isOwnerUserConnected(todo?.creator)"
                        (click)="DeleteModal(todo?.key)">
                        <mat-icon>delete_forever</mat-icon>
                    </mat-chip>
                </div>
                <br>
            </li>
        </ul>
    </cdk-virtual-scroll-viewport>
</div>

<a mat-fab id="button-add-todo" class="addtodo-button" matTooltip="{{ 'Add Todo' | translate }}"
    matTooltipPosition="above" (click)="addForm(teamTodoId, teamTodoName)">
    <mat-icon>add</mat-icon>
</a>
