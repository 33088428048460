import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of as observableOf, Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { TodoService } from '../../services/todo.service';

export interface User {
    name: string;
}

@Component({
    selector: 'app-form-team',
    templateUrl: './form-team.component.html',
    styleUrls: ['./form-team.component.css'],
})
export class FormTeamComponent implements OnInit, OnDestroy {
    currentEmail;
    myForm: FormGroup;
    docID;
    type;
    teamNameSub: Subscription;
    mytagsSub: Subscription;
    startDate = new Date();
    state: string;
    options: User[] = [];
    filteredOptions: Observable<User[]>;
    TeamupdatedAt;
    autocompleteItems = ['P1'];

    constructor(
        public dialogRef: MatDialogRef<string>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private auth: AuthService,
        private todoservice: TodoService,
        private fb: FormBuilder,
    ) {
        this.docID = data.teamID;
        this.type = data.type;
    }

    ngOnInit() {
        // this.docID = this.route.snapshot.params['key'];
        this.myForm = this.fb.group({
            title: ['', Validators.required],
            note: [''],
            processusID: [''],
            members: [[this.auth.currentEmail], Validators.required],
            creator: [this.auth.currentEmail, Validators.required],
            tags: [''],
            updatedAt: [''],
        });
        // Subscription : mytags
        this.mytagsSub = this.todoservice.getUserTeamTags().subscribe(
            mytags => {
                this.autocompleteItems = mytags;
                // console.log(mytags);
            },
            error => console.log('Observer a reçu une erreur : ' + error),
        );
    }

    get dateTarget() {
        return this.myForm.get('dateTarget');
    }
    get isDone() {
        return this.myForm.get('isDone');
    }

    ngOnDestroy(): void {
        if (this.teamNameSub) {
            this.teamNameSub.unsubscribe();
        }
        if (this.mytagsSub) {
            this.mytagsSub.unsubscribe();
        }
    }

    private _filter(name: string): User[] {
        const filterValue = name.toLowerCase();
        return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    // TAGS add
    public transform(value): Observable<object> {
        console.log(value);
        if (value.value) {
            const item = { display: `#${value.value}`, value: `#${value.value}` };
            return observableOf(item);
        } else if (value) {
            const item = { display: `#${value}`, value: `#${value}` };
            return observableOf(item);
        } else {
            return;
        }
    }

    changeHandler(e) {
        this.state = e;
        // this.afs.doc(`teams/${this.docID}`).valueChanges().subscribe(team => {
        //   if (team) {
        //     this.TeamupdatedAt = team['updatedAt'].toDate();
        //   }
        // });
    }
}
