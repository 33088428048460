    <div id="card-mytodos" style="margin-top: 56px;">
      <mat-progress-bar *ngIf="(loading.isLoading$ | async)"  mode="indeterminate"></mat-progress-bar>
        <h2 style="margin: 0%;" *ngIf="!(loading.isLoading$ | async)">My ToDos</h2>
        <h5 *ngIf="!(loading.isLoading$ | async)">{{'The Todos that I own.' | translate}}</h5>
            <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="1%" *ngIf="!(loading.isLoading$ | async)">
              <mat-card fxFlex="25">
                <mat-chip-list>
                  <span>{{"Open (total)" | translate }}</span><br><mat-chip>{{countOpenTodosAll}}</mat-chip>
                </mat-chip-list>
              </mat-card>
              <mat-card fxFlex="25">
                <mat-chip-list>
                  <span>{{"Closed (total)" | translate }}</span><br><mat-chip style="background-color: #006837; color:white">{{countClosedTodos}}</mat-chip>
                </mat-chip-list>
              </mat-card>
            </div>

            <br>
            <mat-divider></mat-divider>
            <br>

            <div id="highchart" *ngIf="!(loading.isLoading$ | async)">
              <app-widget-pie [data]="todosdata" *ngIf="todosdata"></app-widget-pie>
            </div>

            <br>

            <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="1%" *ngIf="!(loading.isLoading$ | async)">
              <mat-card fxFlex="25">
                <mat-chip-list>
                  <span style="font-style: italic;">{{"Open (with target date : OK)" | translate}}</span><br><mat-chip style="background-color: rgb(128, 217, 233);">{{countOpenWithTargetOKTodos}}</mat-chip>
                </mat-chip-list>
              </mat-card>
              <mat-card fxFlex="25">
                <mat-chip-list>
                  <span style="font-style: italic;">{{"Open (without target date)" | translate}}</span><br><mat-chip style="background-color: #FFFAA4;">{{countOpenWithoutTargetTodos}}</mat-chip>
                </mat-chip-list>
              </mat-card>
              <mat-card fxFlex="25">
                <mat-chip-list>
                  <span style="font-style: italic;">{{"Open (Late)" | translate}}</span><br><mat-chip style="background-color: rgb(243, 127, 127);">{{countOpenLateTodos}}</mat-chip>
                </mat-chip-list>
              </mat-card>
              <mat-card fxFlex="25">
                <mat-chip-list>
                  <span style="font-style: italic;">{{"Open (without Team)" | translate }}</span><br><mat-chip >{{countOpenWithoutTeamTodos}}</mat-chip>
                </mat-chip-list>
              </mat-card>
              <!-- <mat-card fxFlex="25">
                <app-widget-card total="6k" percentage="20" [data]="cards"></app-widget-card>
            </mat-card> -->
            </div>
    </div>
          <br>

          <div id="card-myteams" *ngIf="!(loading.isLoading$ | async)">
            <h2 style="margin: 0%">My Teams</h2>
            <h5>{{"The Teams of which I am a member." | translate}}</h5>
                <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="1%">
                  <mat-card fxFlex="25">
                    <mat-chip-list>
                      <span>Teams (Total)</span><br><mat-chip>{{countTeamsAll}}</mat-chip>
                    </mat-chip-list>
                  </mat-card>
                  <mat-card fxFlex="25">
                    <mat-chip-list>
                      <span>{{"Teams (created by me)" | translate }}</span><br><mat-chip >{{countTeamsAdmin}}</mat-chip>
                    </mat-chip-list>
                  </mat-card>
                  <mat-card fxFlex="25">
                    <mat-chip-list>
                      <span>Teams QVT</span><br><mat-chip >{{countTeamsQVT}}</mat-chip>
                    </mat-chip-list>
                    <img [src]="logoqvt" height="45px" style="vertical-align: bottom; margin-left: 25px;">
                  </mat-card>
                  <mat-card fxFlex="25">
                    <mat-chip-list>
                      <span>Teams 8D</span><br><mat-chip >{{countTeams8D}}</mat-chip>
                    </mat-chip-list>
                    <img [src]="logo8d" height="45px" style="vertical-align: bottom; margin-left: 25px;">
                  </mat-card>
                </div>

        </div>
